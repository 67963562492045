const useGetAgency = (agency) => {
  let res = {};

  if (agency) {
    const { id, badge, license, merger, name, organizationName } = agency;

    // Check if a license request has been made (not null means requested)
    const licenceRequested = license.requested !== null;
    // Check if the license has been approved (not null means approved)
    const licenceApproved = license.approved !== null;

    // Check if a merger request has been made (not null means requested)
    const mergerRequested = merger.requested !== null;
    // Check if the merger has been approved (not null means approved)
    const mergerApproved = merger.approved !== null;

    res = {
      agencyId: id,
      agencyBadge: badge,
      agencyName: name,
      agencyMasterId: merger.master_id,
      agencyMasterName: merger.master_name,
      organizationName,
      agencyMerger: merger,
      licenceRequested,
      licenceApproved,
      mergerRequested,
      mergerApproved,
    };
  }

  return res;
};

export default useGetAgency;
