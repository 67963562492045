import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from 'helpers';
import { BlockCard, Table, Spinner, Button } from '@trellixio/roaster-coffee';
import Locales from 'locales';

const CONST_TABLE_KEY = 'table';

const TripDistributionTable = ({ reports, agencyList, lastDownloadTime, handlePrint }) => {
  const tripDistributionTableRef = useRef(null);

  const calculateTotal = (key) => {
    const sum = reports?.reduce((total, data) => total + data[key], 0);
    return formatNumber(sum);
  };

  return (
    <BlockCard
      title={Locales.formatString(Locales.dashboard.report.block_title, {
        generate:
          lastDownloadTime && Locales.formatString(Locales.dashboard.report.generate, { date: lastDownloadTime }),
      })}
      actions={
        <Button variant="secondary" onClick={() => handlePrint(tripDistributionTableRef, CONST_TABLE_KEY)}>
          <i className="fa-regular fa-solid fa-print" />
          {Locales.dashboard.report.button.download}
        </Button>
      }
      ref={tripDistributionTableRef}
    >
      {reports && (
        <>
          <Table>
            <Table.Head>
              <Table.HeadCell type="text">{Locales.dashboard.report.table.agreed_agency}</Table.HeadCell>
              <Table.HeadCell type="numeric">{Locales.dashboard.report.table.sold}</Table.HeadCell>
              <Table.HeadCell type="numeric">{Locales.dashboard.report.table.pre_paid}</Table.HeadCell>
              <Table.HeadCell type="numeric">{Locales.dashboard.report.table.pre_registered}</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {reports.map((data) => {
                const agency = agencyList?.find((agencyObject) => agencyObject.id === data.agency_id);

                return (
                  <Table.Row key={data.agency_id}>
                    <Table.Cell type="text">
                      {Locales.formatString(Locales.dashboard.report.chart_title, {
                        agencyName: agency?.name,
                      })}
                    </Table.Cell>
                    <Table.Cell type="numeric">{formatNumber(data.full_payment_trips)}</Table.Cell>
                    <Table.Cell type="numeric">{formatNumber(data.pre_payed_trips)}</Table.Cell>
                    <Table.Cell type="numeric">{formatNumber(data.pre_registered_trips)}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <Table>
            <Table.Head>
              <Table.HeadCell type="text">-</Table.HeadCell>
              <Table.HeadCell type="numeric">{Locales.dashboard.report.table.sold}</Table.HeadCell>
              <Table.HeadCell type="numeric">{Locales.dashboard.report.table.pre_paid}</Table.HeadCell>
              <Table.HeadCell type="numeric">{Locales.dashboard.report.table.pre_registered}</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell type="text">{Locales.dashboard.report.table.total}</Table.Cell>
                <Table.Cell type="numeric">{calculateTotal('full_payment_trips')}</Table.Cell>
                <Table.Cell type="numeric">{calculateTotal('pre_payed_trips')}</Table.Cell>
                <Table.Cell type="numeric">{calculateTotal('pre_registered_trips')}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
      {!reports && (
        <div>
          <Spinner />
        </div>
      )}
    </BlockCard>
  );
};

TripDistributionTable.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      agency_id: PropTypes.number,
      full_payment_trips: PropTypes.number,
      pre_payed_trips: PropTypes.number,
      pre_registered_trips: PropTypes.number,
    })
  ),
  agencyList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  lastDownloadTime: PropTypes.string,
  handlePrint: PropTypes.func,
};

TripDistributionTable.defaultProps = {
  reports: null,
  agencyList: null,
  lastDownloadTime: null,
  handlePrint: null,
};

export default TripDistributionTable;
