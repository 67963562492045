import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ToastsProvider, showToast } from '@trellixio/roaster-coffee';
import * as Sentry from '@sentry/react';
import { Storage } from 'helpers';
import { QueryClient, QueryClientProvider, MutationCache } from '@tanstack/react-query';
import { RestClientProvider, PermissionsProvider, UserProvider } from 'context';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import 'assets/styles/main.css';

const CONST_USER_TOKEN = 'user_token';

const storage = new Storage(localStorage, 'eHadj-');
const token = storage.get(CONST_USER_TOKEN);

// Creation of a MutationCache instance with an error handling option
const mutationCache = new MutationCache({
  /* In case of an error, the onError configuration option is called with the parameters error, _variables, _context, mutation
   * - error represents the error that occurred during the mutation
   * - _variables represent the variables passed to the mutation (optional, used as needed)
   * - _context represents the context of the mutation (optional, used as needed)
   * - mutation contains information about the current mutation
   */
  onError: (error, _variables, _context, mutation) => {
    // This condition checks if the current mutation has a specific onError function defined in its options
    if (!mutation.options.onError) {
      showToast({ message: error, level: 'error' });
    }
  },
});

// Creation of a QueryClient instance with default options for queries
const queryClient = new QueryClient({
  // Defines the default options to be used for all queries that will be made
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000, // Time to live for cached data (in milliseconds)
      cacheTime: 48 * 60 * 60 * 1000, // Time to retain cached data (in milliseconds)
      enabled: !!token, // Enables queries if a user token is present
    },
  },
  mutationCache, // Using the previously configured mutation cache
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ToastsProvider>
          <RestClientProvider>
            <UserProvider>
              <PermissionsProvider>
                <Sentry.ErrorBoundary>
                  <App />
                </Sentry.ErrorBoundary>
              </PermissionsProvider>
            </UserProvider>
          </RestClientProvider>
        </ToastsProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
