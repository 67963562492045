import axios from 'axios';
import { raiseAPIError } from './exceptions';

/**
 * API calls returns an objects formatted with pagination
 * {data: [{}, ...], next: 'url', previous: 'url'}
 *
 * @param {object} data response from ApiCall
 * @param {object} data.data the actual data
 * @returns {Array} Array of objects
 */
const handleListData = ({ data }) => {
  if (data.object === 'list') {
    const arrayData = data.data;
    arrayData.object = data.object;
    arrayData.count = data.count;
    arrayData.next = data.next;
    arrayData.previous = data.previous;
    return Promise.resolve(arrayData);
  }
  return Promise.resolve({ data });
};

export default class RestClient {
  #baseUrl = '';

  #authToken = '';

  #debounceRequests = {};

  #onLogout = null;

  /**
   * Cache the request params before making the call.
   * Used to build the storageKey
   */
  requestParams = null;

  constructor(baseUrl, token, onLogout) {
    this.#baseUrl = baseUrl;
    this.#authToken = token;
    this.#onLogout = onLogout;
  }

  /**
   * Config for REST method call
   *
   * @param {object} axiosConfig Config for the request
   * @returns {Promise} Result of the request
   */
  async #request(axiosConfig) {
    const updatedAxiosConfig = { ...axiosConfig };
    updatedAxiosConfig.url = `${this.#baseUrl}${axiosConfig.url}`;

    if (this.#authToken) {
      updatedAxiosConfig.auth = { username: this.#authToken, password: '' };
    }
    try {
      return await axios.request(updatedAxiosConfig);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        return raiseAPIError(err.response, this.#onLogout);
      }
      return Promise.reject(err);
    }
  }

  /** REST API methods */

  /**
   * Rest: Get
   *
   * @param {string} url Request URL or path
   * @param {object} config Additional axios config
   * @returns {Promise} Response of the request
   */
  async get(url, config) {
    const conf = { ...(config || {}), ...{ url, method: 'get' } };
    return this.#request(conf);
  }

  /**
   * Rest: Delete
   *
   * @param {string} url Request URL or path
   * @param {object} config Additional axios config
   * @returns {Promise} Response of the request
   */
  async delete(url, config) {
    const conf = { ...(config || {}), ...{ url, method: 'delete' } };
    return this.#request(conf);
  }

  /**
   * Rest: Post
   *
   * @param {string} url Request URL or path
   * @param {object} data data to POST
   * @param {object} config Additional axios config
   * @returns {Promise} Response of the request
   */
  async post(url, data, config) {
    const conf = { ...(config || {}), ...{ url, data, method: 'post' } };
    return this.#request(conf);
  }

  /**
   * Rest: Put
   *
   * @param {string} url Request URL or path
   * @param {object} data data to PUT
   * @param {object} config Additional axios config
   * @returns {Promise} Response of the request
   */
  async put(url, data, config) {
    const conf = { ...(config || {}), ...{ url, data, method: 'put' } };
    return this.#request(conf);
  }

  /**
   * Rest: Head
   *
   * @param {string} url Request URL or path
   * @param {object} config Additional axios config
   * @returns {Promise} Response of the request
   */
  async head(url, config) {
    const conf = { ...(config || {}), ...{ url, method: 'head' } };
    return this.#request(conf);
  }

  /**
   * Rest: Patch
   *
   * @param {string} url Request URL or path
   * @param {object} data data to PATCH
   * @param {object} config Additional axios config
   * @returns {Promise} Response of the request
   */
  async patch(url, data, config) {
    const conf = { ...(config || {}), ...{ url, data, method: 'patch' } };
    return this.#request(conf);
  }

  /** helpers */

  /**
   * Delete a resource
   *
   * @param {string} objectURI API object relative URI or path
   * @param {string} id resource id
   * @returns {Promise} Response of the request
   */
  async remove(objectURI, id = 'current') {
    const url = `${objectURI}/${id}`;
    return this.delete(url);
  }

  /**
   * Call to create a resource
   *
   * @param {string} objectURI API object relative URI or path
   * @param {object} data object to create
   * @returns {Promise} Response of the request
   */
  async create(objectURI, data) {
    const url = `${objectURI}`;
    return this.post(url, data);
  }

  /**
   * Call to update a resource
   *
   * @param {string} objectURI API object relative URI or path
   * @param {object} data object to update
   * @param {string} id resource id
   * @returns {Promise} Response of the request
   */
  async update(objectURI, data, id = 'current') {
    const url = `${objectURI}/${id}`;
    return this.put(url, data);
  }

  /**
   * Call to update an existing file
   *
   * @param {string} objectURI API object relative URI or path
   * @param {string} field object key
   * @param {Array} files an array of Files
   * @param {string} id resource id
   * @param {boolean} useRessourceId whether or not to use path to ressource id
   * @returns {Promise} Response of the request
   */
  async updateFile(objectURI, field, files, id = 'current', useRessourceId = true) {
    const url = useRessourceId ? `${objectURI}/${id}/file` : objectURI;
    const formData = new FormData();
    Object.values(files).forEach((file) => {
      if (file instanceof File) {
        formData.append(field, file);
      }
    });
    return this.post(url, formData);
  }

  /**
   * Prepare a call to fetch a resource
   *
   * @param {string} objectURI API object relative URI or path
   * @param {string} id resource id
   * @param {object} params Query parameters
   * @returns {Promise} a promise
   */
  retrieve(objectURI, id = 'current', params = {}) {
    if (!id) return Promise.resolve({ data: null });
    const path = `${objectURI}/${id}`;
    this.requestParams = { method: 'GET', params, path };
    return async () => {
      const { data } = await this.get(path, { params });
      return data;
    };
  }

  /**
   * Prepare a call to list resources
   *
   * @param {string} objectURI API object relative URI or path
   * @param {object} params Query parameters
   * @returns {Promise} a promise
   */
  list(objectURI, params = { limit: 15 }) {
    this.requestParams = { method: 'GET', params, path: objectURI };
    return async () => {
      const response = await this.get(objectURI, { params });
      return handleListData(response);
    };
  }
}
