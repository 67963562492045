import React, { useState } from 'react';
import { Table, Button, ModalProvider, openModal, Tooltip, showToast, Icon } from '@trellixio/roaster-coffee';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI } from 'hooks';
import PropTypes from 'prop-types';
import { getUserName, formatDate, hasNextPage, hasPreviousPage, updateQueryCursor, showPagination } from 'helpers';
import Locales from 'locales';
import adminIcon from 'assets/img/sidebar/admin.svg';
import { LockableActionWrapper } from 'components';
import EditUserModal from './EditUserModal';
import style from './User.module.css';

const UsersTable = ({ tableData, setCursor, limit, isPlatformLocked }) => {
  const [openedEditModal, setOpenedEditModal] = useState(false);
  const [selectedUserData, setUserData] = useState({});

  const { api } = useAPI();

  const queryClient = useQueryClient();

  const openEditModal = (userData) => {
    setUserData(userData);
    setOpenedEditModal(true);
  };

  const { mutate: updateUserStatus } = useMutation({
    mutationFn: (user) => {
      const { id, is_active: isActive } = user;
      const endpoint = isActive ? 'user_deactivate' : 'user_activate';
      return api.post(`user/${id}/${endpoint}/`);
    },
    onSuccess: ({ data }) => {
      const { first_name: firstName, last_name: lastName, is_active: isActive } = data;
      queryClient.invalidateQueries({ queryKey: ['user/'] });
      showToast({
        message: Locales.formatString(Locales.user.toast.update_success, {
          firstName,
          lastName,
          status: isActive ? Locales.base.status.is_activate : Locales.base.status.is_deactivate,
        }),
        level: 'success',
      });
    },
  });

  const handleOpenStatusUpdateModal = (user) => {
    const { is_active: isActive, first_name: firstName, last_name: lastName, email: mail } = user;
    openModal({
      title: isActive ? Locales.user.disable_account : Locales.user.enable_account,
      children: (
        <span>
          {Locales.formatString(Locales.user.alert_confirm, {
            action: isActive ? Locales.base.status.deactivate : Locales.base.status.activate,
          })}
          <strong>{getUserName(firstName, lastName)}</strong> {Locales.user.associated_mail}
          <span>{mail}</span> ?
        </span>
      ),
      closeOnConfirm: true,
      closeOnCancel: true,
      confirmProps: {
        children: isActive ? Locales.base.button.disable : Locales.base.button.enable,
        variant: 'primary',
        color: isActive ? 'danger' : 'success',
      },
      cancelProps: {
        children: <span>{Locales.base.button.cancel}</span>,
        variant: 'secondary',
        color: 'secondary',
      },
      onCancel: () => {},
      onConfirm: () => updateUserStatus(user),
    });
  };

  return (
    <ModalProvider>
      <div className={style.table}>
        <Table
          showPagination={showPagination(tableData, limit)}
          hasNextPage={hasNextPage(tableData)}
          hasPreviousPage={hasPreviousPage(tableData)}
          onNextPage={() => updateQueryCursor(tableData.next, setCursor)}
          onPreviousPage={() => updateQueryCursor(tableData.previous, setCursor)}
        >
          <Table.Head>
            <Table.HeadCell type="thumbnail" />
            <Table.HeadCell type="text">{Locales.user.last_name}</Table.HeadCell>
            <Table.HeadCell type="text">{Locales.user.mail}</Table.HeadCell>
            <Table.HeadCell type="paragraph">{Locales.user.organisation}</Table.HeadCell>
            <Table.HeadCell type="enum">
              {Locales.base.status.active}
              <Tooltip inline label={Locales.user.account_status}>
                <Icon hasTooltip>
                  <i className="fa-regular fa-circle-info" />
                </Icon>
              </Tooltip>
            </Table.HeadCell>
            <Table.HeadCell type="date">
              {Locales.user.creation}
              <Tooltip inline label={Locales.user.user_creation_date}>
                <Icon hasTooltip>
                  <i className="fa-regular fa-circle-info" />
                </Icon>
              </Tooltip>
            </Table.HeadCell>
            <Table.HeadCell type="object">{Locales.base.button.actions}</Table.HeadCell>
          </Table.Head>

          {tableData && tableData.length > 0 && (
            <Table.Body>
              {tableData.map((data, key) => (
                <Table.Row key={data.id} id={key}>
                  <Table.Cell type="thumbnail">
                    <Icon>
                      <img src={adminIcon} alt="" />
                    </Icon>
                  </Table.Cell>
                  <Table.Cell type="text">
                    <div className="items-group">{getUserName(data.first_name, data.last_name)}</div>
                  </Table.Cell>
                  <Table.Cell type="text">{data.email}</Table.Cell>
                  <Table.Cell type="paragraph">{data.organization_name}</Table.Cell>
                  <Table.Cell type="enum">
                    {data.is_verified && data.is_active ? (
                      <Icon small color="success">
                        <i className="fa-regular fa-check" />
                      </Icon>
                    ) : (
                      <Icon small color="danger">
                        <i className="fa-solid fa-xmark" />
                      </Icon>
                    )}
                  </Table.Cell>
                  <Table.Cell type="text">{formatDate(data.created)}</Table.Cell>
                  <Table.Cell type="text">
                    {data.is_active && (
                      <div className="items-group">
                        <LockableActionWrapper isPlatformLocked={isPlatformLocked} callback={() => openEditModal(data)}>
                          <Button variant="secondary" isIconButton>
                            <i className="fa-regular fa-solid fa-pen" />
                          </Button>
                        </LockableActionWrapper>

                        <LockableActionWrapper
                          isPlatformLocked={isPlatformLocked}
                          callback={() => handleOpenStatusUpdateModal(data)}
                        >
                          <Button variant="secondary" color="danger" isIconButton>
                            <i className="fa-regular fa-solid fa-ban" />
                          </Button>
                        </LockableActionWrapper>
                      </div>
                    )}

                    {!data.is_active && (
                      <LockableActionWrapper
                        isPlatformLocked={isPlatformLocked}
                        callback={() => handleOpenStatusUpdateModal(data)}
                      >
                        <Button variant="secondary" isIconButton>
                          <i className="fa-solid fa-rotate-right" />
                        </Button>
                      </LockableActionWrapper>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>

      {Object.keys(selectedUserData).length > 0 && openedEditModal && (
        <EditUserModal user={selectedUserData} opened={openedEditModal} closeModal={() => setOpenedEditModal(false)} />
      )}
    </ModalProvider>
  );
};

UsersTable.defaultProps = {
  tableData: null,
  setCursor: null,
  limit: null,
  isPlatformLocked: false,
};

UsersTable.propTypes = {
  tableData: PropTypes.shape([]),
  setCursor: PropTypes.func,
  limit: PropTypes.number,
  isPlatformLocked: PropTypes.bool,
};

export default UsersTable;
