import React, { useState } from 'react';
import { Modal, TextField, showToast, Button, BlockCard } from '@trellixio/roaster-coffee';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { getUserName, Storage } from 'helpers';
import { useAPI, useGetUser } from 'hooks';
import Locales from 'locales';

const CONST_USER = 'user';

const EditUserModal = ({ opened, user, closeModal }) => {
  const { user: currentUser } = useGetUser();
  const {
    id,
    email,
    first_name: userFirstname,
    last_name: userLastname,
    organization_id: organizationId,
    organization_name: organizationName,
  } = user;

  const [firstName, setFirstName] = useState(userFirstname);
  const [lastName, setLastName] = useState(userLastname);
  const [emptyFirstNameFieldError, setEmptyFirstNameFieldError] = useState(false);
  const [emptyLastNameFieldError, setEmptyLastNameFieldError] = useState(false);
  const storage = new Storage(localStorage, 'eHadj-');
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;

  const { mutate: editUser } = useMutation({
    mutationFn: () => {
      const newData = { first_name: firstName, last_name: lastName, email, organization_id: organizationId };
      return api.update('user', newData, id);
    },
    onSuccess: ({ data }) => {
      if (currentUser.id === data.id) {
        // Update the first_name and last_name properties of the retrieved data object
        currentUser.first_name = data.first_name;
        currentUser.last_name = data.last_name;
        // Store the updated data object in session storage
        storage.set(CONST_USER, JSON.stringify(currentUser));
      }
      queryClient.invalidateQueries({ queryKey: ['user/'] });
      showToast({
        message: Locales.formatString(Locales.user.toast.user_updated, { firstName, lastName }),
        level: 'success',
      });
      closeModal();
    },
  });

  const handleUpdate = () => {
    const emptyFirstNameField = firstName.trim() === '';
    const emptyLastNameField = lastName.trim() === '';

    setEmptyFirstNameFieldError(emptyFirstNameField);
    setEmptyLastNameFieldError(emptyLastNameField);

    if (!emptyFirstNameField && !emptyLastNameField) {
      editUser();
    }
  };

  return (
    <Modal
      title={Locales.user.edit_admin}
      size="small"
      opened={opened}
      onClose={closeModal}
      footer={
        <>
          <Button variant="secondary" onClick={closeModal}>
            {Locales.base.button.cancel}
          </Button>
          <Button variant="primary" color="success" onClick={handleUpdate} disabled={isMutating}>
            {Locales.base.button.save}
          </Button>
        </>
      }
    >
      <form>
        <BlockCard>
          <TextField label={Locales.user.organisation} value={organizationName} readOnly disabled />

          <p>
            {Locales.user.update_account}
            <strong>{getUserName(userFirstname, userLastname)} :</strong>
          </p>

          <div className="items-group">
            <TextField
              label={Locales.base.field.first_name}
              type="text"
              defaultValue={userFirstname}
              onChange={setFirstName}
              error={emptyFirstNameFieldError ? Locales.base.field.required.empty : ''}
              required
            />
            <TextField
              label={Locales.base.field.last_name}
              type="text"
              defaultValue={userLastname}
              onChange={setLastName}
              error={emptyLastNameFieldError ? Locales.base.field.required.empty : ''}
              required
            />
          </div>

          <TextField label={Locales.base.field.email} type="email" value={email} readOnly disabled />
        </BlockCard>
      </form>
    </Modal>
  );
};

EditUserModal.defaultProps = {
  opened: false,
  user: null,
  closeModal: null,
};

EditUserModal.propTypes = {
  opened: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    organization_id: PropTypes.string,
    organization_name: PropTypes.string,
  }),
  closeModal: PropTypes.func,
};

export default EditUserModal;
