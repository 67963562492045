import React, { useEffect } from 'react';
import { Routes, Route, useMatch } from 'react-router-dom';
import '@trellixio/roaster-coffee/theme-coffee.css';
import { useIsFetching } from '@tanstack/react-query';
import { showToast } from '@trellixio/roaster-coffee';
import { AuthSidebar, ProgressiveLoader, Sidebar, ProtectedRoute, LandingPage } from 'components';
import { Urls } from 'data';
import { useGetUser } from 'hooks';
import { Storage, vendors, onLogout } from 'helpers';
import Locales from 'locales';
import {
  ForgotPassword,
  Login,
  Logout,
  ApiKey,
  Agency,
  Invoice,
  Campaign,
  Customization,
  Authorization,
  Dashboard,
  Deposit,
  ResetPassword,
  Trip,
  User,
  Error404,
  LegalMention,
  Cgu,
  CookiePolicy,
} from 'pages';

const CONST_LEGAL_DOWNLOADED = 'legal_downloaded';
const CONST_LEGAL_TOAST_DISPLAYED = 'legal_toast_displayed';

const App = () => {
  const storage = new Storage(localStorage, 'eHadj-');
  const isLegalDownloaded = storage.getData(CONST_LEGAL_DOWNLOADED) === true;
  const isLegalToastDisplayed = storage.getData(CONST_LEGAL_TOAST_DISPLAYED) === true;
  const legalToastDuration = 30000;

  const {
    isConnected,
    user,
    isSuperAdmin,
    isBank,
    isAGLO,
    isAgency,
    isAgencyPrimary,
    isPrimaryUser,
    isMS,
    isDEI,
    isMAEC,
  } = useGetUser();
  const isFetching = useIsFetching();

  const hideSidebarPages = [Urls.cgu, Urls.legal_mention, Urls.cookie_policy];

  const hideSidebar = hideSidebarPages.filter((URL) => useMatch(URL)).length > 0;

  useEffect(() => {
    if (isConnected && user && process.env.REACT_APP_ENV !== 'dev') {
      // Only init vendors when all data are available
      vendors.init(user, isAGLO || isSuperAdmin);
    }

    if (isConnected && !isLegalToastDisplayed) {
      showToast({
        message: Locales.formatString(Locales.base.legals.download, {
          consent_receipt: (
            <strong>
              <a
                href={`${process.env.PUBLIC_URL}pdfs/legal-consent.pdf`}
                download={Locales.base.legals.document_name}
                target="_blank"
                rel="noopener noreferrer"
              >
                {Locales.base.legals.consent_receipt}
              </a>
            </strong>
          ),
        }),
        level: 'success',
        // 30 seconds before the toast disappears
        autoClose: legalToastDuration,
        onClose: () => storage.set(CONST_LEGAL_TOAST_DISPLAYED, true),
      });

      setTimeout(() => {
        storage.set(CONST_LEGAL_TOAST_DISPLAYED, true);
      }, legalToastDuration);
    }

    // Force disconnect all user that did not accept Legals
    if (isConnected && !isLegalDownloaded) {
      onLogout();
    }
  }, [isConnected, user]);

  return (
    <>
      {isFetching ? <ProgressiveLoader /> : null}

      {isConnected && !hideSidebar && <Sidebar />}

      {!isConnected && !hideSidebar && <AuthSidebar />}

      <div className="mainframe">
        <Routes>
          <Route index element={<LandingPage />} />

          <Route element={<ProtectedRoute isAllowed={!isConnected} />}>
            <Route path={Urls.front.login} element={<Login />} />
            <Route path={Urls.front.password.forgot} element={<ForgotPassword />} />
            <Route path={Urls.front.password.reset} element={<ResetPassword />} />
          </Route>

          <Route path={Urls.front.logout} element={<Logout />} />
          <Route path={Urls.cgu} element={<Cgu />} />
          <Route path={Urls.legal_mention} element={<LegalMention />} />
          <Route path={Urls.cookie_policy} element={<CookiePolicy />} />

          <Route element={<ProtectedRoute isAllowed={isConnected} />}>
            <Route
              path={Urls.apikey}
              element={
                <ProtectedRoute isAllowed={isBank}>
                  <ApiKey />
                </ProtectedRoute>
              }
            />
            <Route
              path={Urls.invoice}
              element={
                <ProtectedRoute isAllowed={isAGLO || isBank || isAgency}>
                  <Invoice />
                </ProtectedRoute>
              }
            />
            <Route
              path={Urls.agency}
              element={
                <ProtectedRoute isAllowed={isAGLO}>
                  <Agency />
                </ProtectedRoute>
              }
            />
            <Route
              path={Urls.campaign}
              element={
                <ProtectedRoute isAllowed={isSuperAdmin || isAGLO}>
                  <Campaign />
                </ProtectedRoute>
              }
            />
            <Route
              path={Urls.trip}
              element={
                <ProtectedRoute isAllowed={isAgency || isAGLO || isMS || isDEI || isMAEC}>
                  <Trip />
                </ProtectedRoute>
              }
            />
            <Route
              path={Urls.authorization}
              element={
                <ProtectedRoute isAllowed={isAgencyPrimary}>
                  <Authorization />
                </ProtectedRoute>
              }
            />
            <Route
              path={Urls.dashboard}
              element={
                <ProtectedRoute isAllowed={isAGLO || isSuperAdmin}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path={Urls.user}
              element={
                <ProtectedRoute isAllowed={isPrimaryUser}>
                  <User />
                </ProtectedRoute>
              }
            />
            <Route
              path={Urls.deposit}
              element={
                <ProtectedRoute isAllowed={isAGLO || isAgency}>
                  <Deposit />
                </ProtectedRoute>
              }
            />
            <Route
              path={Urls.customization}
              element={
                <ProtectedRoute isAllowed={isAGLO || isAgency}>
                  <Customization />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default App;
