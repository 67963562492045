const Urls = {
  base: '/',
  front: {
    login: '/login',
    password: {
      reset: '/reset-password',
      forgot: '/forgot-password',
    },
    logout: '/logout',
  },
  deposit: '/deposit',
  apikey: '/api-key',
  trip: '/trip',
  authorization: '/authorization',
  dashboard: '/dashboard',
  customization: '/customization',
  campaign: '/campaign',
  agency: '/agency',
  invoice: '/invoice',
  user: '/user',
  legal_mention: '/mention-legal',
  cgu: '/cgu',
  cookie_policy: '/cookie-policy',
};

export default Urls;
