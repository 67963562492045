import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Page, StatCard } from '@trellixio/roaster-coffee';
import { useQuery } from '@tanstack/react-query';
import { PermissionsContext } from 'context';
import { useAPI, useDebounce, useGetUser } from 'hooks';
import { EmptyTable, LockedPlatformAlert, LockableActionWrapper } from 'components';
import { printTable, formatNumber } from 'helpers';
import Locales from 'locales';
import TripsTable from './components/TripsTable';
import AddTripModal from './components/AddTripModal';
import TripCategoriesModal from './components/TripCategoriesModal';
import TripFilters from './components/TripFilters';
import style from './components/Trips.module.css';

// Definition of columns for the trips table
const COLUMNS = {
  SELECTOR: 'Selector',
  THUMBNAIL: 'Thumbnail',
  LAST_NAME: 'Nom',
  FIRST_NAME: 'Prénom',
  CATEGORY: 'Catégorie',
  PASSPORT: 'Passeport',
  NPI: 'NPI',
  DEPOSIT: 'Dépôts',
  BIRTHDAY: 'Naissance',
  BANK: 'Banque',
  TOTAL_AMOUNT: 'Total frais',
  NEXT_STEPS: 'Étape suivante',
  COMPANY: 'Société',
  DOCTOR: 'Médecin',
  HEALTH: 'Santé',
  MEDICAL_CHECKUP: 'Visite médicale',
  NOTE: 'Note',
  EXPIRATION: 'Expiration',
  PASSPORT_VALIDATION: 'Validation Passeport',
  ACTIONS: 'Actions',
};

// Define columns for each user type
const USER_TABLE = {
  // Columns for Agency users
  AGENCY: [
    COLUMNS.THUMBNAIL,
    COLUMNS.PASSPORT,
    COLUMNS.NPI,
    COLUMNS.FIRST_NAME,
    COLUMNS.LAST_NAME,
    COLUMNS.CATEGORY,
    COLUMNS.DEPOSIT,
    COLUMNS.BANK,
    COLUMNS.TOTAL_AMOUNT,
    COLUMNS.NEXT_STEPS,
    COLUMNS.HEALTH,
    COLUMNS.NOTE,
    COLUMNS.ACTIONS,
  ],
  // Columns for Immigration Manager users
  IMMIGRATION_MANAGER: [
    COLUMNS.THUMBNAIL,
    COLUMNS.PASSPORT,
    COLUMNS.NPI,
    COLUMNS.FIRST_NAME,
    COLUMNS.LAST_NAME,
    COLUMNS.CATEGORY,
    COLUMNS.BIRTHDAY,
    COLUMNS.NEXT_STEPS,
    COLUMNS.COMPANY,
    COLUMNS.EXPIRATION,
    COLUMNS.PASSPORT_VALIDATION,
  ],
  // Columns for Health Controller users
  HEALTH_CONTROLLER: [
    COLUMNS.THUMBNAIL,
    COLUMNS.PASSPORT,
    COLUMNS.NPI,
    COLUMNS.FIRST_NAME,
    COLUMNS.LAST_NAME,
    COLUMNS.CATEGORY,
    COLUMNS.BIRTHDAY,
    COLUMNS.NEXT_STEPS,
    COLUMNS.COMPANY,
    COLUMNS.DOCTOR,
    COLUMNS.HEALTH,
    COLUMNS.MEDICAL_CHECKUP,
  ],
  // Columns for Admin users
  ADMIN: [
    COLUMNS.THUMBNAIL,
    COLUMNS.PASSPORT,
    COLUMNS.NPI,
    COLUMNS.FIRST_NAME,
    COLUMNS.LAST_NAME,
    COLUMNS.CATEGORY,
    COLUMNS.DEPOSIT,
    COLUMNS.BANK,
    COLUMNS.TOTAL_AMOUNT,
    COLUMNS.NEXT_STEPS,
    COLUMNS.COMPANY,
    COLUMNS.HEALTH,
    COLUMNS.NOTE,
    COLUMNS.ACTIONS,
  ],
  // Columns for Visa Controller users
  VISA_CONTROLLER: [
    COLUMNS.SELECTOR,
    COLUMNS.THUMBNAIL,
    COLUMNS.PASSPORT,
    COLUMNS.NPI,
    COLUMNS.FIRST_NAME,
    COLUMNS.LAST_NAME,
    COLUMNS.CATEGORY,
    COLUMNS.BIRTHDAY,
    COLUMNS.BANK,
    COLUMNS.NEXT_STEPS,
    COLUMNS.COMPANY,
    COLUMNS.HEALTH,
    COLUMNS.ACTIONS,
  ],
};

const Trip = () => {
  const { isPlatformLocked } = useContext(PermissionsContext);
  const { isAgency, isMAEC, isDEI, isMS, isAGLO, user } = useGetUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const preSetSearch = searchParams.get('q');
  const [searchQuery, setSearchQuery] = useState(preSetSearch || '');
  const [originalHeader, setOriginalHeader] = useState(null);
  const [isAddModalOpened, setAddModalOpened] = useState(false);
  const [isCategoriesModalOpened, setCategoriesModalOpened] = useState(false);
  const [cursor, setCursor] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [queryFilters, setQueryFilter] = useState(null);
  const tableRef = useRef(null);

  const { api } = useAPI();
  const limit = 30;

  // Here the search is applied to the selected filters
  const fetchParams = searchQuery.length > 0 ? { q: debouncedSearchQuery } : { ...queryFilters, limit, cursor };

  const { data: preferences } = useQuery({
    queryFn: api.retrieve('preference', 'current/'),
    queryKey: ['preference/current/'],
  });

  const registrationDeposit = preferences?.registration_deposit;
  const preferencesId = preferences?.id;

  const { data: trips } = useQuery({
    queryFn: api.list('trip/', fetchParams),
    queryKey: ['trip/', debouncedSearchQuery, limit, cursor, queryFilters],
    keepPreviousData: true,
  });

  const { data: agencies } = useQuery({
    queryFn: api.list('agency/', { limit: 100 }),
    queryKey: ['agency/'],
  });

  const { data: categoriesData } = useQuery({
    queryFn: api.list('category/', { limit: 100 }),
    queryKey: ['category/'],
  });

  const { data: tripsStatsDefaultData } = useQuery({
    queryFn: api.list('trip/stats/default/'),
    queryKey: ['trip/stats/default/'],
    enabled: !isDEI,
  });

  const { data: tripsStatsCategoryData } = useQuery({
    queryFn: api.list('trip/stats/category/'),
    queryKey: ['trip/stats/category/'],
    enabled: isAgency || isAGLO,
  });

  const { data: tripsStatsCategoryPaidData } = useQuery({
    queryFn: api.list('trip/stats/trip_category_paid/'),
    queryKey: ['trip/stats/trip_category_paid/'],
    enabled: isAgency || isAGLO,
  });

  const tripsStatsDefault = tripsStatsDefaultData?.data.data;

  const tripsStatsCategory = tripsStatsCategoryData?.data.data;

  const tripsStatsCategoryPaid = tripsStatsCategoryPaidData?.data.data;

  const tripsStats = [...(tripsStatsDefault || []), ...(tripsStatsCategory || []), ...(tripsStatsCategoryPaid || [])];

  const connectedAgencyData = agencies?.find((agency) => agency.id === user.organization_id);

  const mergerRequested = connectedAgencyData?.merger?.requested;

  const categories = categoriesData
    ?.map((category) => ({
      ...category,
      value: category.id,
      label: category.name,
    }))
    .reverse();

  // This function returns an array of column indexes for a given user type and column names
  const getColumnIndexes = (userType, ...columnNames) => {
    return columnNames.map((columnName) => USER_TABLE[userType].indexOf(COLUMNS[columnName]));
  };

  // This function determines which columns to hide based on the user's role
  const getColumnsToHide = () => {
    let columnsToHide = [];
    if (isAgency) {
      columnsToHide = getColumnIndexes('AGENCY', 'ACTIONS');
    } else if (isDEI) {
      columnsToHide = getColumnIndexes('IMMIGRATION_MANAGER', 'PASSPORT_VALIDATION');
    } else if (isMS) {
      columnsToHide = getColumnIndexes('HEALTH_CONTROLLER', 'MEDICAL_CHECKUP');
    } else if (isAGLO) {
      columnsToHide = getColumnIndexes('ADMIN', 'ACTIONS');
    } else if (isMAEC) {
      columnsToHide = getColumnIndexes('VISA_CONTROLLER', 'SELECTOR', 'THUMBNAIL', 'ACTIONS');
    }
    return columnsToHide;
  };

  // This function updates the original header of the table by cloning it if it hasn't been set yet
  const updateOriginalHeader = () => {
    if (tableRef.current && !originalHeader) {
      const header = tableRef.current.querySelector('thead');
      if (header) {
        setOriginalHeader(header.cloneNode(true));
      }
    }
  };

  const toggleAddModalOpen = () => setAddModalOpened((val) => !val);

  const toggleCategoriesModal = () => setCategoriesModalOpened((val) => !val);

  useEffect(() => {
    updateOriginalHeader();
  }, [trips]);

  return (
    trips && (
      <Page
        className={style.container}
        title={Locales.trip.title}
        size="full"
        primaryAction={
          isAgency && (
            <LockableActionWrapper
              className={mergerRequested?.length > 0 ? style['disabled-field'] : ''}
              isPlatformLocked={isPlatformLocked}
              callback={toggleAddModalOpen}
            >
              <Button variant="primary" color="success" disabled={mergerRequested?.length > 0}>
                <i className="fa-regular fa-solid fa-plus" />
                {Locales.base.button.add}
              </Button>
            </LockableActionWrapper>
          )
        }
        secondaryAction={
          <Button
            variant="secondary"
            color="success"
            onClick={() => printTable(tableRef, originalHeader, getColumnsToHide())}
          >
            <i className="fa-regular fa-solid fa-print" />
            {Locales.base.button.print}
          </Button>
        }
      >
        {isPlatformLocked && isAgency && <LockedPlatformAlert />}

        {!isDEI && tripsStats && (
          <div className="stats-container items-group wrap xl">
            {tripsStats.map((stats) => {
              let result = null;

              if (stats.percent === null) {
                result = (
                  <StatCard
                    key={stats.name}
                    title={stats.name}
                    value={formatNumber(stats.value)}
                    description={stats.description}
                  />
                );
              } else {
                result = (
                  <StatCard
                    key={stats.name}
                    title={stats.name}
                    value={formatNumber(stats.value)}
                    description={stats.description}
                    progressValue={stats.percent}
                    progressLabel={`${stats.percent}%`}
                  />
                );
              }

              return result;
            })}
          </div>
        )}

        <TripFilters
          agencies={agencies}
          trips={trips}
          categories={categories}
          queryFilters={queryFilters}
          setQueryFilter={setQueryFilter}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          preSetSearch={preSetSearch}
        />

        {trips.count > 0 && !!categories && (
          <TripsTable
            registrationDepot={registrationDeposit}
            preferencesId={preferencesId}
            tableData={trips}
            limit={limit}
            setCursor={setCursor}
            tableRef={tableRef}
            isPlatformLocked={isPlatformLocked}
            categories={categories}
            openCategoriesModal={toggleCategoriesModal}
          />
        )}

        {trips.count === 0 && <EmptyTable isSearchResult={queryFilters || searchQuery.length > 0} />}

        {isAddModalOpened && !!categories && (
          <AddTripModal
            isOpened={isAddModalOpened}
            closeModal={toggleAddModalOpen}
            categories={categories}
            openCategoriesModal={toggleCategoriesModal}
          />
        )}

        {isCategoriesModalOpened && (
          <TripCategoriesModal
            isOpened={isCategoriesModalOpened}
            closeModal={toggleCategoriesModal}
            categoriesData={categoriesData}
          />
        )}
      </Page>
    )
  );
};

export default Trip;
