import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TextField, Button, Alert, Page, BlockCard, Spinner } from '@trellixio/roaster-coffee';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Urls } from 'data';
import { Storage, validateEmail, formatDate } from 'helpers';
import { useAPI } from 'hooks';
import Locales from 'locales';
import LegalNotice from './components/LegalNotice';

const CONST_USER = 'user';
const CONST_USER_TOKEN = 'user_token';
const CONST_LEGAL_ACCEPTED = 'legal_accepted';
const CONST_LEGAL_DOWNLOADED = 'legal_downloaded';
const CONST_LEGAL_ACCEPTANCE_DATE = 'legal_acceptance_date';

const Login = () => {
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [error, setError] = useState('');
  const [displayPwd, setDisplayPwd] = useState(false);
  const storage = new Storage(localStorage, 'eHadj-');
  const [LegalNoticeOpen, setLegalNoticeOpen] = useState(true);
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;
  const isEmptyField = email.trim() === '' || pwd.trim() === '';
  const isDisabled = isEmptyField || isMutating;
  const isLegalAccepted = storage.getData(CONST_LEGAL_ACCEPTED) === true;
  const isLegalDownloaded = storage.getData(CONST_LEGAL_DOWNLOADED) === true;

  const downloadConsentReceipt = () => {
    if (!isLegalDownloaded) {
      const pdfUrl = `${process.env.PUBLIC_URL}pdfs/legal-consent.pdf`;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = Locales.base.legals.document_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      storage.set(CONST_LEGAL_DOWNLOADED, true);
    }
  };

  const { mutate: logUser } = useMutation({
    mutationFn: () => {
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      };

      return api.post(
        'auth/user_token/',
        {
          email,
          password: pwd,
        },
        { headers }
      );
    },
    onSuccess: ({ data }) => {
      setError('');
      // set token in storage
      storage.set(CONST_USER_TOKEN, data.auth_key);
      // set user in storage
      storage.set(CONST_USER, JSON.stringify(data.user));
      // download consent receipt
      downloadConsentReceipt();
      // redirect to landing page
      window.location = Urls.base;
    },
    onError: () => {
      setError(Locales.auth.errors.invalid_identifiers);
      setPwd('');
      setEmail('');
    },
  });

  const handleLogin = (event) => {
    event.preventDefault();
    const validEmail = validateEmail(email);

    if (!validEmail) {
      setError(Locales.auth.errors.invalid_email);
      setPwd('');
      setEmail('');
      return;
    }

    setError('');
    logUser(); // only log user when all data are verified
  };

  const toggleDisplayPassword = () => setDisplayPwd((val) => !val);

  const toggleLegalNoticeOpen = () => setLegalNoticeOpen((val) => !val);

  const acceptCgu = () => {
    storage.set(CONST_LEGAL_ACCEPTED, true);
    const acceptanceDateTime = formatDate(new Date(), 'dddd DD MMMM YYYY [à] HH:mm:ss');
    storage.set(CONST_LEGAL_ACCEPTANCE_DATE, acceptanceDateTime);
    toggleLegalNoticeOpen();
  };

  return (
    <Page size="medium" className="vcenter">
      {isLegalAccepted && (
        <div className="auth-form-container">
          <form>
            <BlockCard
              title={
                <div className="items-group">
                  <h2>{Locales.auth.login}</h2>
                  {isMutating && <Spinner />}
                </div>
              }
            >
              {error && <Alert level="error" message={error} />}
              <TextField
                label={Locales.base.field.email}
                name="email"
                type="email"
                value={email}
                onChange={setEmail}
                placeholder={Locales.base.field.placeholder.email}
                required
              />
              <TextField
                label={Locales.base.field.password}
                name="pwd"
                value={pwd}
                type={displayPwd ? 'text' : 'password'}
                rightSection={
                  <Button variant="secondary" onClick={toggleDisplayPassword}>
                    {displayPwd ? <i className="fa-regular fa-eye-slash" /> : <i className="fa-regular fa-eye" />}
                  </Button>
                }
                onChange={setPwd}
                placeholder="••••••••••••••••••"
                required
              />

              <Link to={Urls.front.password.forgot}>{Locales.auth.forgot_pwd.subtitle}</Link>

              <Button variant="primary" color="success" submit onClick={handleLogin} disabled={isDisabled}>
                {Locales.auth.connect}
              </Button>
            </BlockCard>
          </form>
        </div>
      )}

      {LegalNoticeOpen && !isLegalAccepted && <LegalNotice cta={acceptCgu} />}
    </Page>
  );
};

export default Login;
