import React, { useState, useContext } from 'react';
import { BlockCard, Button, Alert, Select, ActionCard, openModal, showToast } from '@trellixio/roaster-coffee';
import { useMutation, useQueryClient, useIsMutating } from '@tanstack/react-query';
import { LockableActionWrapper } from 'components';
import { PermissionsContext } from 'context';
import { useGetAgency, useAPI } from 'hooks';
import Locales from 'locales';
import PropTypes from 'prop-types';
import AuthorizationAlert from './AuthorizationAlert';

const CONST_MERGED = 'merged';
const CONST_REQUESTING = 'requesting';

const MergerBlock = ({ userAgency, sortedAgencyList, combinedAgenciesArray }) => {
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const { isPlatformLocked } = useContext(PermissionsContext);
  const [selectedAgency, setSelectedAgency] = useState(
    sortedAgencyList && sortedAgencyList.length > 0 ? sortedAgencyList[0].id : ''
  );
  const { mergerRequested, mergerApproved, agencyMasterName, agencyId, agencyMerger, agencyMasterId } =
    useGetAgency(userAgency);
  const isMutating = useIsMutating() > 0;
  const showAlertWithCancelButton = !mergerApproved && mergerRequested;
  const showSelectAgencyField = mergerApproved || mergerRequested || (!mergerApproved && mergerRequested);

  const { mutate: mergerRequest } = useMutation({
    mutationFn: (selectedAgencyId) =>
      api.post(`agency/current/merger_request/?agency_master_id=${selectedAgencyId}`, {
        merger: {
          approved: null,
          requested: null,
          rejected: null,
          master_id: agencyId,
          master_name: null,
          trip_count: null,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agency/'] });
      showToast({
        message: Locales.authorization.toast.merger_request,
        level: 'success',
      });
    },
  });

  const { mutate: cancelMergerRequest } = useMutation({
    mutationFn: () => api.post(`agency/current/merger_cancel/`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agency/'] });
      showToast({
        message: Locales.authorization.toast.merger_cancel,
        level: 'success',
      });
    },
  });

  const { mutate: approveMergerRequest } = useMutation({
    mutationFn: (agencySlaveId) =>
      api.post(`agency/current/merger_approve/?agency_slave_id=${agencySlaveId}`, {
        merger: {
          approved: agencyMerger.approved,
          requested: agencyMerger.requested,
          rejected: null,
          master_id: agencyMasterId,
          master_name: null,
          trip_count: null,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agency/'] });
      queryClient.invalidateQueries({ queryKey: ['agency/stats/default/'] });
      showToast({
        message: Locales.authorization.toast.merger_approve,
        level: 'success',
      });
    },
  });

  const handleOpenMergerRequestModal = () => {
    openModal({
      title: Locales.authorization.merger_request,
      children: (
        <div>
          <p>{Locales.formatString(Locales.authorization.merger_request_message)}</p>
          <div className="content-row" />
          <strong>
            <p>{Locales.formatString(Locales.authorization.pilgrim_add_not_allowed)}</p>
          </strong>
        </div>
      ),
      confirmProps: {
        children: Locales.authorization.request_the_merger,
        variant: 'primary',
        color: 'success',
        disabled: isMutating,
      },
      cancelProps: {
        children: Locales.base.button.discard,
        variant: 'secondary',
        color: '',
      },
      onCancel: () => {},
      onConfirm: () => mergerRequest(selectedAgency),
    });
  };

  const handleOpenCancelMergerRequestModal = () => {
    openModal({
      title: Locales.authorization.merger_cancel,
      children: <p>{Locales.formatString(Locales.authorization.cancel_merger)} </p>,
      confirmProps: {
        children: Locales.authorization.cancel_grouping_request,
        variant: 'primary',
        color: 'danger',
      },
      cancelProps: {
        children: Locales.authorization.conserver,
        variant: 'secondary',
        color: '',
      },
      onCancel: () => {},
      onConfirm: () => cancelMergerRequest(),
    });
  };

  const getMessage = (agency) => {
    if (agency.tripCount === null || agency.tripCount === 0) {
      return agency.type === CONST_REQUESTING
        ? Locales.authorization.trip_merge_wanted
        : Locales.authorization.trip_merged;
    }

    return agency.type === CONST_MERGED
      ? Locales.authorization.trip_transferred
      : Locales.authorization.trip_transfer_wanted;
  };

  return (
    <>
      <BlockCard title={Locales.authorization.grouping}>
        <div>
          <p>{Locales.authorization.grouping_explanation}</p>
          <div className="content-row" />
          <p>{Locales.authorization.grouping_initialization}</p>
        </div>

        <form>
          {showAlertWithCancelButton && (
            <>
              <div className="content-row">
                <Alert
                  level="info"
                  message={
                    <span className="alert-children">
                      {Locales.formatString(Locales.authorization.alert.information_message, {
                        name: <strong>{agencyMasterName}</strong>,
                        pilgrim_add_not_allowed: <strong>{Locales.authorization.alert.pilgrim_add_not_allowed}</strong>,
                      })}
                    </span>
                  }
                />
              </div>
              <LockableActionWrapper
                isPlatformLocked={isPlatformLocked}
                callback={() => handleOpenCancelMergerRequestModal()}
              >
                <Button variant="primary" color="danger" disabled={isMutating}>
                  {Locales.authorization.cancel_grouping_request}
                </Button>
              </LockableActionWrapper>
            </>
          )}

          {!showSelectAgencyField && (
            <>
              <div className="content-row">
                <Select
                  label={Locales.authorization.society}
                  data={sortedAgencyList || []}
                  helpText={Locales.authorization.select_society}
                  value={selectedAgency}
                  onChange={setSelectedAgency}
                />
              </div>
              <LockableActionWrapper
                isPlatformLocked={isPlatformLocked}
                callback={() => handleOpenMergerRequestModal()}
              >
                <Button variant="primary" color="success" disabled={isMutating}>
                  {Locales.authorization.grouping_request}
                </Button>
              </LockableActionWrapper>
            </>
          )}

          <AuthorizationAlert
            condition={mergerApproved && mergerRequested}
            level="info"
            message={Locales.formatString(Locales.authorization.alert.merger_accepted, {
              name: <strong>{agencyMasterName}</strong>,
            })}
          />
        </form>
      </BlockCard>

      {combinedAgenciesArray.map((agency) => (
        <ActionCard
          key={agency.id}
          actions={
            agency.type === CONST_REQUESTING && (
              <LockableActionWrapper
                isPlatformLocked={isPlatformLocked}
                callback={() => approveMergerRequest(agency.id)}
              >
                <Button variant="primary" color="success" disabled={isMutating}>
                  {Locales.authorization.button.accept}
                </Button>
              </LockableActionWrapper>
            )
          }
        >
          <h4>
            {Locales.authorization.group} - {agency.name}
          </h4>
          <p>
            {Locales.formatString(getMessage(agency), {
              name: <strong>{agency.name}</strong>,
              trip_count: agency.tripCount,
            })}
          </p>
        </ActionCard>
      ))}
    </>
  );
};

MergerBlock.defaultProps = {
  userAgency: null,
  sortedAgencyList: null,
  combinedAgenciesArray: null,
};

MergerBlock.propTypes = {
  userAgency: PropTypes.shape({}),
  sortedAgencyList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  combinedAgenciesArray: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      tripCount: PropTypes.number,
      type: PropTypes.string,
    })
  ),
};

export default MergerBlock;
