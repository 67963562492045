import React from 'react';
import { Button, Alert } from '@trellixio/roaster-coffee';
import { useIsMutating } from '@tanstack/react-query';
import Locales from 'locales';
import PropTypes from 'prop-types';

const PlatformLocked = ({ handleOpenConfirmationModal }) => {
  const isMutating = useIsMutating() > 0;

  return (
    <>
      <Alert message={Locales.campaign.settings.alert.lock_platform} level="error" />

      <p>{Locales.campaign.settings.description_unlock}</p>

      <Button variant="primary" color="success" disabled={isMutating} onClick={handleOpenConfirmationModal}>
        {Locales.campaign.settings.button.unlock_platform}
      </Button>
    </>
  );
};

PlatformLocked.defaultProps = {
  handleOpenConfirmationModal: null,
};

PlatformLocked.propTypes = {
  handleOpenConfirmationModal: PropTypes.func,
};

export default PlatformLocked;
