import React, { useMemo, createContext } from 'react';
import { Storage } from 'helpers';
import PropTypes from 'prop-types';

export const UserContext = createContext({});

const CONST_USER = 'user';
const CONST_USER_TOKEN = 'user_token';

const UserProvider = ({ children }) => {
  const storage = new Storage(localStorage, 'eHadj-');

  const user = storage.getData(CONST_USER);
  const token = storage.get(CONST_USER_TOKEN);
  const isConnected = !!token;

  const primaryMAECMail = 'boda@gouv.bj';
  const primaryMSMail = 'sosalifou@gouv.bj';

  // DSI => Direction des Systèmes Informatique de la Présidence
  // ASIN => Agence des Systèmes d’Information et du Numérique
  const isSuperAdmin =
    user && (user.role === 'DSI1' || user.role === 'DSI2' || user.role === 'ASIN1' || user.role === 'ASIN2'); // DSI or ASIN user

  // AGLO => Agence pour la Gestion de la Logistique des Officiels
  const isAGLO = user && (user.role === 'AGLO1' || user.role === 'AGLO2'); // Any AGLO user
  const isAGLOPrimary = user && user.role === 'AGLO1'; // AGLO primary user
  const isAGLOSecondary = user && user.role === 'AGLO2'; // AGLO secondary user

  // SA => Société Agréée
  const isAgency = user && (user.role === 'SA1' || user.role === 'SA2'); // Any SA user
  const isAgencyPrimary = user && user.role === 'SA1'; // SA primary user
  const isAgencySecondary = user && user.role === 'SA2'; // SA secondary user

  // MS => Ministère de la Santé
  const isMS = user && (user.role === 'MS1' || user.role === 'MS2'); // Any MS user
  // const isMSPrimary = user && user.role === 'MS1'; // MS primary user
  // const isMSSecondary = user && user.role === 'MS2'; // MS secondary user

  // HOTFIX define primary and secondary MS
  const isMSPrimary = user && user.role === 'MS1' && user.email === primaryMSMail;
  const isMSSecondary = user && user.role === 'MS1' && user.email !== primaryMSMail; // MS secondary user

  // MAEC => Ministère des Affaires Étrangères et de la Coopération
  const isMAEC = user && (user.role === 'MAEC1' || user.role === 'MAEC2'); // Any MAEC user
  // const isMAECPrimary = user && user.role === 'MAEC1'; // MAEC primary user
  // const isMAECSecondary = user && user.role === 'MAEC2'; // MAEC secondary user

  // HOTFIX define primary and secondary MAEC>
  const isMAECPrimary = user && user.role === 'MAEC1' && user.email === primaryMAECMail; // MAEC primary user
  const isMAECSecondary = user && user.role === 'MAEC1' && user.email !== primaryMAECMail; // MAEC secondary user

  // BANK => Banques: ECOBANK and BIIC (Banque Internationale pour l'Industrie et le Commerce)
  const isBank = user && (user.role === 'BANK1' || user.role === 'BANK2'); // Any BANK user
  const isBankPrimary = user && user.role === 'BANK1'; // BANK primary user
  const isBankSecondary = user && user.role === 'BANK2'; // BANK secondary user

  // DEI => Direction de l’Émigration et de l’Immigration
  const isDEI = user && (user.role === 'DEI1' || user.role === 'DEI2'); // Any DEI user
  const isDEIPrimary = user && user.role === 'DEI1'; // DEI primary user
  const isDEISecondary = user && user.role === 'DEI2'; // DEI secondary user

  const isPrimaryUser =
    isSuperAdmin || isAGLOPrimary || isAgencyPrimary || isMSPrimary || isMAECPrimary || isDEIPrimary || isBankPrimary;

  const memoValues = useMemo(
    () => ({
      user,
      isSuperAdmin,
      isAGLO,
      isAGLOPrimary,
      isAGLOSecondary,
      isAgency,
      isAgencyPrimary,
      isAgencySecondary,
      isMS,
      isMSPrimary,
      isMSSecondary,
      isMAEC,
      isMAECPrimary,
      isMAECSecondary,
      isDEI,
      isDEIPrimary,
      isDEISecondary,
      isBank,
      isBankPrimary,
      isBankSecondary,
      isPrimaryUser,
      isConnected,
    }),
    [user]
  );

  return <UserContext.Provider value={memoValues}>{children}</UserContext.Provider>;
};

UserProvider.defaultProps = {
  children: null,
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export default UserProvider;
