import React, { useState } from 'react';
import { Page } from '@trellixio/roaster-coffee';
import Locales from 'locales';
import { useFormatTextWithLinks } from 'hooks';
import style from '../LegalMention/Legal.module.css';

const Cgu = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleBlock = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Page title={Locales.cgu.title} className={style.container}>
      <nav className="settings">
        <ol>
          {Locales.cgu.articles.map((article) => (
            <li key={article.id}>
              <a
                href={article.href}
                onClick={(e) => {
                  e.preventDefault();
                  toggleBlock(article.id);
                }}
              >
                {article.article}
              </a>
              {openIndex === article.id && (
                <section className={style.content}>
                  {article.content.map((paragraph) => {
                    if (typeof paragraph === 'string') {
                      return <p key={`text-${paragraph.id}`}>{useFormatTextWithLinks(paragraph)}</p>;
                    }
                    if (paragraph.type === 'list') {
                      return (
                        <ul key={`list-${paragraph.id}`} className={style.list}>
                          {paragraph.items.map((item) => (
                            <li key={`item-${item.id}`}>{useFormatTextWithLinks(item)}</li>
                          ))}
                        </ul>
                      );
                    }
                    return null;
                  })}
                </section>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </Page>
  );
};

export default Cgu;
