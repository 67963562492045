import React from 'react';
import PropTypes from 'prop-types';
import { useCheckPermission } from 'hooks';
import LockedPlatformModal from './LockedPlatformModal';
import style from './Lockable.module.css';

const LockableActionWrapper = ({ isPlatformLocked, callback, children, className }) => {
  const { checkPermission, openedLockedModal, closeLockedModal } = useCheckPermission();

  const handleClick = () => {
    checkPermission(isPlatformLocked, callback);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick();
    }
  };

  return (
    <>
      <div
        className={`${className} ${style.content}`}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
      >
        {children}
      </div>
      <LockedPlatformModal opened={openedLockedModal} onClose={closeLockedModal} />
    </>
  );
};

LockableActionWrapper.defaultProps = {
  isPlatformLocked: false,
  callback: () => {},
  children: null,
  className: null,
};

LockableActionWrapper.propTypes = {
  isPlatformLocked: PropTypes.bool,
  callback: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default LockableActionWrapper;
