import React from 'react';
import {
  Table,
  Button,
  Icon,
  useSelectableResourceState,
  ModalProvider,
  showToast,
  openModal,
  Tooltip,
} from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { formatDate, hasNextPage, hasPreviousPage, updateQueryCursor, showPagination, formatNumber } from 'helpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI, useGetUser } from 'hooks';
import Locales from 'locales';
import depositIcon from 'assets/img/sidebar/deposit.svg';
import { LockableActionWrapper } from 'components';
import style from './DepositsTable.module.css';

const DepositsTable = ({ tableData, setCursor, limit, tableRef, isPlatformLocked, isInvoiceOnlyDeposit }) => {
  const { isAgency, isAGLO } = useGetUser();
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useSelectableResourceState(tableData);

  const isMutating = queryClient.isMutating() > 0;

  const { mutate: generateInvoice } = useMutation({
    mutationFn: () => api.post('invoice/approve_deposits/', selectedResources),
    onSuccess: ({ data }) => {
      const { code } = data;
      queryClient.invalidateQueries({ queryKey: ['invoice/'] });
      queryClient.invalidateQueries({ queryKey: ['deposit/'] });
      queryClient.invalidateQueries({ queryKey: ['deposit/stats/default/'] });
      showToast({
        message: Locales.formatString(Locales.deposit.toast.update_invoice, { code }),
        level: 'success',
      });
      selectedResources.length = 0;
    },
  });

  const { mutate: deleteDeposit } = useMutation({
    mutationFn: (deposit) => api.remove('deposit', deposit.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['invoice/'] });
      queryClient.invalidateQueries({ queryKey: ['deposit/'] });
      queryClient.invalidateQueries({ queryKey: ['deposit/stats/default/'] });
      showToast({
        message: Locales.formatString(Locales.deposit.toast.delete_deposit),
        level: 'success',
      });
    },
  });

  const handleOpenDeleteModal = (deposit) => {
    const { amount, pilgrim_name: pilgrimName, invoice_code: invoiceCode } = deposit;
    openModal({
      title: Locales.deposit.suppress_deposit,
      children: (
        <p>
          {Locales.formatString(Locales.deposit.delete_deposit, {
            amount: <strong>{formatNumber(amount)}</strong>,
            fullName: <strong>{pilgrimName}</strong>,
          })}
          {isInvoiceOnlyDeposit(invoiceCode) &&
            Locales.formatString(Locales.deposit.warning_last_deposit, {
              code: <strong>{invoiceCode}</strong>,
            })}
        </p>
      ),
      closeOnConfirm: true,
      closeOnCancel: true,
      cancelProps: { children: Locales.base.button.discard, variant: 'secondary', color: 'secondary' },
      confirmProps: { children: Locales.base.button.delete, variant: 'primary', color: 'danger' },
      onCancel: () => {},
      onConfirm: () => deleteDeposit(deposit),
    });
  };

  return (
    <ModalProvider>
      <div className={style.table} ref={tableRef}>
        <Table
          showPagination={showPagination(tableData, limit)}
          itemCount={tableData.length}
          selectable={isAgency}
          hasNextPage={hasNextPage(tableData)}
          hasPreviousPage={hasPreviousPage(tableData)}
          onNextPage={() => updateQueryCursor(tableData.next, setCursor)}
          onPreviousPage={() => updateQueryCursor(tableData.previous, setCursor)}
          selectedItemsCount={allResourcesSelected ? Locales.base.all : selectedResources.length}
          onSelectionChange={handleSelectionChange}
          selectActions={
            <LockableActionWrapper isPlatformLocked={isPlatformLocked} callback={() => generateInvoice()}>
              <Button variant="secondary" disabled={isMutating}>
                {Locales.deposit.add_current_invoice}
              </Button>
            </LockableActionWrapper>
          }
        >
          <Table.Head>
            <Table.HeadCell type="thumbnail" />
            <Table.HeadCell type="date">
              {Locales.deposit.date_deposit}
              <Tooltip inline label={Locales.deposit.tooltips.creation_date}>
                <Icon hasTooltip>
                  <i className="fa-regular fa-circle-info" />
                </Icon>
              </Tooltip>
            </Table.HeadCell>

            {isAGLO && (
              <Table.HeadCell type="text">
                {Locales.deposit.agency}
                <Tooltip inline label={Locales.deposit.tooltips.agency}>
                  <Icon hasTooltip>
                    <i className="fa-regular fa-circle-info" />
                  </Icon>
                </Tooltip>
              </Table.HeadCell>
            )}

            <Table.HeadCell type="text">{Locales.deposit.pilgrim}</Table.HeadCell>
            <Table.HeadCell type="text">{Locales.deposit.passport}</Table.HeadCell>
            <Table.HeadCell type="numeric">{Locales.deposit.amount}</Table.HeadCell>
            <Table.HeadCell type="text">
              {Locales.deposit.invoice}
              <Tooltip inline label={Locales.deposit.tooltips.invoice_associated}>
                <Icon hasTooltip>
                  <i className="fa-regular fa-circle-info" />
                </Icon>
              </Tooltip>
            </Table.HeadCell>
            <Table.HeadCell type="enum">
              {Locales.deposit.status}
              <Tooltip inline label={Locales.deposit.tooltips.bank_confirmation}>
                <Icon hasTooltip>
                  <i className="fa-regular fa-circle-info" />
                </Icon>
              </Tooltip>
            </Table.HeadCell>
            <Table.HeadCell type="object">{Locales.deposit.actions}</Table.HeadCell>
          </Table.Head>

          {tableData && tableData.length > 0 && (
            <Table.Body>
              {tableData.map((data) => (
                <Table.Row
                  key={data.id}
                  id={data.id.toString()}
                  selected={selectedResources.includes(data.id.toString())}
                >
                  <Table.Cell type="thumbnail">
                    <Icon>
                      <img src={depositIcon} alt="" />
                    </Icon>
                  </Table.Cell>
                  <Table.Cell type="date">{formatDate(data.created)}</Table.Cell>
                  {isAGLO && <Table.Cell type="text">{data.agency_name}</Table.Cell>}
                  <Table.Cell type="text">{data.pilgrim_name}</Table.Cell>
                  <Table.Cell type="text">{data.pilgrim_passport || '-'}</Table.Cell>
                  <Table.Cell type="numeric">{formatNumber(data.amount)}</Table.Cell>
                  <Table.Cell type="text">{data.invoice_code || '-'}</Table.Cell>
                  <Table.Cell type="enum">
                    {data.is_invoice_paid ? (
                      <Icon small color="success">
                        <i className="fa-solid fa-check" />
                      </Icon>
                    ) : (
                      <Icon small color="gray">
                        <i className="fa-solid fa-question" />
                      </Icon>
                    )}
                  </Table.Cell>
                  <Table.Cell type="object">
                    {isAgency && !data.is_invoice_paid && (
                      <LockableActionWrapper
                        isPlatformLocked={isPlatformLocked}
                        callback={() => handleOpenDeleteModal(data)}
                      >
                        <Button variant="secondary" color="danger" isIconButton>
                          <i className="fa-regular fa-solid fa-trash" />
                        </Button>
                      </LockableActionWrapper>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    </ModalProvider>
  );
};

DepositsTable.defaultProps = {
  tableData: null,
  setCursor: null,
  limit: null,
  tableRef: null,
  isInvoiceOnlyDeposit: false,
  isPlatformLocked: false,
};

DepositsTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  setCursor: PropTypes.func,
  limit: PropTypes.number,
  tableRef: PropTypes.shape({}),
  isInvoiceOnlyDeposit: PropTypes.bool,
  isPlatformLocked: PropTypes.bool,
};

export default DepositsTable;
