import React from 'react';
import { Table, Button, Icon, openModal, ModalProvider, showToast, Tooltip } from '@trellixio/roaster-coffee';
import { LockableActionWrapper } from 'components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI, useGetUser } from 'hooks';
import PropTypes from 'prop-types';
import { formatDate, hasNextPage, hasPreviousPage, updateQueryCursor, showPagination, formatNumber } from 'helpers';
import Locales from 'locales';
import invoiceIcon from 'assets/img/sidebar/invoice.svg';

const InvoicesTable = ({ tableData, setCursor, limit, isPlatformLocked }) => {
  const { isAgency, isBank } = useGetUser();

  const { api } = useAPI();
  const queryClient = useQueryClient();

  const { mutate: activateInvoice } = useMutation({
    mutationFn: (invoice) => api.post(`invoice/${invoice.id}/activate/`),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/default/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/category/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/trip_category_paid/'] });
      queryClient.invalidateQueries({ queryKey: ['invoice/'] });
      queryClient.invalidateQueries({ queryKey: ['category/'] });
      const { code } = data;
      showToast({
        message: Locales.formatString(Locales.invoice.toast.activate_invoice, {
          code: <strong>{code}</strong>,
        }),
        level: 'success',
      });
    },
  });

  const { mutate: deleteInvoice } = useMutation({
    mutationFn: (invoice) => api.remove('invoice', invoice.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoice/'] });
      queryClient.invalidateQueries({ queryKey: ['deposit/'] });
      showToast({
        message: Locales.invoice.toast.delete_invoice,
        level: 'success',
      });
    },
  });

  const handleOpenActivateInvoiceModal = (invoice) => {
    const { amount, code } = invoice;
    openModal({
      title: Locales.invoice.action_modal.title.validation,
      children: (
        <div>
          <p>
            {Locales.formatString(Locales.invoice.confirm_payment, {
              amount: <strong>{formatNumber(amount)}</strong>,
              code: <strong>{code}</strong>,
            })}
          </p>
        </div>
      ),
      confirmProps: { children: Locales.base.button.validate, variant: 'primary', color: 'success' },
      cancelProps: { children: Locales.base.button.cancel, variant: 'secondary', color: '' },
      onCancel: () => {},
      onConfirm: () => activateInvoice(invoice),
    });
  };

  const handleOpenDeleteInvoiceModal = (invoice) => {
    const { amount, code } = invoice;
    openModal({
      title: Locales.invoice.action_modal.title.delete,
      children: (
        <div>
          <p>
            {Locales.formatString(Locales.invoice.delete_payment, {
              amount: <strong>{formatNumber(amount)}</strong>,
              code: <strong>{code}</strong>,
            })}
          </p>
        </div>
      ),
      confirmProps: { children: Locales.base.button.delete, variant: 'primary', color: 'danger' },
      cancelProps: { children: Locales.base.button.cancel, variant: 'secondary', color: '' },
      onCancel: () => {},
      onConfirm: () => deleteInvoice(invoice),
    });
  };

  return (
    <ModalProvider>
      <Table
        showPagination={showPagination(tableData, limit)}
        hasNextPage={hasNextPage(tableData)}
        hasPreviousPage={hasPreviousPage(tableData)}
        onNextPage={() => updateQueryCursor(tableData.next, setCursor)}
        onPreviousPage={() => updateQueryCursor(tableData.previous, setCursor)}
      >
        <Table.Head>
          <Table.HeadCell type="thumbnail" />
          <Table.HeadCell type="text">
            {Locales.invoice.field.reference}
            <Tooltip inline label={Locales.invoice.tooltips.reference}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="paragraph">{Locales.invoice.field.agency}</Table.HeadCell>
          <Table.HeadCell type="numeric">
            {Locales.invoice.field.amount}
            <Tooltip inline label={Locales.invoice.tooltips.amount}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="numeric">
            {Locales.invoice.field.deposits}
            <Tooltip inline label={Locales.invoice.tooltips.deposits}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="date">
            {Locales.invoice.field.created}
            <Tooltip inline label={Locales.invoice.tooltips.created}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="paragraph">{Locales.invoice.field.bank}</Table.HeadCell>
          <Table.HeadCell type="date">
            {Locales.invoice.field.paid}
            <Tooltip inline label={Locales.invoice.tooltips.paid}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="enum">
            {Locales.base.status.invoice_status}
            <Tooltip inline label={Locales.invoice.tooltips.status}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="object">{Locales.base.button.actions}</Table.HeadCell>
        </Table.Head>

        {tableData && tableData.length > 0 && (
          <Table.Body>
            {tableData.map((data) => (
              <Table.Row key={data.id} id={data.id}>
                <Table.Cell type="thumbnail">
                  <Icon>
                    <img src={invoiceIcon} alt="" />
                  </Icon>
                </Table.Cell>
                <Table.Cell type="text">{data.code}</Table.Cell>
                <Table.Cell type="paragraph">{data.agency_name}</Table.Cell>
                <Table.Cell type="numeric">{formatNumber(data.amount)}</Table.Cell>
                <Table.Cell type="numeric">{data.deposit_count}</Table.Cell>
                <Table.Cell type="date">{formatDate(data.created)}</Table.Cell>
                <Table.Cell type="paragraph">{data.is_paid ? data.bank_name : '-'}</Table.Cell>
                <Table.Cell type="date">{data.paid ? formatDate(data.paid) : '-'}</Table.Cell>
                <Table.Cell type="enum">
                  {data.is_paid ? (
                    <Icon small color="success">
                      <i className="fa-regular fa-check" />
                    </Icon>
                  ) : (
                    <Icon small color="gray">
                      <i className="fa-regular fa-question" />
                    </Icon>
                  )}
                </Table.Cell>

                <Table.Cell type="object">
                  <div className="items-group">
                    {!data.is_paid && isAgency && (
                      <LockableActionWrapper
                        isPlatformLocked={isPlatformLocked}
                        callback={() => handleOpenDeleteInvoiceModal(data)}
                      >
                        <Button variant="secondary" color="danger" isIconButton>
                          <i className="fa-regular fa-solid fa-trash" />
                        </Button>
                      </LockableActionWrapper>
                    )}
                    {!data.is_paid && isBank && (
                      <Button color="success" isIconButton onClick={() => handleOpenActivateInvoiceModal(data)}>
                        <i className="fa-regular fa-solid fa-check" />
                      </Button>
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
    </ModalProvider>
  );
};

InvoicesTable.defaultProps = {
  tableData: null,
  setCursor: null,
  limit: null,
  isPlatformLocked: false,
};

InvoicesTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  setCursor: PropTypes.func,
  limit: PropTypes.number,
  isPlatformLocked: PropTypes.bool,
};

export default InvoicesTable;
