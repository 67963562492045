import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAPI, useGetUser } from 'hooks';
import AGLOCampaign from './components/AGLOCampaign';
import SuperAdminCampaign from './components/SuperAdminCampaign';

const Campaign = () => {
  const { isSuperAdmin, isAGLO } = useGetUser();
  const { api } = useAPI();

  const { data: preferences } = useQuery({
    queryFn: api.retrieve('preference', 'current/'),
    queryKey: ['preference/current/'],
  });

  const { campaign_year: campaignYear } = preferences || {};

  return (
    preferences && (
      <>
        {isSuperAdmin && <SuperAdminCampaign preferences={preferences} />}

        {isAGLO && <AGLOCampaign campaignYear={campaignYear} />}
      </>
    )
  );
};

export default Campaign;
