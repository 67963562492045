import React, { useState } from 'react';
import { Page, TextField, BlockCard, Button, StatCard } from '@trellixio/roaster-coffee';
import { useQuery } from '@tanstack/react-query';
import { useAPI, useDebounce } from 'hooks';
import { EmptyTable } from 'components';
import { preventSubmitWithENTER, formatNumber } from 'helpers';
import Locales from 'locales';
import AddAgencyModal from './components/AddAgencyModal';
import AgenciesTable from './components/AgenciesTable';

const Agency = () => {
  const [isAddModalOpened, setAddModalOpened] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [cursor, setCursor] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const limit = 30;
  const { api } = useAPI();

  const fetchParams = searchQuery.length > 0 ? { q: debouncedSearchQuery } : { limit, cursor };

  const { data: agencies } = useQuery({
    queryFn: api.list('agency/', fetchParams),
    queryKey: ['agency/', debouncedSearchQuery, limit, cursor],
    keepPreviousData: true,
  });

  const { data: agenciesStatsData } = useQuery({
    queryFn: api.list('agency/stats/default/'),
    queryKey: ['agency/stats/default/'],
  });

  const agenciesStats = agenciesStatsData?.data.data;

  const toggleAddModalOpen = () => setAddModalOpened((val) => !val);

  const resultString = agencies
    ? `${agencies.count} ${
        agencies.count <= 1 ? Locales.agency.agency.slice(0, -1).toLowerCase() : Locales.agency.agency.toLowerCase()
      }`
    : '-';

  return (
    agencies && (
      <Page
        title={Locales.agency.agency}
        size="full"
        primaryAction={
          <Button variant="primary" color="success" onClick={toggleAddModalOpen}>
            <i className="fa-regular fa-solid fa-plus" />
            {Locales.base.button.add}
          </Button>
        }
      >
        {agenciesStats && (
          <div className="stats-container items-group wrap xl">
            {agenciesStats.map((stats) => {
              let result = null;

              if (stats.percent === null) {
                result = (
                  <StatCard
                    key={stats.name}
                    title={stats.name}
                    value={formatNumber(stats.value)}
                    description={stats.description}
                  />
                );
              } else {
                result = (
                  <StatCard
                    key={stats.name}
                    title={stats.name}
                    value={formatNumber(stats.value)}
                    description={stats.description}
                    progressValue={stats.percent}
                    progressLabel={`${stats.percent}%`}
                  />
                );
              }

              return result;
            })}
          </div>
        )}

        <div>
          <form>
            <TextField
              placeholder={Locales.agency.search}
              prefix={<i className="fa-solid fa-magnifying-glass" />}
              onChange={(value) => setSearchQuery(value.trim())}
              onKeyDown={preventSubmitWithENTER}
            />
          </form>
        </div>

        <BlockCard>
          <div>
            <span>{Locales.agency.result}</span>
            <strong>{resultString}</strong>
          </div>
        </BlockCard>

        {agencies.count > 0 && <AgenciesTable tableData={agencies} limit={limit} setCursor={setCursor} />}

        {agencies.count === 0 && <EmptyTable isSearchResult={searchQuery.length > 0} />}

        {isAddModalOpened && <AddAgencyModal opened={isAddModalOpened} closeModal={toggleAddModalOpen} />}
      </Page>
    )
  );
};

export default Agency;
