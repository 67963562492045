import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@trellixio/roaster-coffee';

const FilterSelect = ({ label, helpText, value, data, onChange }) => {
  return (
    <div className="content-row">
      <p className="group-label">{label}</p>
      <Select helpText={helpText} defaultValue={value} data={data} onChange={onChange} />
    </div>
  );
};

FilterSelect.defaultProps = {
  label: null,
  helpText: null,
  value: null,
  data: null,
  onChange: null,
};

FilterSelect.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

export default FilterSelect;
