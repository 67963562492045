import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { BlockCard, Spinner, Button } from '@trellixio/roaster-coffee';
import { formatNumber } from 'helpers';
import Locales from 'locales';
import TripDistributionChartItem from './TripDistributionChartItem';
import style from './AGLODashboard.module.css';

const CONST_COLOR_GREEN = '#12874F';
const CONST_COLOR_BLUE = '#1975BA';
const CONST_COLOR_GREY = '#6D6661';
const CONST_CHART_KEY = 'chart';

const TripDistributionChart = ({ reports, agencyList, lastDownloadTime, handlePrint }) => {
  const tripDistributionChartRef = useRef(null);

  return (
    <BlockCard
      title={Locales.formatString(Locales.dashboard.graph_pilgrims_distribution_by_company, {
        generate:
          lastDownloadTime && Locales.formatString(Locales.dashboard.report.generate, { date: lastDownloadTime }),
      })}
      actions={
        <Button variant="secondary" onClick={() => handlePrint(tripDistributionChartRef, CONST_CHART_KEY)}>
          <i className="fa-regular fa-solid fa-print" />
          {Locales.dashboard.report.button.download}
        </Button>
      }
      ref={tripDistributionChartRef}
    >
      {reports &&
        reports.map((data, key) => {
          const agency = agencyList?.find((agencyObject) => agencyObject.id === data.agency_id);

          return (
            <div className={style['group-block']} key={data.id} id={key}>
              <table className={style.chart}>
                <thead>
                  <tr>
                    <th>
                      {Locales.formatString(Locales.dashboard.report.chart_title, {
                        agencyName: agency?.name,
                      })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <TripDistributionChartItem
                          value={data.full_payment_trips}
                          color={CONST_COLOR_GREEN}
                          className={style['report-chart']}
                        />

                        <TripDistributionChartItem
                          value={data.pre_payed_trips}
                          color={CONST_COLOR_BLUE}
                          className={style['report-chart']}
                        />
                      </div>

                      <TripDistributionChartItem value={data.pre_registered_trips} color={CONST_COLOR_GREY} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={style['chart-legend']}>
                <div className={style['legend-sold']}>
                  {Locales.dashboard.report.table.sold} : {formatNumber(data.full_payment_trips)}
                </div>
                <div className={style['legend-prepaid']}>
                  {Locales.dashboard.report.table.pre_paid} : {formatNumber(data.pre_payed_trips)}
                </div>
                <div className={style['legend-preregistered']}>
                  {Locales.dashboard.report.table.pre_registered} : {formatNumber(data.pre_registered_trips)}
                </div>
              </div>
            </div>
          );
        })}
      {!reports && (
        <div>
          <Spinner />
        </div>
      )}
    </BlockCard>
  );
};

TripDistributionChart.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      agency_id: PropTypes.number,
      full_payment_trips: PropTypes.number,
      pre_payed_trips: PropTypes.number,
      pre_registered_trips: PropTypes.number,
    })
  ),
  agencyList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  lastDownloadTime: PropTypes.string,
  handlePrint: PropTypes.func,
};

TripDistributionChart.defaultProps = {
  reports: null,
  agencyList: null,
  lastDownloadTime: null,
  handlePrint: null,
};

export default TripDistributionChart;
