const useGetPilgrim = (pilgrimData) => {
  const {
    id,
    deposits,
    deposit_total: depositTotal,
    cart_total: cartTotal,
    deposit_transferred: depositTransferred,
    next_steps: nextSteps,
    agency_name: agencyName,
    note,
    pilgrim,
    customization,
    health,
    category_id: categoryId,
    category_name: categoryName,
    is_visa_submission: isVisaSubmission,
  } = pilgrimData;

  const {
    first_name: firstname,
    last_name: lastname,
    npi,
    is_npi_valid: isNpiValid,
    passport,
    passport_expiration: passportExpirationDate,
    is_passport_valid: isPassportValid,
    birthdate,
    is_international: isInternational,
  } = pilgrim;

  const { is_healthy: isHealthy, doctor_name: doctorName, doctor_email: doctorEmail } = health;

  const {
    name: customizationName,
    approved: customizationApproved,
    description: customizationDescription,
    price: customizationPrice,
  } = customization;

  const isInvoicePaid = cartTotal <= depositTransferred;

  return {
    id,
    pilgrim,
    firstname,
    lastname,
    birthdate,
    npi,
    isNpiValid,
    passport,
    passportExpirationDate,
    isPassportValid,
    isInternational,
    note,
    agencyName,
    nextSteps,
    deposits,
    depositTotal,
    cartTotal,
    depositTransferred,
    health,
    isHealthy,
    doctorName,
    doctorEmail,
    customization,
    customizationName,
    customizationApproved,
    customizationDescription,
    customizationPrice,
    categoryId,
    categoryName,
    isInvoicePaid,
    isVisaSubmission,
  };
};

export default useGetPilgrim;
