import moment from 'moment';
import 'moment/locale/fr';
import { Urls } from 'data';
import Storage from './Storage';

moment.locale('fr');

const storage = new Storage(localStorage, 'eHadj-');
const CONST_LEGAL_ACCEPTED = 'legal_accepted';
const CONST_LEGAL_DOWNLOADED = 'legal_downloaded';
const CONST_LEGAL_TOAST_DISPLAYED = 'legal_toast_displayed';
const CONST_LEGAL_ACCEPTANCE_DATE = 'legal_acceptance_date';

/**
 * Test if an email address is in the correct format
 *
 * @param {string} email a email we want to check
 * @returns {boolean} Result of the test
 */
export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

/**
 * Formate a date using moment js
 *
 * @param {string} date a date to formate
 * @param {string} format the expect format for the date
 * @returns {string} a date to the expected format
 */
export const formatDate = (date, format = 'DD/MM/YYYY') => {
  return moment(date).format(format);
};

/**
 * Formats a number according to the French locale.
 *
 * @param {number} number - The number to format.
 * @returns {string} The formatted number as a string with French locale formatting.
 */
export const formatNumber = (number) => {
  return `${number.toLocaleString('fr-FR')}`;
};

/**
 * Determine whether or not we should display pagination icon on a table
 *
 * @param {Array} tableData an array of object
 * @param {number} limit the maximum number of items to display per page
 * @returns {boolean} Result of the test
 */
export const showPagination = (tableData, limit) => (tableData ? tableData.count > limit : false);

/**
 * Determine whether or not there is a next page to display
 *
 * @param {Array} tableData an array of object
 * @returns {boolean} Result of the test
 */
export const hasNextPage = (tableData) => (tableData ? tableData.next !== null : false);

/**
 * Determine whether or not there is a previous page to display
 *
 * @param {Array} tableData an array of object
 * @returns {boolean} Result of the test
 */
export const hasPreviousPage = (tableData) => (tableData ? tableData.previous !== null : false);

/**
 * Retrieve the value of the search parameter cursor from an url
 *
 * @param {string} url an url that was retrieve from a LIST query
 * @returns {string} the cursor parameter
 */
const getQueryCursorFromURL = (url) => {
  const params = new URL(url).searchParams;
  return params.get('cursor');
};

/**
 * Update the value of the cursor set in the LIST query. This allow the user to go from one page to another
 *
 * @param {*} url an url that was retrieve from a LIST query
 * @param {*} setCursor a react method to update a state value
 * @returns {Function} method to update state with data from url
 */
export const updateQueryCursor = (url, setCursor) => setCursor(getQueryCursorFromURL(url));

/**
 * Logout action
 *
 */
export const onLogout = () => {
  const isLegalAccepted = storage.getData(CONST_LEGAL_ACCEPTED) === true;
  const isLegalDownloaded = storage.getData(CONST_LEGAL_DOWNLOADED) === true;
  const isLegalToastDisplayed = storage.getData(CONST_LEGAL_TOAST_DISPLAYED) === true;
  const legalAcceptanceDate = storage.get(CONST_LEGAL_ACCEPTANCE_DATE);
  storage.clear();
  // We keep those data in storage even after logout
  storage.set(CONST_LEGAL_ACCEPTED, isLegalAccepted);
  storage.set(CONST_LEGAL_DOWNLOADED, isLegalDownloaded);
  storage.set(CONST_LEGAL_TOAST_DISPLAYED, isLegalToastDisplayed);
  storage.set(CONST_LEGAL_ACCEPTANCE_DATE, legalAcceptanceDate);
  window.location = Urls.front.login;
};

/**
 * Get a username based on firstname and lastname
 *
 * @param {string} firstname user firstname
 * @param {string} lastname user lastname
 * @returns {string} a concatenation of firstname and lastname separated by a space
 */
export const getUserName = (firstname, lastname) => `${firstname} ${lastname}`;

/**
 *
 * @param {object} e the keydown event of the input
 */
export const preventSubmitWithENTER = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault(); // prevent ENTER from submitting the form, thus reloading the page
  }
};
