import React, { useState } from 'react';
import { Modal, TextField, Select, showToast, Button, BlockCard } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { validateEmail } from 'helpers';
import { useAPI, useGetUser } from 'hooks';
import Locales from 'locales';

const AddUserModal = ({ opened, organizations, closeModal }) => {
  const { user, isSuperAdmin } = useGetUser();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isValidEmailError, setIsValidEmailError] = useState(false);
  const [emptyFirstNameFieldError, setEmptyFirstNameFieldError] = useState(false);
  const [emptyLastNameFieldError, setEmptyLastNameFieldError] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState(user?.organization_id || '');

  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;

  const getUserOrganizations = () => {
    let result = [];

    if (isSuperAdmin) {
      result = [...organizations].reverse();
    } else {
      result = [user.organization_name];
    }

    return result;
  };

  const newUser = {
    email,
    first_name: firstName,
    last_name: lastName,
    organization_id: selectedOrganisation,
  };

  const { mutate: createUser } = useMutation({
    mutationFn: () => api.create('user/', newUser),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user/'] });
      showToast({
        message: Locales.formatString(Locales.user.toast.user_added, { firstName, lastName, email }),
        level: 'success',
      });
      closeModal();
    },
  });

  const handleSave = () => {
    const emptyFirstNameField = firstName.trim() === '';
    const emptyLastNameField = lastName.trim() === '';
    const validEmail = validateEmail(email);

    setEmptyFirstNameFieldError(emptyFirstNameField);
    setEmptyLastNameFieldError(emptyLastNameField);
    setIsValidEmailError(!validEmail);

    if (!emptyFirstNameField && !emptyLastNameField && validEmail) {
      createUser();
    }
  };

  return (
    <Modal
      title={Locales.user.add}
      size="small"
      opened={opened}
      onClose={closeModal}
      footer={
        <>
          <Button variant="secondary" onClick={closeModal}>
            {Locales.base.button.cancel}
          </Button>
          <Button variant="primary" color="success" onClick={handleSave} disabled={isMutating}>
            {Locales.base.button.save}
          </Button>
        </>
      }
    >
      <form>
        <BlockCard>
          <Select
            label={Locales.user.organisation}
            data={getUserOrganizations()}
            helpText={Locales.user.select_organisation}
            onChange={setSelectedOrganisation}
          />

          <p>{Locales.user.enter_user_information}</p>

          <div className="items-group">
            <TextField
              label={Locales.base.field.first_name}
              type="text"
              placeholder={Locales.base.field.placeholder.firstname}
              onChange={setFirstName}
              error={emptyFirstNameFieldError ? Locales.base.field.required.empty : ''}
              required
            />
            <TextField
              label={Locales.base.field.last_name}
              type="text"
              placeholder={Locales.base.field.placeholder.lastname}
              onChange={setLastName}
              error={emptyLastNameFieldError ? Locales.base.field.required.empty : ''}
              required
            />
          </div>

          <TextField
            label={Locales.base.field.email}
            type="email"
            placeholder={Locales.base.field.placeholder.email}
            onChange={setEmail}
            error={isValidEmailError ? Locales.user.errors.email : ''}
            required
          />
        </BlockCard>
      </form>
    </Modal>
  );
};

AddUserModal.defaultProps = {
  opened: false,
  organizations: null,
  closeModal: null,
};

AddUserModal.propTypes = {
  opened: PropTypes.bool,
  organizations: PropTypes.arrayOf(PropTypes.shape({})),
  closeModal: PropTypes.func,
};

export default AddUserModal;
