/**
 * Hides a specified column in a table by setting its display style to 'none'.
 *
 * @param {HTMLTableElement} table - The table element from which to remove the column.
 * @param {number} [columnIndex=0] - The index of the column to remove. Defaults to 0 if not provided.
 */
const hideColumn = (table, columnIndex) => {
  const columnId = columnIndex || 0;
  for (let i = 0; i < table.rows.length; i += 1) {
    if (table.rows[i].cells[columnId]) {
      const cell = table.rows[i].cells[columnId];
      cell.style.display = 'none';
    }
  }
};

/**
 * Hides rows from the table that are unchecked.
 *
 * @param {NodeList} checkboxes - A list of checkbox elements.
 */
const hideUncheckedRows = (checkboxes) => {
  // Check if at least one checkbox is checked
  const hasCheckedBoxes = Array.from(checkboxes).some((checkbox) => checkbox.checked);

  // Only hide unchecked rows if at least one checkbox is checked
  if (hasCheckedBoxes) {
    checkboxes.forEach((checkbox) => {
      const row = checkbox.closest('tr');
      if (!checkbox.checked) {
        row.style.display = 'none';
      }
    });
  }
};

/**
 * Hides the pagination buttons in the table.
 * This function finds the element with the class 'table-pagination' and sets its display style to 'none'.
 * It's useful when you want to print the table without the pagination controls or when using a different pagination method.
 *
 * @param {HTMLElement} table - The HTML table element in which to hide the pagination buttons.
 */
const hidePaginationButtons = (table) => {
  const paginationContainer = table.querySelector('.table-pagination');
  if (paginationContainer) {
    paginationContainer.style.display = 'none';
  }
};

/**
 * Hides tooltips in the specified table.
 * This function searches for elements with the classes 'fa-regular' and 'fa-circle-info'
 * within the provided table and sets their display style to 'none', effectively hiding them.
 * This is useful for scenarios where you want to print the table without these tooltip icons.
 *
 * @param {HTMLElement} table - The HTML table element in which to hide tooltips.
 */
const hideTooltips = (table) => {
  const icons = table?.querySelectorAll('.fa-regular.fa-circle-info');
  icons?.forEach((iconElement) => {
    const icon = iconElement; // create a new variable to store the DOM element
    icon.style.display = 'none'; // modify the style property of the new variable
  });
};

/**
 * Retrieves the indexes of specified columns for a given user role.
 *
 * @param {string} userType - The type of user (e.g., 'AGENCY', 'ADMIN').
 * @param {string[]} columnNames - An array of column keys to retrieve indexes for (e.g., ['SELECTOR', 'ACTIONS']).
 * @param {object} userTable - An object mapping user types to their corresponding column layouts.
 * @param {object} columns - An object containing column keys and their respective labels.
 * @returns {number[]} - An array of column indexes corresponding to the specified column keys for the user type.
 */
export const getColumnsIndexes = (userType, columnNames, userTable, columns) => {
  return columnNames.map((columnName) => userTable[userType].indexOf(columns[columnName]));
};

/**
 * Updates the original header of the table by cloning it if it hasn't been set yet
 *
 * This function is used to store the original header of the table before any modifications are made to it.
 * This allows the original header to be restored when desired.
 *
 * @param {object} tableRef - React reference to the table element.
 * @param {function(HTMLElement): void} setOriginalHeader - A function to set the original header.
 * @param {HTMLElement} originalHeader - Original header of the table.
 */
export const updateOriginalHeader = (tableRef, setOriginalHeader, originalHeader) => {
  if (tableRef.current && !originalHeader) {
    const header = tableRef.current.querySelector('thead');
    if (header) {
      setOriginalHeader(header.cloneNode(true));
    }
  }
};

/**
 * Prints a table with customization options.
 *
 * This function clones the table content, removes unnecessary elements,
 * adjusts columns and rows according to parameters, and opens a new window
 * for printing.
 *
 * @param {object} tableRef - React reference to the table element.
 * @param {HTMLElement} originalHeader - Original header of the table.
 * @param {number[]} columnsToRemove - Indices of columns to remove before printing.
 */
export const printTable = (tableRef, originalHeader, columnsToRemove = []) => {
  // Clone the content to be printed
  const printTableContent = tableRef.current?.cloneNode(true);

  // Remove pagination buttons
  hidePaginationButtons(printTableContent);

  // Remove icons located in the header
  hideTooltips(printTableContent);

  // Remove action column => class 'table-actions'
  const tableActions = printTableContent.querySelector('.table-actions');
  if (tableActions) {
    tableActions.remove();
  }

  const table = printTableContent.querySelector('table');

  // Add a specific class for printing
  table.classList.add('print-table');

  // Create a copy of the original header and remove icons from it
  const newHeader = originalHeader?.cloneNode(true);
  hideTooltips(newHeader);

  // Remove specified columns from the table and the new header
  columnsToRemove
    .sort((a, b) => b - a)
    .forEach((columnIndex) => {
      hideColumn(table, columnIndex);
      const headerCell = newHeader?.querySelector(`th:nth-child(${columnIndex + 1})`);
      if (headerCell) {
        headerCell.remove();
      }
    });

  // Remove unchecked rows
  const checkboxes = printTableContent.querySelectorAll('.datatable tbody .selector input[type="checkbox"]');
  hideUncheckedRows(checkboxes);

  // Check if there's already a header before adding the original header
  const existingHeader = table.querySelector('thead');
  if (!existingHeader) {
    // Restore the original header only if there's no existing header
    if (newHeader) {
      table.insertBefore(newHeader, table.firstChild);
    }
  }

  // Open a new window for printing
  const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

  // Copy styles from the current page
  const styles = Array.from(document.styleSheets)
    .map((styleSheet) => {
      try {
        return Array.from(styleSheet.cssRules)
          .map((rule) => rule.cssText)
          .join('');
      } catch (e) {
        return '';
      }
    })
    .join('\n');

  // Add styles to ensure the table is printed fully and correctly
  const tableStyles = `
    @media print {
      @page {
        size: landscape;
      }
      html {
        font-family: 'DM Sans', sans-serif;
      }
      body {
        margin: 0;
        padding: 0;
        -webkit-print-color-adjust: exact; /* For Chrome and Edge */
        print-color-adjust: exact; /* For other browsers */
      }
      table.print-table {
        width: 100%;
        font-size: 7pt;
      }
      table.print-table td,
      table.print-table th {
        padding: 4px 8px;
      }
      table.print-table th,
      table.print-table td {
        white-space: normal;
        min-width: 25px;
        width: auto;
      }
      table.print-table td.numeric.enum span {
        margin-left: 10px;
      }
      table.print-table td.enum {
        max-width: 200px;
      }
      table.print-table em.tag {
        font-size: 7pt;
      }
      table.print-table.datatable td.text {
        white-space: normal;
      }
      table.print-table.datatable td:nth-of-type(12),
      table.print-table.datatable td:nth-of-type(13) {
        white-space: normal;
        word-break: break-word;
        hyphens: auto;
      }
    }
  `;

  // Include the DM Sans font via Google Fonts
  const fontLink = `<link href="https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400&display=swap" rel="stylesheet" />`;

  // Write styles and content to the new window
  WinPrint.document.write(fontLink); // Include the font link
  // Set lang="fr" to ensure proper word hyphenation for French text with the applied styles.
  WinPrint.document.write(`
      <html lang="fr">
        <style>${styles}${tableStyles}</style>
        ${printTableContent.innerHTML}
      </html>
    `);
  WinPrint.document.close();

  // Focus on the new window and initiate printing
  WinPrint.focus();
  WinPrint.print();
};
