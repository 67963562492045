import React from 'react';
import appLogo from 'assets/img/sidebar/ehadj-logo.svg';
import horizontalDots from 'assets/img/sidebar/pattern-dots-horizontal.svg';
import verticalDots from 'assets/img/sidebar/pattern-dots-vertical.svg';
import Locales from 'locales';

const AuthSidebar = () => {
  return (
    <aside className="auth-decoration">
      <div className="shapes top">
        <img src={horizontalDots} alt="dots pattern" />
      </div>

      <div className="app-logo">
        <img src={appLogo} title="eHadj logo" alt="eHadj-logo" />
        <p>{Locales.auth.description}</p>
      </div>

      <div className="breadcrumbs">
        <a
          href="https://diplomatie.gouv.bj/actualite/268/lancement-hadj-edition-2023/"
          target="_blank"
          rel="noreferrer"
        >
          {Locales.auth.get_info}
        </a>
      </div>

      <div>
        <img className="shapes bottom" src={verticalDots} alt="dots pattern" />
      </div>
    </aside>
  );
};

export default AuthSidebar;
