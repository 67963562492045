import React from 'react';
import PropTypes from 'prop-types';
import { Page, Spinner } from '@trellixio/roaster-coffee';
import { useQuery } from '@tanstack/react-query';
import Locales from 'locales';
import { useAPI } from 'hooks';
import { StatCardsList } from 'components';
import styles from './SuperAdminDashboard.module.css';

const StatsSection = ({ title, data }) => (
  <section>
    <h3 className="content-row">{title}</h3>
    {data ? (
      <div className="items-group wrap xl">
        <StatCardsList statsData={data} />
      </div>
    ) : (
      <Spinner />
    )}
  </section>
);

StatsSection.defaultProps = {
  title: null,
  data: null,
};

StatsSection.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

const SuperAdminDashboard = () => {
  const { api } = useAPI();

  const { data: depositsData } = useQuery({
    queryFn: api.list('deposit/stats/default/'),
    queryKey: ['deposit/stats/default/'],
  });

  const { data: agenciesData } = useQuery({
    queryFn: api.list('agency/stats/default/'),
    queryKey: ['agency/stats/default/'],
  });

  const { data: pilgrimsDemographicData } = useQuery({
    queryFn: api.list('trip/stats/demographic/'),
    queryKey: ['trip/stats/demographic/'],
  });

  const { data: pilgrimsPaymentData } = useQuery({
    queryFn: api.list('trip/stats/payment/'),
    queryKey: ['trip/stats/payment/'],
  });

  const { data: pilgrimsHealthData } = useQuery({
    queryFn: api.list('trip/stats/health/'),
    queryKey: ['trip/stats/health/'],
  });

  const { data: pilgrimsVisaData } = useQuery({
    queryFn: api.list('trip/stats/visa/'),
    queryKey: ['trip/stats/visa/'],
  });

  const { data: pilgrimsMiscData } = useQuery({
    queryFn: api.list('trip/stats/misc/'),
    queryKey: ['trip/stats/misc/'],
  });

  const { data: pilgrimsCategoryData } = useQuery({
    queryFn: api.list('trip/stats/category/'),
    queryKey: ['trip/stats/category/'],
  });

  const { data: pilgrimsCategoryPaidData } = useQuery({
    queryFn: api.list('trip/stats/trip_category_paid/'),
    queryKey: ['trip/stats/trip_category_paid/'],
  });

  // we skip first element "Total" because it's not within the stats to display
  const depositsStats = depositsData?.data.data.filter((stats, index) => index > 0);

  const agenciesStats = agenciesData?.data.data;

  const pilgrimsDemographicStats = pilgrimsDemographicData?.data.data;

  const pilgrimsPaymentStats = pilgrimsPaymentData?.data.data;

  const pilgrimsHealthStats = pilgrimsHealthData?.data.data;

  const pilgrimsVisaStats = pilgrimsVisaData?.data.data;

  const pilgrimsMiscStats = pilgrimsMiscData?.data.data;

  const pilgrimsCategory = pilgrimsCategoryData?.data.data;

  const pilgrimsCategoryPaid = pilgrimsCategoryPaidData?.data.data;

  return (
    <Page title={Locales.dashboard.title} size="full" className={styles.container}>
      <StatsSection
        title={Locales.base.stats.pilgrims}
        data={[...(pilgrimsDemographicStats || []), ...(pilgrimsCategory || []), ...(pilgrimsCategoryPaid || [])]}
      />

      <section>
        <h3 className="content-row">{Locales.base.stats.bank}</h3>
        {depositsStats && pilgrimsPaymentStats ? (
          <div className="items-group wrap xl">
            <StatCardsList statsData={depositsStats} />
            <StatCardsList statsData={pilgrimsPaymentStats} />
          </div>
        ) : (
          <Spinner />
        )}
      </section>

      <StatsSection title={Locales.base.stats.health} data={pilgrimsHealthStats} />

      <StatsSection title={Locales.base.stats.visa} data={pilgrimsVisaStats} />

      <StatsSection title={Locales.base.stats.no_visa_application} data={pilgrimsMiscStats} />

      <StatsSection title={Locales.base.stats.agencies} data={agenciesStats} />
    </Page>
  );
};

export default SuperAdminDashboard;
