import React from 'react';
import PropTypes from 'prop-types';
import Locales from 'locales';
import styles from './EmptyTable.module.css';

const EmptyTable = ({ isSearchResult }) => {
  return (
    <article className={styles.container}>
      <p>{isSearchResult ? Locales.base.empty_search_result : Locales.base.empty_table}</p>
    </article>
  );
};

EmptyTable.defaultProps = {
  isSearchResult: false,
};

EmptyTable.propTypes = {
  isSearchResult: PropTypes.bool,
};

export default EmptyTable;
