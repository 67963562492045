import React from 'react';
import Locales from 'locales';
import errorIcon from 'assets/img/error.png';
import { Page } from '@trellixio/roaster-coffee';
import styles from './Error404.module.css';

const Error404 = () => {
  return (
    <Page className="vcenter">
      <div className={styles.container}>
        <div>
          <img src={errorIcon} alt="404 error" />
        </div>
        <div className={styles.text}>
          <h2 className="content-row">{Locales.base.error_404.title}</h2>
          <h3 className="content-row">{Locales.base.error_404.subtitle}</h3>
        </div>
      </div>
    </Page>
  );
};

export default Error404;
