import React, { useContext } from 'react';
import { BlockCard, Button, openModal, showToast } from '@trellixio/roaster-coffee';
import { useMutation, useQueryClient, useIsMutating } from '@tanstack/react-query';
import { LockableActionWrapper } from 'components';
import { PermissionsContext } from 'context';
import { useGetAgency, useAPI } from 'hooks';
import Locales from 'locales';
import PropTypes from 'prop-types';
import AuthorizationAlert from './AuthorizationAlert';
import style from '../Authorization.module.css';

const LicenseBlock = ({ userAgency, agencyIsAuthorize, minPilgrimForAuthorization }) => {
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const { isPlatformLocked } = useContext(PermissionsContext);
  const { mergerRequested, licenceRequested, licenceApproved, mergerApproved, organizationName, agencyId } =
    useGetAgency(userAgency);
  const isMutating = useIsMutating() > 0;

  const { mutate: licenseRequest } = useMutation({
    mutationFn: () => api.post(`agency/current/license_request/`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agency/'] });
      queryClient.invalidateQueries({ queryKey: ['agency/stats/default/'] });
      showToast({
        message: Locales.formatString(Locales.authorization.toast.licence_request, {
          name: organizationName,
        }),
        level: 'success',
      });
    },
  });

  const { mutate: cancelLicenceRequest } = useMutation({
    mutationFn: () => api.post(`agency/${agencyId}/license_revoke/`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agency/'] });
      queryClient.invalidateQueries({ queryKey: ['agency/stats/default/'] });
      showToast({
        message: Locales.formatString(Locales.authorization.toast.revoke_licence_request, {
          name: organizationName,
        }),
        level: 'success',
      });
    },
  });

  const handleOpenLicenseRequestModal = () => {
    openModal({
      title: Locales.authorization.request_authorization,
      children: (
        <div>
          <p>
            {Locales.formatString(Locales.authorization.request_authorization_message, {
              name: <strong>{organizationName}</strong>,
            })}
          </p>
          <div className="content-row" />
          <p>{Locales.formatString(Locales.authorization.after_giving_autorization)}</p>
        </div>
      ),
      confirmProps: {
        children: Locales.authorization.request_the_authorization,
        variant: 'primary',
        color: 'success',
        disabled: isMutating,
      },
      cancelProps: {
        children: Locales.base.button.discard,
        variant: 'secondary',
        color: '',
      },
      onCancel: () => {},
      onConfirm: () => licenseRequest(),
    });
  };

  const handleOpenCancelLicenseRequestModal = () => {
    openModal({
      title: Locales.authorization.cancel_request_authorization,
      children: (
        <p>
          {Locales.formatString(Locales.authorization.cancel_request_authorization_message, {
            name: <strong>{organizationName}</strong>,
          })}
        </p>
      ),
      confirmProps: {
        children: Locales.authorization.cancel_request_authorization,
        variant: 'primary',
        color: 'danger',
        disabled: isMutating,
      },
      cancelProps: {
        children: Locales.base.button.discard,
        variant: 'secondary',
        color: '',
      },
      onCancel: () => {},
      onConfirm: () => cancelLicenceRequest(),
    });
  };

  return (
    <BlockCard title={Locales.authorization.authorization_status}>
      {!mergerRequested && (
        <>
          <p>{Locales.authorization.conditions_admissibilite}</p>

          <ul className={style.conditions}>
            <li>{Locales.authorization.conditions.badge_identification}</li>
            <li>
              {Locales.formatString(Locales.authorization.conditions.enregistrement_pelerins, {
                minActivePilgrim: minPilgrimForAuthorization,
              })}
            </li>
            <li>
              {Locales.formatString(Locales.authorization.conditions.validate_documents, {
                minActivePilgrim: minPilgrimForAuthorization,
              })}
            </li>
          </ul>

          <AuthorizationAlert
            condition={agencyIsAuthorize && !licenceRequested && !licenceApproved}
            level="success"
            message={Locales.authorization.alert.success_can_request_authorization}
          />

          <AuthorizationAlert
            condition={!agencyIsAuthorize && !licenceRequested}
            level="warning"
            message={Locales.authorization.alert.warning_message}
          />
        </>
      )}

      {licenceRequested && !licenceApproved && (
        <>
          <AuthorizationAlert
            condition={!mergerRequested}
            level="warning"
            message={Locales.authorization.alert.authorization_request_pending}
          />
          <LockableActionWrapper
            isPlatformLocked={isPlatformLocked}
            callback={() => handleOpenCancelLicenseRequestModal()}
            className={!agencyIsAuthorize ? style.disabled : ''}
          >
            {!mergerRequested && (
              <Button variant="primary" color="danger" disabled={!agencyIsAuthorize || isMutating}>
                {Locales.authorization.cancel_authorization_request}
              </Button>
            )}
          </LockableActionWrapper>
        </>
      )}

      <AuthorizationAlert
        condition={!mergerRequested && licenceApproved}
        level="success"
        message={Locales.authorization.alert.success_message}
      />

      {!mergerRequested && !licenceRequested && (
        <LockableActionWrapper
          isPlatformLocked={isPlatformLocked}
          callback={() => handleOpenLicenseRequestModal()}
          className={!agencyIsAuthorize ? style.disabled : ''}
        >
          {!mergerRequested && (
            <Button variant="primary" color="success" disabled={!agencyIsAuthorize || isMutating}>
              {Locales.authorization.authorization_request}
            </Button>
          )}
        </LockableActionWrapper>
      )}

      <AuthorizationAlert
        condition={mergerRequested}
        level="info"
        message={
          !mergerApproved ? Locales.authorization.alert.merger_pending : Locales.authorization.alert.merger_approved
        }
      />
    </BlockCard>
  );
};

LicenseBlock.defaultProps = {
  userAgency: null,
  agencyIsAuthorize: false,
  minPilgrimForAuthorization: null,
};

LicenseBlock.propTypes = {
  userAgency: PropTypes.shape({}),
  agencyIsAuthorize: PropTypes.bool,
  minPilgrimForAuthorization: PropTypes.number,
};

export default LicenseBlock;
