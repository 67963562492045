import React, { useState, useContext } from 'react';
import { Page, Button, ModalProvider, BlockCard, FileField, showToast } from '@trellixio/roaster-coffee';
import { useQuery, useMutation, useQueryClient, useIsMutating } from '@tanstack/react-query';
import { PermissionsContext } from 'context';
import { useAPI, useGetUser, useGetAgency } from 'hooks';
import { LockedPlatformAlert, LockableActionWrapper } from 'components';
import Locales from 'locales';
import LicenseBlock from './components/LicenseBlock';
import MergerBlock from './components/MergerBlock';
import AuthorizationAlert from './components/AuthorizationAlert';

const CONST_PASSPORT_GRANTED = 'passport_granted';
const CONST_NPI_GRANTED = 'npi_granted';
const CONST_MERGED = 'merged';
const CONST_REQUESTING = 'requesting';

const Authorization = () => {
  const { isPlatformLocked } = useContext(PermissionsContext);

  const { user } = useGetUser();
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = useIsMutating() > 0;
  const [badge, setBadge] = useState(null);

  const limit = 100;

  const { data: preferences } = useQuery({
    queryFn: api.retrieve('preference', 'current/'),
    queryKey: ['preference/current/'],
  });

  const { data: agencyList } = useQuery({
    queryFn: api.list('agency/', { limit }),
    queryKey: ['agency/'],
  });

  const { data: pilgrimsWithNPI } = useQuery({
    queryFn: api.list('trip/', { limit, npi: CONST_NPI_GRANTED }),
    queryKey: ['trip/', { npi: CONST_NPI_GRANTED }],
  });

  const { data: pilgrimsWithPassport } = useQuery({
    queryFn: api.list('trip/', { limit, passport: CONST_PASSPORT_GRANTED }),
    queryKey: ['trip/', { passport: CONST_PASSPORT_GRANTED }],
  });

  // Minimum number of validated (having Passport or NPI validated) pilgrims required for an authorization request by a SA
  const minPilgrimForAuthorization = preferences?.certificate_min_active_pilgrim;

  const combinedPilgrimsArray = [...(pilgrimsWithNPI || []), ...(pilgrimsWithPassport || [])];

  const pilgrimsArray = Array.from(new Map(combinedPilgrimsArray.map((pilgrim) => [pilgrim.id, pilgrim])).values());

  // Current number of validated pilgrims of the SA
  const pilgrimsCount = pilgrimsArray.length;

  const userOrganizationId = user.organization_id;

  const userAgency = agencyList?.find((agency) => agency.id === userOrganizationId);

  const { mergerRequested, mergerApproved, agencyBadge, agencyName } = useGetAgency(userAgency);

  // Determine if the agency is authorized based on the number of validated pilgrims and the presence of an agency badge
  const agencyIsAuthorize = pilgrimsCount >= minPilgrimForAuthorization && agencyBadge !== null;

  const sortedAgencyList = agencyList
    ?.filter((agency) => agency.id !== userOrganizationId && agency.merger.approved === null)
    .sort((a, b) => {
      const isUpperCaseA = a.name[0] === a.name[0].toUpperCase();
      const isUpperCaseB = b.name[0] === b.name[0].toUpperCase();

      if (isUpperCaseA && !isUpperCaseB) return -1;
      if (!isUpperCaseA && isUpperCaseB) return 1;
      return a.name.localeCompare(b.name);
    });

  const approvedMerges = agencyList
    ? agencyList
        .filter(
          (agency) => agency.merger && agency.merger.master_name === agencyName && agency.merger.approved !== null
        )
        .map((agency) => ({
          id: agency.id,
          name: agency.name,
          tripCount: agency.merger.trip_count,
          created: agency.created,
          type: CONST_MERGED,
        }))
    : [];

  const requestedMerges = agencyList
    ? agencyList
        .filter(
          (agency) =>
            agency.merger &&
            agency.merger.master_name === agencyName &&
            agency.merger.requested !== null &&
            agency.merger.approved === null
        )
        .map((agency) => ({
          id: agency.id,
          name: agency.name,
          tripCount: agency.merger.trip_count,
          created: agency.created,
          type: CONST_REQUESTING,
        }))
    : [];

  const combinedAgenciesArray = [...approvedMerges, ...requestedMerges].sort(
    (a, b) => new Date(a.created) - new Date(b.created)
  );

  const { mutate: addBadge } = useMutation({
    mutationFn: () => {
      const headers = { 'Content-Type': 'multipart/form-data' };
      return api.post('agency/file/', { file: badge, object_name: badge.name }, { headers });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agency/'] });
      showToast({
        message: Locales.authorization.toast.add_badge,
        level: 'success',
      });
    },
  });

  const handleAddBadge = () => {
    if (!badge) {
      showToast({
        message: Locales.authorization.toast.error,
        level: 'error',
      });
      return;
    }
    addBadge();
  };

  return (
    agencyList && (
      <ModalProvider>
        <Page title={Locales.authorization.title} loading={isMutating}>
          {isPlatformLocked && <LockedPlatformAlert />}

          <form>
            <LicenseBlock
              userAgency={{
                ...userAgency,
                organizationName: user?.organization_name,
              }}
              agencyIsAuthorize={agencyIsAuthorize}
              minPilgrimForAuthorization={minPilgrimForAuthorization}
            />

            <BlockCard title={Locales.authorization.badge}>
              {!mergerRequested && (
                <>
                  <FileField
                    label={Locales.authorization.badge_selection}
                    size="small"
                    type="image"
                    defaultValue={agencyBadge}
                    accept="*"
                    onChange={setBadge}
                  />

                  <LockableActionWrapper isPlatformLocked={isPlatformLocked} callback={() => handleAddBadge()}>
                    <Button variant="primary" color="success" disabled={isMutating}>
                      {Locales.authorization.save_badge}
                    </Button>
                  </LockableActionWrapper>
                </>
              )}

              <AuthorizationAlert
                condition={mergerRequested}
                level="info"
                message={
                  !mergerApproved
                    ? Locales.authorization.alert.merger_pending
                    : Locales.authorization.alert.merger_approved
                }
              />
            </BlockCard>

            <MergerBlock
              userAgency={userAgency}
              sortedAgencyList={sortedAgencyList}
              combinedAgenciesArray={combinedAgenciesArray}
            />
          </form>
        </Page>
      </ModalProvider>
    )
  );
};

export default Authorization;
