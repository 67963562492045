import React, { useState } from 'react';
import { TextField, Button, Alert, Tag, showToast, Page, BlockCard } from '@trellixio/roaster-coffee';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Urls } from 'data';
import { useAPI } from 'hooks';
import Locales from 'locales';

const ResetPassword = () => {
  const [pwd, setPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [displayPwd, setDisplayPwd] = useState(false);
  const [displayConfirmPwd, setDisplayConfirmPwd] = useState(false);
  const [pwdRuleSize, setPwdRuleSize] = useState(false);
  const [pwdRuleUppercase, setPwdRuleUppercase] = useState(false);
  const [pwdRuleLowercase, setPwdRuleLowercase] = useState(false);
  const [pwdRuleNumber, setPwdRuleNumber] = useState(false);
  const [pwdRuleSpecial, setPwdRuleSpecial] = useState(false);
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;
  const navigate = useNavigate();

  const { api } = useAPI();

  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const { mutate: resetPassword } = useMutation({
    mutationFn: () => api.post('auth/user_token/reset_password/', { token, password: pwd, password2: confirmPwd }),
    onSuccess: () => {
      showToast({
        message: Locales.auth.password_reset_with_success,
        level: 'success',
      });
      navigate(Urls.front.login);
    },
  });

  const isValidPwd = pwdRuleSize && pwdRuleUppercase && pwdRuleLowercase && pwdRuleNumber && pwdRuleSpecial;

  const toggleDisplayPassword = () => setDisplayPwd((val) => !val);
  const toggleDisplayConfirmPassword = () => setDisplayConfirmPwd((val) => !val);

  const validatePassword = (password) => {
    setPwdRuleSize(password.length > 8);
    setPwdRuleUppercase(/[A-Z]/.test(password));
    setPwdRuleLowercase(/[a-z]/.test(password));
    setPwdRuleNumber(/\d/.test(password));
    setPwdRuleSpecial(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password));
  };

  const handlePasswordChange = (value) => {
    const newPassword = value;
    setPwd(newPassword);
    validatePassword(newPassword);
  };

  const enableSubmitButton = isValidPwd && pwd === confirmPwd;

  return (
    <Page size="medium" className="vcenter">
      <div className="auth-form-container">
        <form>
          <BlockCard title={<h2>{Locales.auth.register}</h2>}>
            <p>{Locales.auth.reset_password_message}</p>

            <Alert
              level="info"
              message={
                <>
                  <p>{Locales.auth.info_message}</p>
                  <ul>
                    <li>{Locales.auth.password_rules.size}</li>
                    <li>{Locales.auth.password_rules.uppercase}</li>
                    <li>{Locales.auth.password_rules.lowercase}</li>
                    <li>{Locales.auth.password_rules.number}</li>
                    <li>
                      {Locales.auth.password_rules.special} <strong>!@#$%^&*()-=+</strong>
                    </li>
                    <li>{Locales.auth.password_rules.no_personnal_information}</li>
                  </ul>
                </>
              }
            />

            <TextField label={Locales.auth.email} type="email" defaultValue={email} disabled />

            <TextField
              label={Locales.auth.password}
              type={displayPwd ? 'text' : 'password'}
              rightSection={
                <Button variant="secondary" onClick={toggleDisplayPassword}>
                  {displayPwd ? <i className="fa-regular fa-eye-slash" /> : <i className="fa-regular fa-eye" />}
                </Button>
              }
              required
              onChange={handlePasswordChange}
              placeholder="••••••••••••••••••"
            />

            <TextField
              label={Locales.auth.confirm_password}
              type={displayConfirmPwd ? 'text' : 'password'}
              rightSection={
                <Button variant="secondary" onClick={toggleDisplayConfirmPassword}>
                  {displayConfirmPwd ? <i className="fa-regular fa-eye-slash" /> : <i className="fa-regular fa-eye" />}
                </Button>
              }
              required
              onChange={setConfirmPwd}
              placeholder="••••••••••••••••••"
            />

            <Tag.Group>
              <Tag color={`${pwdRuleSize ? 'success' : 'grey'}`}>{Locales.auth.password_rule_size}</Tag>
              <Tag color={`${pwdRuleUppercase ? 'success' : 'grey'}`}>{Locales.auth.password_rule_uppercase}</Tag>
              <Tag color={`${pwdRuleLowercase ? 'success' : 'grey'}`}>{Locales.auth.password_rule_lowercase}</Tag>
              <Tag color={`${pwdRuleNumber ? 'success' : 'grey'}`}>{Locales.auth.password_rule_number}</Tag>
              <Tag color={`${pwdRuleSpecial ? 'success' : 'grey'}`}>{Locales.auth.password_rule_special}</Tag>
            </Tag.Group>

            <Button
              variant="primary"
              color="success"
              className="card-footer"
              disabled={!enableSubmitButton || isMutating}
              onClick={resetPassword}
            >
              {Locales.auth.create_account}
            </Button>
          </BlockCard>
        </form>
      </div>
    </Page>
  );
};

export default ResetPassword;
