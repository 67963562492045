import React, { useState, useCallback, useEffect } from 'react';
import { Modal, TextField, showToast, DatePicker, BlockCard, Button, Alert, Select } from '@trellixio/roaster-coffee';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { getUserName, formatDate } from 'helpers';
import { useAPI, useGetPilgrim, useGetUser } from 'hooks';
import Locales from 'locales';
import styles from './Trips.module.css';

const EditTripModal = ({ isOpened, pilgrimData, categories, openCategoriesModal, closeModal }) => {
  const { isAgency } = useGetUser();

  const {
    id,
    firstname,
    lastname,
    npi,
    passport,
    passportExpirationDate,
    birthdate,
    isInternational,
    note,
    categoryId,
    isInvoicePaid,
    isVisaSubmission,
  } = useGetPilgrim(pilgrimData);

  const [pilgrimNPI, setNPI] = useState(npi || '');
  const [pilgrimPassport, setPassport] = useState(passport || '');
  const [pilgrimFirstname, setFirstname] = useState(firstname || '');
  const [pilgrimLastname, setLastname] = useState(lastname || '');
  const [pilgrimNote, setPilgrimNote] = useState(note || '');
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [pilgrimBirthdate, setBirthdate] = useState(new Date(birthdate));
  const [pilgrimPassportExpirationDate, setPassportExpirationDate] = useState(
    passportExpirationDate ? new Date(passportExpirationDate) : null
  );
  const [pilgrimIsInternational, setIsInternational] = useState(isInternational);
  const [pilgrimCategory, setPilgrimCategory] = useState(categoryId);
  const [npiError, setNpiError] = useState(false);
  const [passportError, setPassportError] = useState(false);
  const [emptyFieldError, setEmptyFieldError] = useState(false);
  const [birthDateError, setBirthDateError] = useState(null);
  const [passportExpirationDateError, setPassportExpirationDateError] = useState(null);

  // The passportMinExpirationDate is set to 6 months from today.
  // It is to ensure that the passport expiration date has at least 6 months of validity.
  const passportMinExpirationDate = new Date(new Date().setMonth(new Date().getMonth() + 6));

  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;

  const selectedCategory = categories.find((category) => category.id === pilgrimCategory);

  const updatedPilgrim = {
    pilgrim: {
      first_name: pilgrimFirstname,
      last_name: pilgrimLastname.toUpperCase(),
      npi: pilgrimNPI,
      passport: pilgrimPassport.toUpperCase(),
      birthdate: formatDate(pilgrimBirthdate, 'YYYY-MM-DD'),
      passport_expiration: pilgrimPassportExpirationDate
        ? formatDate(pilgrimPassportExpirationDate, 'YYYY-MM-DD')
        : null,
      is_international: pilgrimIsInternational,
    },
    note: pilgrimNote,
    category_id: pilgrimCategory,
  };

  const { mutate: editPilgrim } = useMutation({
    mutationFn: () => api.update('trip', updatedPilgrim, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/default/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/category/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/trip_category_paid/'] });
      queryClient.invalidateQueries({ queryKey: ['deposit/'] });
      showToast({
        message: Locales.formatString(Locales.trip.toast.pilgrim_updated, {
          fullname: getUserName(pilgrimFirstname, pilgrimLastname),
        }),
        level: 'success',
      });
      closeModal();
    },
  });

  const validateForm = (event) => {
    event.preventDefault();
    const npiCorrectFormat = pilgrimNPI === '' || /^[0-9]{10}$/.test(pilgrimNPI);
    const passportCorrectFormat = pilgrimPassport === '' || /^[A-Z0-9]{8,9}$/.test(pilgrimPassport);

    const npiErrorLocal = pilgrimNPI !== '' && !npiCorrectFormat;
    const passportErrorLocal = pilgrimPassport !== '' && !passportCorrectFormat;
    const emptyPassportOrNpiFieldErrorLocal = pilgrimPassport === '' && pilgrimNPI === '';
    const emptyFieldErrorLocal =
      pilgrimFirstname.length === 0 ||
      pilgrimLastname.length === 0 ||
      pilgrimBirthdate === null ||
      emptyPassportOrNpiFieldErrorLocal;

    setNpiError(npiErrorLocal);
    setPassportError(passportErrorLocal);
    setEmptyFieldError(emptyFieldErrorLocal);

    if (
      !npiErrorLocal &&
      !emptyFieldErrorLocal &&
      !passportErrorLocal &&
      birthDateError === null &&
      passportExpirationDateError === null
    ) {
      editPilgrim();
    }
  };

  const handleBirthDateInputError = useCallback((errorType) => {
    if (errorType === 'OUT_OF_RANGE') {
      setBirthDateError(Locales.trip.errors.out_of_range_birthdate);
      return Locales.trip.errors.out_of_range_birthdate;
    }
    setBirthDateError(Locales.trip.errors.date);
    return Locales.trip.errors.date;
  }, []);

  const handleExpirationDateInputError = useCallback((errorType) => {
    if (errorType === 'OUT_OF_RANGE') {
      setPassportExpirationDateError(Locales.trip.errors.out_of_range_expiration_date);
      return Locales.trip.errors.out_of_range_expiration_date;
    }
    setPassportExpirationDateError(Locales.trip.errors.date);
    return Locales.trip.errors.date;
  }, []);

  const handleMonthChange = useCallback(
    (targetMonth, targetYear) => setDate({ month: targetMonth, year: targetYear }),
    []
  );

  const handleKeyDown = (e, callback) => {
    if (e.keyCode === 13) {
      callback();
    }
  };

  useEffect(() => {
    setIsInternational(selectedCategory?.name.includes('Diaspora'));
  }, [pilgrimCategory]);

  return (
    <Modal
      title={Locales.trip.edit_pilgrim}
      size="small"
      opened={isOpened}
      onClose={closeModal}
      footer={
        <>
          <Button variant="secondary" onClick={closeModal}>
            {Locales.base.button.discard}
          </Button>
          <Button variant="primary" color="success" onClick={validateForm} disabled={isMutating}>
            {Locales.base.button.save}
          </Button>
        </>
      }
    >
      <form>
        <BlockCard>
          {emptyFieldError && <Alert level="error" message={Locales.trip.errors.emptyfield} />}

          <p>
            {Locales.trip.enter_pilgrim_details} <strong>{getUserName(firstname, lastname)}</strong> :
          </p>

          <TextField
            label={Locales.base.field.npi}
            type="text"
            placeholder={Locales.base.field.placeholder.npi}
            pattern="^[0-9]{10}$"
            maxLength="10"
            minLength="10"
            defaultValue={npi}
            error={npiError ? Locales.trip.errors.npi : ''}
            onChange={setNPI}
          />

          <TextField
            label={Locales.base.field.passport}
            type="text"
            placeholder={Locales.base.field.placeholder.passport}
            pattern="^[A-Z0-9]{8,9}$"
            minLength="7"
            maxLength="10"
            defaultValue={passport}
            error={passportError ? Locales.trip.errors.passport : ''}
            onChange={setPassport}
          />

          <div className="items-group">
            <TextField
              label={Locales.base.field.first_name}
              type="text"
              placeholder={Locales.base.field.placeholder.firstname}
              defaultValue={firstname}
              onChange={setFirstname}
              required
            />

            <TextField
              label={Locales.base.field.last_name}
              type="text"
              placeholder={Locales.base.field.placeholder.lastname}
              defaultValue={lastname}
              onChange={setLastname}
              required
            />
          </div>

          <DatePicker
            label={Locales.base.field.birthdate}
            placeholder={Locales.base.field.placeholder.date}
            month={month}
            year={year}
            defaultValue={new Date(birthdate)}
            onChange={(date) => {
              setBirthdate(date);
              setBirthDateError(null); // Clear the error when a valid date is selected
            }}
            onMonthChange={handleMonthChange}
            selected={pilgrimBirthdate}
            maxDate={new Date()}
            onInputError={handleBirthDateInputError}
          />

          <DatePicker
            label={Locales.base.field.passport_expiration}
            placeholder={Locales.base.field.placeholder.date}
            month={month}
            year={year}
            defaultValue={pilgrimPassportExpirationDate}
            onChange={(date) => {
              setPassportExpirationDate(date);
              setPassportExpirationDateError(null); // Clear the error when a valid date is selected
            }}
            onMonthChange={handleMonthChange}
            selected={pilgrimPassportExpirationDate}
            minDate={passportMinExpirationDate}
            onInputError={handleExpirationDateInputError}
          />

          <Select
            label={Locales.trip.table.categorie}
            data={categories}
            defaultValue={pilgrimCategory}
            helpText={Locales.formatString(Locales.trip.categories.help_text, {
              details: (
                <div
                  className={styles.categoriesModalTrigger}
                  role="button"
                  tabIndex={0}
                  onClick={openCategoriesModal}
                  onKeyDown={(e) => handleKeyDown(e, openCategoriesModal)}
                >
                  {Locales.trip.categories.help_text_btn}
                </div>
              ),
            })}
            onChange={setPilgrimCategory}
            disabled={(isAgency && isInvoicePaid) || isVisaSubmission}
          />

          <TextField
            defaultValue={pilgrimNote}
            label={Locales.base.field.note}
            type="text"
            placeholder="..."
            onChange={setPilgrimNote}
          />
        </BlockCard>
      </form>
    </Modal>
  );
};

EditTripModal.defaultProps = {
  isOpened: false,
  pilgrimData: null,
  categories: null,
  openCategoriesModal: null,
  closeModal: null,
};

EditTripModal.propTypes = {
  isOpened: PropTypes.bool,
  pilgrimData: PropTypes.shape({}),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  openCategoriesModal: PropTypes.func,
  closeModal: PropTypes.func,
};

export default EditTripModal;
