import React, { useState } from 'react';
import { Page } from '@trellixio/roaster-coffee';
import Locales from 'locales';
import { useQuery } from '@tanstack/react-query';
import { useAPI } from 'hooks';
import { formatDate } from 'helpers';
import { StatCardsList } from 'components';
import TripDistributionChart from './TripDistributionChart';
import TripDistributionTable from './TripDistributionTable';
import style from './AGLODashboard.module.css';

const CONST_DISPLAY_DURATION = 1000;

const AGLODashboard = () => {
  const { api } = useAPI();
  const limit = 100;

  const { data: reportsData } = useQuery({
    queryFn: api.list('trip/trip_report/'),
    queryKey: ['trip/trip_report/'],
  });

  const { data: pilgrimsStatsData } = useQuery({
    queryFn: api.list('trip/stats/default/'),
    queryKey: ['trip/stats/default/'],
  });

  const { data: pilgrimsCategoryStatsData } = useQuery({
    queryFn: api.list('trip/stats/category/'),
    queryKey: ['trip/stats/category/'],
  });

  const { data: pilgrimsCategoryPaidStatsData } = useQuery({
    queryFn: api.list('trip/stats/trip_category_paid/'),
    queryKey: ['trip/stats/trip_category_paid/'],
  });

  const { data: agenciesStatsData } = useQuery({
    queryFn: api.list('agency/stats/default/'),
    queryKey: ['agency/stats/default/'],
  });

  const { data: agencyList } = useQuery({
    queryFn: api.list('agency/', { limit }),
    queryKey: ['agency/'],
  });

  const reports = reportsData?.data;

  const pilgrimsStats = pilgrimsStatsData?.data.data;

  const agenciesStats = agenciesStatsData?.data.data;

  const pilgrimsCategoryStats = pilgrimsCategoryStatsData?.data.data;

  const pilgrimsCategoryPaidStats = pilgrimsCategoryPaidStatsData?.data.data;

  const [lastDownloadTime, setLastDownloadTime] = useState({
    chart: null,
    table: null,
  });

  // Updates the last download time for a specific section and resets this value after a defined duration.
  const updateDownloadTime = (key) => {
    // Create a timestamp with the current date and time
    const timestamp = formatDate(new Date(), 'dddd DD MMMM YYYY [à] HH:mm:ss');

    // Update the state to display the timestamp for the corresponding section
    setLastDownloadTime((prev) => ({ ...prev, [key]: timestamp }));

    // Start a timeout to reset the timestamp after the defined duration
    setTimeout(() => {
      setLastDownloadTime((prev) => ({ ...prev, [key]: null }));
    }, CONST_DISPLAY_DURATION);
  };

  // Function to hide the download button
  const hideDownloadButton = (container) => {
    const downloadButton = container.querySelector('.button.secondary');
    if (downloadButton) {
      downloadButton.style.display = 'none';
    }
  };

  // Function to print the reports
  const printAction = (sectionRef) => {
    const printContent = sectionRef.current?.cloneNode(true);

    // Hide the download button before printing
    hideDownloadButton(printContent);

    // Open a new window for printing
    const printWindow = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

    // Copy styles from the main document
    const styles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join('');
        } catch (e) {
          return '';
        }
      })
      .join('\n');

    const reportStyles = `
     @media print {
        html {
          font-family: 'DM Sans', sans-serif;
          font-size: 14px;
        }
        body {
          -webkit-print-color-adjust: exact; /* For Chrome and Edge */
          print-color-adjust: exact; /* For other browsers */
        }
        @page {
          size: landscape;
          margin: 1.3cm;
        }
      }
    `;

    // Include the DM Sans font via Google Fonts
    const fontLink = `<link href="https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400&display=swap" rel="stylesheet" />`;

    printWindow.document.write(fontLink); // Include the font link

    // Write styles and content to the new window
    printWindow.document.write(`<style>${styles}${reportStyles}</style>`);
    printWindow.document.write(printContent.outerHTML);
    printWindow.document.close();

    // Focus on the new window and initiate printing
    printWindow.focus();
    printWindow.print();
  };

  // Function to trigger printing and update the timestamp
  const handlePrint = (sectionRef, sectionKey) => {
    updateDownloadTime(sectionKey); // Update the timestamp
    setTimeout(() => {
      printAction(sectionRef); // Print the section
    }, 500);
  };

  return (
    <Page title={Locales.dashboard.title} size="full" className={style['big-container']}>
      <div className="stats-container items-group wrap xl">
        {pilgrimsStats && <StatCardsList statsData={pilgrimsStats} />}
        {pilgrimsCategoryStats && <StatCardsList statsData={pilgrimsCategoryStats} />}
        {pilgrimsCategoryPaidStats && <StatCardsList statsData={pilgrimsCategoryPaidStats} />}
        {agenciesStats && <StatCardsList statsData={agenciesStats} />}
      </div>

      <div className={style['reports-block']}>
        <TripDistributionChart
          reports={reports}
          agencyList={agencyList}
          lastDownloadTime={lastDownloadTime.chart}
          handlePrint={handlePrint}
        />

        <TripDistributionTable
          reports={reports}
          agencyList={agencyList}
          lastDownloadTime={lastDownloadTime.table}
          handlePrint={handlePrint}
        />
      </div>
    </Page>
  );
};

export default AGLODashboard;
