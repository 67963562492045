import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Page, DropdownCard, Button, BlockCard, StatCard, Tag } from '@trellixio/roaster-coffee';
import { useQuery } from '@tanstack/react-query';
import { PermissionsContext } from 'context';
import { useAPI, useGetUser } from 'hooks';
import { EmptyTable, FilterSelect, LockedPlatformAlert } from 'components';
import Locales from 'locales';
import { printTable, getColumnsIndexes, updateOriginalHeader, formatNumber } from 'helpers';
import DepositsTable from './components/DepositsTable';

// Constants for filter labels and values used in trip filtering
const CONST_NONE = 'none';
const CONST_FILTER_LABEL_AGENCY = 'agency';
const CONST_FILTER_LABEL_INVOICE = 'invoice';
const CONST_FILTER_LABEL_DEPOSIT = 'deposit';
const CONST_FILTER_VALUE_DEPOSIT_CONFIRMED = 'deposit_confirmed';
const CONST_FILTER_VALUE_DEPOSIT_PENDING = 'deposit_pending';
const CONST_FILTER_LABEL_DATE = 'date';
const CONST_FILTER_VALUE_DATE_ASCENDING = 'asc';
const CONST_FILTER_VALUE_DATE_DESCENDING = 'desc';

// Definition of columns for the deposits table
const COLUMNS = {
  SELECTOR: 'Sélecteur',
  THUMBNAIL: 'Thumbnail',
  DEPOSIT_DATE: 'Date de dépôt',
  COMPANY: 'Société',
  TRIP: 'Pèlerin',
  PASSPORT: 'Passeport',
  AMOUNT: 'Montant',
  INVOICE: 'Facture',
  STATUS: 'Statut',
  ACTIONS: 'Actions',
};

// Define columns for each user type
const USER_TABLE = {
  // Columns for Agency users
  AGENCY: [
    COLUMNS.SELECTOR,
    COLUMNS.THUMBNAIL,
    COLUMNS.DEPOSIT_DATE,
    COLUMNS.TRIP,
    COLUMNS.PASSPORT,
    COLUMNS.AMOUNT,
    COLUMNS.INVOICE,
    COLUMNS.STATUS,
    COLUMNS.ACTIONS,
  ],
  // Columns for Admin users
  ADMIN: [
    COLUMNS.THUMBNAIL,
    COLUMNS.DEPOSIT_DATE,
    COLUMNS.COMPANY,
    COLUMNS.TRIP,
    COLUMNS.PASSPORT,
    COLUMNS.AMOUNT,
    COLUMNS.INVOICE,
    COLUMNS.STATUS,
    COLUMNS.ACTIONS,
  ],
};

const Deposit = () => {
  const { isPlatformLocked } = useContext(PermissionsContext);
  const { isAGLO, isAgency } = useGetUser();
  const { api } = useAPI();
  const [cursor, setCursor] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const preSetAgency = isAGLO ? searchParams.get(CONST_FILTER_LABEL_AGENCY) : null;
  const preSetInvoice = isAGLO || isAgency ? searchParams.get(CONST_FILTER_LABEL_INVOICE) : null;
  const preSetPaymentStatus = isAGLO || isAgency ? searchParams.get(CONST_FILTER_LABEL_DEPOSIT) : null;
  const preSetDate = isAGLO || isAgency ? searchParams.get(CONST_FILTER_LABEL_DATE) : null;

  const [selectedAgency, setAgency] = useState(preSetAgency || CONST_NONE);
  const [selectedInvoice, setInvoice] = useState(preSetInvoice || CONST_NONE);
  const [selectedPaymentStatus, setPaymentStatus] = useState(preSetPaymentStatus || CONST_NONE);
  const [selectedDate, setDate] = useState(preSetDate || CONST_NONE);

  const tableRef = useRef(null);
  const [originalHeader, setOriginalHeader] = useState(null);

  const [queryFilters, setQueryFilter] = useState(null);
  const limit = 30;

  const fetchParams = queryFilters ? { ...queryFilters, limit, cursor } : { limit, cursor };

  const { data: deposits } = useQuery({
    queryFn: api.list('deposit/', fetchParams),
    queryKey: ['deposit/', limit, cursor, queryFilters],
    keepPreviousData: true,
  });

  const { data: agencies } = useQuery({
    queryFn: api.list('agency/', { limit: 100 }),
    queryKey: ['agency/'],
  });

  const { data: invoices } = useQuery({
    queryFn: api.list('invoice/', { limit: 100 }),
    queryKey: ['invoice/'],
  });

  const { data: depositsData } = useQuery({
    queryFn: api.list('deposit/stats/default/'),
    queryKey: ['deposit/stats/default/'],
  });

  const depositsStats = depositsData?.data.data;

  const isInvoiceOnlyDeposit = (invoiceCode) =>
    deposits.filter((deposit) => deposit.invoice_code === invoiceCode).length === 1;

  const resultString =
    deposits?.count > 1
      ? `${deposits.count} ${Locales.deposit.title.toLowerCase()}`
      : `${deposits?.count || 0} ${Locales.deposit.title.toLowerCase().slice(0, -1)}`;

  const selectableListDefaultOption = [{ label: Locales.base.button.select, value: CONST_NONE, disabled: true }];

  const paymentStatus = [
    { label: Locales.deposit.filter.payment_status.deposit_confirmed, value: CONST_FILTER_VALUE_DEPOSIT_CONFIRMED },
    { label: Locales.deposit.filter.payment_status.deposit_pending, value: CONST_FILTER_VALUE_DEPOSIT_PENDING },
  ];

  const creationDate = [
    { label: Locales.deposit.filter.creation_date.date_ascending, value: CONST_FILTER_VALUE_DATE_ASCENDING },
    { label: Locales.deposit.filter.creation_date.date_descending, value: CONST_FILTER_VALUE_DATE_DESCENDING },
  ];

  const selectedAgencyName = () => {
    let name = null;
    if (selectedAgency !== CONST_NONE) {
      agencies?.forEach((data) => {
        if (data.id === selectedAgency) name = data.name;
      });
    }

    return name;
  };

  const selectedInvoiceCode = () => {
    let code = null;
    if (selectedInvoice !== CONST_NONE) {
      invoices?.forEach((data) => {
        if (data.id === selectedInvoice) code = data.code;
      });
    }

    return code;
  };

  const selectedPaymentStatusName = () => {
    let label = null;
    if (selectedPaymentStatus !== CONST_NONE) {
      paymentStatus.forEach((data) => {
        if (data.value === selectedPaymentStatus) label = data.label;
      });
    }

    return label;
  };

  const selectedCreationDate = () => {
    let label = null;
    if (selectedDate !== CONST_NONE) {
      creationDate.forEach((data) => {
        if (data.value === selectedDate) label = data.label;
      });
    }

    return label;
  };

  const filterOptions = [
    {
      id: CONST_FILTER_LABEL_AGENCY,
      label: Locales.trip.filter.agreed_agency,
      helpText: Locales.deposit.filter.helptext.agreed_agency,
      value: selectedAgency,
      data: selectableListDefaultOption.concat(agencies),
      onChange: setAgency,
      tagName: selectedAgencyName(),
      condition: isAGLO,
    },
    {
      id: CONST_FILTER_LABEL_DEPOSIT,
      label: Locales.base.dropdown.payment_status,
      helpText: Locales.deposit.filter.helptext.payment_status,
      value: selectedPaymentStatus,
      data: selectableListDefaultOption.concat(paymentStatus),
      onChange: setPaymentStatus,
      tagName: selectedPaymentStatusName(),
      condition: isAGLO || isAgency,
    },
    {
      id: CONST_FILTER_LABEL_INVOICE,
      label: Locales.base.dropdown.invoice,
      helpText: Locales.deposit.filter.helptext.invoice,
      value: selectedInvoice,
      data: selectableListDefaultOption.concat(
        invoices?.map((invoice) => ({
          label: invoice.code,
          value: invoice.id,
        }))
      ),
      onChange: setInvoice,
      tagName: selectedInvoiceCode(),
      condition: isAGLO || isAgency,
    },
    {
      id: CONST_FILTER_LABEL_DATE,
      label: Locales.base.dropdown.sort_by_date,
      helpText: Locales.deposit.filter.helptext.date,
      value: selectedDate,
      data: selectableListDefaultOption.concat(creationDate),
      onChange: setDate,
      tagName: selectedCreationDate(),
      condition: isAGLO || isAgency,
    },
  ];

  // Function to apply selected filters
  const applyFilters = () => {
    let filters = {};

    if (selectedAgency !== CONST_NONE) {
      filters = { [CONST_FILTER_LABEL_AGENCY]: selectedAgency };
    }

    if (selectedInvoice !== CONST_NONE) {
      filters = { ...filters, [CONST_FILTER_LABEL_INVOICE]: selectedInvoice };
    }

    if (selectedPaymentStatus !== CONST_NONE) {
      filters = { ...filters, [CONST_FILTER_LABEL_DEPOSIT]: selectedPaymentStatus };
    }

    if (selectedDate !== CONST_NONE) {
      filters = { ...filters, [CONST_FILTER_LABEL_DATE]: selectedDate };
    }

    setQueryFilter(filters);
  };

  // Function to reset all filters to their default state (CONST_NONE)
  const resetFilters = () => {
    setAgency(CONST_NONE);
    setInvoice(CONST_NONE);
    setPaymentStatus(CONST_NONE);
    setDate(CONST_NONE);
    setQueryFilter(null);
  };

  // Function to remove a specific filter from the query filters
  const deleteFilter = (filter) => {
    const queryFiltersLocal = { ...queryFilters };
    delete queryFiltersLocal[filter];
    setQueryFilter(queryFiltersLocal);
  };

  // Function to remove a specific filter and reset its corresponding state
  const removeFilter = (filter) => {
    switch (filter) {
      case CONST_FILTER_LABEL_AGENCY:
        setAgency(CONST_NONE);
        break;
      case CONST_FILTER_LABEL_INVOICE:
        setInvoice(CONST_NONE);
        break;
      case CONST_FILTER_LABEL_DEPOSIT:
        setPaymentStatus(CONST_NONE);
        break;
      case CONST_FILTER_LABEL_DATE:
        setDate(CONST_NONE);
        break;
      default:
        break;
    }
    deleteFilter(filter);
  };

  // This function determines which columns to hide based on the user's role
  const getColumnsToHide = () => {
    let columnsToHide = [];
    if (isAgency) {
      columnsToHide = getColumnsIndexes('AGENCY', ['SELECTOR', 'ACTIONS'], USER_TABLE, COLUMNS);
    } else if (isAGLO) {
      columnsToHide = getColumnsIndexes('ADMIN', ['THUMBNAIL', 'ACTIONS'], USER_TABLE, COLUMNS);
    }
    return columnsToHide;
  };

  useMemo(() => {
    // set value if in URL and allow the filter to be applied only if the user have the right to apply it
    if (
      preSetAgency !== CONST_NONE ||
      preSetInvoice !== CONST_NONE ||
      preSetPaymentStatus !== CONST_NONE ||
      preSetDate !== CONST_NONE
    ) {
      applyFilters();
    }
  }, []);

  useMemo(() => {
    if (preSetAgency === null && preSetInvoice === null && preSetPaymentStatus === null && preSetDate === null) {
      resetFilters();
    }
  }, [preSetAgency, preSetInvoice, preSetPaymentStatus, preSetDate]);

  useMemo(() => {
    let params = {};

    // update URL each time a filter is selected
    if (selectedAgency !== CONST_NONE) {
      params = { [CONST_FILTER_LABEL_AGENCY]: selectedAgency };
    }

    if (selectedInvoice !== CONST_NONE) {
      params = { ...params, [CONST_FILTER_LABEL_INVOICE]: selectedInvoice };
    }

    if (selectedPaymentStatus !== CONST_NONE) {
      params = { ...params, [CONST_FILTER_LABEL_DEPOSIT]: selectedPaymentStatus };
    }

    if (selectedDate !== CONST_NONE) {
      params = { ...params, [CONST_FILTER_LABEL_DATE]: selectedDate };
    }

    if (params) {
      setSearchParams(params);
    }
  }, [selectedAgency, selectedInvoice, selectedPaymentStatus, selectedDate]);

  useEffect(() => {
    updateOriginalHeader(tableRef, setOriginalHeader, originalHeader);
  }, [deposits]);
  return (
    deposits && (
      <Page
        title={Locales.deposit.title}
        size="full"
        secondaryAction={
          <Button
            variant="secondary"
            color="success"
            onClick={() => printTable(tableRef, originalHeader, getColumnsToHide())}
          >
            <i className="fa-regular fa-solid fa-print" />
            {Locales.base.button.print}
          </Button>
        }
      >
        {isPlatformLocked && isAgency && <LockedPlatformAlert />}
        {depositsStats && (
          <div className="stats-container items-group wrap xl">
            {depositsStats.map((stats) => {
              let result = null;

              if (stats.percent === null) {
                result = (
                  <StatCard
                    key={stats.name}
                    title={stats.name}
                    value={formatNumber(stats.value)}
                    description={stats.description}
                  />
                );
              } else {
                result = (
                  <StatCard
                    key={stats.name}
                    title={stats.name}
                    value={formatNumber(stats.value)}
                    description={stats.description}
                    progressValue={stats.percent}
                    progressLabel={`${stats.percent}%`}
                  />
                );
              }

              return result;
            })}
          </div>
        )}

        <section className="table-form">
          <form>
            <div className="table-actions items-group">
              <div />
              <DropdownCard>
                <DropdownCard.Trigger>
                  <Button variant="secondary">
                    <i className="fa-solid fa-regular fa-bars-filter" /> {Locales.base.dropdown.filter}
                  </Button>
                </DropdownCard.Trigger>
                <DropdownCard.Dropdown width="large">
                  <DropdownCard.Content>
                    <form>
                      {filterOptions.map((option) =>
                        option.condition ? (
                          <FilterSelect
                            key={option.id}
                            label={option.label}
                            helpText={option.helpText}
                            value={option.value}
                            data={option.data}
                            onChange={option.onChange}
                          />
                        ) : null
                      )}
                    </form>
                  </DropdownCard.Content>
                  <DropdownCard.Actions>
                    <Button variant="inline" onClick={resetFilters}>
                      {Locales.base.button.reset}
                    </Button>
                    <Button color="success" onClick={applyFilters}>
                      {Locales.base.button.validate}
                    </Button>
                  </DropdownCard.Actions>
                </DropdownCard.Dropdown>
              </DropdownCard>
            </div>
          </form>
        </section>

        <BlockCard>
          <div className="table-meta items-group">
            <div>
              <span>{Locales.base.result} : </span>
              <strong>{resultString}</strong>
            </div>

            <div className="tags-container">
              {queryFilters &&
                filterOptions.map(
                  (filter) =>
                    queryFilters[filter.id] && (
                      <>
                        <span>|</span>
                        <Tag onRemove={() => removeFilter(filter.id)}>{`${filter.label}: ${filter.tagName}`}</Tag>
                      </>
                    )
                )}
            </div>
          </div>
        </BlockCard>

        {deposits?.count > 0 && (
          <DepositsTable
            tableData={deposits}
            limit={limit}
            setCursor={setCursor}
            tableRef={tableRef}
            isInvoiceOnlyDeposit={isInvoiceOnlyDeposit}
            isPlatformLocked={isPlatformLocked}
          />
        )}

        {deposits?.count === 0 && <EmptyTable />}
      </Page>
    )
  );
};

export default Deposit;
