import { useState } from 'react';

/**
 * Custom hook to check user permissions and handle locked modal visibility.
 *
 * @returns {object} An object containing:
 * - `checkPermission`: A function to verify platform lock status and execute a callback or open a modal if locked.
 * - `openedLockedModal`: A boolean indicating whether the locked modal is open.
 * - `closeLockedModal`: A function to close the locked modal.
 */
const useCheckPermission = () => {
  const [openedLockedModal, setOpenedLockedModal] = useState(false);

  /**
   * Check if the platform is locked and perform an action if not locked,
   * or show a modal if the platform is locked.
   *
   * @param {boolean} isPlatformLocked A boolean indicating if the platform is locked.
   * @param {Function} callback The function to execute if the platform is not locked.
   */
  const checkPermission = (isPlatformLocked, callback) => {
    if (isPlatformLocked) {
      setOpenedLockedModal(true);
    } else {
      callback();
    }
  };

  // Function to close the locked modal
  const closeLockedModal = () => setOpenedLockedModal(false);

  return { checkPermission, openedLockedModal, closeLockedModal };
};

export default useCheckPermission;
