import React, { useState } from 'react';
import {
  Table,
  Button,
  ActionList,
  ModalProvider,
  Icon,
  Tooltip,
  showToast,
  openModal,
} from '@trellixio/roaster-coffee';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI } from 'hooks';
import PropTypes from 'prop-types';
import { getUserName, formatDate, hasNextPage, hasPreviousPage, updateQueryCursor, showPagination } from 'helpers';
import Locales from 'locales';
import agencyIcon from 'assets/img/sidebar/agency.svg';
import EditAgencyModal from './EditAgencyModal';
import style from './Agency.module.css';

const AgenciesTable = ({ tableData, setCursor, limit }) => {
  const [openedEditModal, setOpenedEditModal] = useState(false);
  const [selectedAgencyData, setAgencyData] = useState({});

  const openEditModal = (orgData) => {
    setAgencyData(orgData);
    setOpenedEditModal((val) => !val);
  };

  const { api } = useAPI();

  const queryClient = useQueryClient();

  const { mutate: approveLicence } = useMutation({
    mutationFn: (agency) => api.post(`agency/${agency.id}/license_approve/`),
    onSuccess: ({ data }) => {
      const { name } = data;
      queryClient.invalidateQueries({ queryKey: ['agency/'] });
      queryClient.invalidateQueries({ queryKey: ['agency/stats/default/'] });
      showToast({
        message: Locales.formatString(Locales.agency.toast.validate_autorisation, {
          name,
        }),
        level: 'success',
      });
    },
  });

  const { mutate: revokeLicence } = useMutation({
    mutationFn: (agency) => api.post(`agency/${agency.id}/license_revoke/`),
    onSuccess: ({ data }) => {
      const { name } = data;
      queryClient.invalidateQueries({ queryKey: ['agency/'] });
      queryClient.invalidateQueries({ queryKey: ['agency/stats/default/'] });
      showToast({
        message: Locales.formatString(Locales.agency.toast.cancel_autorisation, {
          name,
        }),
        level: 'success',
      });
    },
  });

  const handleKeyDown = (e, callback, data) => {
    if (e.keyCode === 13) {
      callback(data);
    }
  };

  const handleOpenApproveLicenseModal = (agency) => {
    const { name } = agency;
    openModal({
      title: Locales.agency.give_autorization,
      children: (
        <div>
          <p>{Locales.formatString(Locales.agency.make_autorisation_request, { name: <strong>{name}</strong> })}</p>
          <div className="content-row" />
          <p>{Locales.agency.after_giving_autorization}</p>
        </div>
      ),
      confirmProps: {
        children: Locales.agency.confirm_authorization,
        variant: 'primary',
        color: 'success',
      },
      cancelProps: { children: Locales.base.button.discard, variant: 'secondary', color: '' },
      onCancel: () => {},
      onConfirm: () => approveLicence(agency),
    });
  };

  const handleOpenRevokeLicenseModal = (agency) => {
    const { name } = agency;
    openModal({
      title: Locales.agency.remove_autorization,
      children: (
        <div>
          <p>
            {Locales.formatString(Locales.agency.make_remove_autorisation_request, { name: <strong>{name}</strong> })}
          </p>
          <div className="content-row" />
          <p>{Locales.agency.after_removing_autorization}</p>
        </div>
      ),
      confirmProps: { children: Locales.agency.button.remove, variant: 'primary', color: 'danger' },
      cancelProps: { children: Locales.base.button.discard, variant: 'secondary', color: '' },
      onCancel: () => {},
      onConfirm: () => revokeLicence(agency),
    });
  };

  const licenseIcon = (license) => {
    let iconElement;

    if (license.approved) {
      iconElement = (
        <Icon small color="success">
          <i className="fa-solid fa-check" />
        </Icon>
      );
    } else if (license.requested) {
      iconElement = (
        <Icon small color="primary">
          <i className="fa-solid fa-file" />
        </Icon>
      );
    } else {
      iconElement = (
        <Icon small className="gray">
          <i className="fa-solid fa-question" />
        </Icon>
      );
    }

    return iconElement;
  };

  return (
    <ModalProvider modalProps={{ size: 'small' }}>
      <Table
        showPagination={showPagination(tableData, limit)}
        hasNextPage={hasNextPage(tableData)}
        hasPreviousPage={hasPreviousPage(tableData)}
        onNextPage={() => updateQueryCursor(tableData.next, setCursor)}
        onPreviousPage={() => updateQueryCursor(tableData.previous, setCursor)}
      >
        <Table.Head>
          <Table.HeadCell type="thumbnail" />
          <Table.HeadCell type="text">{Locales.base.field.last_name}</Table.HeadCell>
          <Table.HeadCell type="numeric">{Locales.base.field.ifu}</Table.HeadCell>
          <Table.HeadCell type="numeric">{Locales.base.field.phone}</Table.HeadCell>
          <Table.HeadCell type="paragraph">{Locales.base.field.address}</Table.HeadCell>
          <Table.HeadCell type="text">{Locales.agency.admin}</Table.HeadCell>
          <Table.HeadCell type="text">{Locales.base.field.mail}</Table.HeadCell>
          <Table.HeadCell type="enum">
            {Locales.base.status.activation}
            <Tooltip inline label={Locales.agency.tooltips.user_account}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="numeric">
            {Locales.agency.registered_pilgrims}
            <Tooltip inline label={Locales.agency.tooltips.registered_pilgrims}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="numeric">
            {Locales.agency.validated_pilgrims}
            <Tooltip inline label={Locales.agency.tooltips.validated_pilgrims}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="enum">
            {Locales.agency.badge}
            <Tooltip inline label={Locales.agency.tooltips.badge}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="text">
            {Locales.agency.autorisation}
            <Tooltip inline label={Locales.agency.tooltips.autorisation}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="date">
            {Locales.agency.creation}
            <Tooltip inline label={Locales.agency.tooltips.agency_registration_date}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="object">{Locales.base.button.actions}</Table.HeadCell>
        </Table.Head>

        {tableData && tableData.length > 0 && (
          <Table.Body>
            {tableData.map((data, key) => (
              <Table.Row key={data.id} id={key}>
                <Table.Cell type="thumbnail">
                  <Icon>
                    <img src={agencyIcon} alt="" />
                  </Icon>
                </Table.Cell>
                <Table.Cell type="text">
                  {data.name}
                  <br />
                  {data.merger.approved && (
                    <sub className={style.sub} title={`${Locales.agency.merger} ${data.merger.master_name}`}>
                      ↳ {data.merger.master_name}
                    </sub>
                  )}
                </Table.Cell>
                <Table.Cell type="numeric">{data.ifu}</Table.Cell>
                <Table.Cell type="numeric">{data.phone}</Table.Cell>
                <Table.Cell type="paragraph">{data.address}</Table.Cell>
                <Table.Cell type="text">{getUserName(data.admin.first_name, data.admin.last_name)}</Table.Cell>
                <Table.Cell type="text">{data.admin.email}</Table.Cell>
                <Table.Cell type="text">
                  {data.admin.is_verified && data.admin.is_active ? (
                    <Icon small color="success">
                      <i className="fa-solid fa-check" />
                    </Icon>
                  ) : (
                    <Icon small color="danger">
                      <i className="fa-solid fa-xmark" />
                    </Icon>
                  )}
                </Table.Cell>
                <Table.Cell type="numeric">{data.count_pilgrim_registered}</Table.Cell>
                <Table.Cell type="numeric">{data.count_pilgrim_validated}</Table.Cell>
                <Table.Cell type="enum">
                  {data.badge && (
                    <a href={data.badge} download>
                      <img className="border-card avatar" name="product thumbnail" src={data.badge} alt="badge" />
                    </a>
                  )}
                </Table.Cell>
                <Table.Cell type="text">{licenseIcon(data.license)}</Table.Cell>
                <Table.Cell type="date">{formatDate(data.created)}</Table.Cell>
                <Table.Cell type="text">
                  <ActionList>
                    <ActionList.Trigger>
                      <Button variant="secondary" isIconButton>
                        <i className="fa-regular fa-solid fa-ellipsis-vertical" />
                      </Button>
                    </ActionList.Trigger>

                    <ActionList.Dropdown>
                      <ActionList.Group>
                        <ActionList.Item>
                          {!data.license.approved && (
                            <div
                              className="action-item"
                              role="button"
                              tabIndex="0"
                              onClick={() => handleOpenApproveLicenseModal(data)}
                              onKeyDown={(e) => handleKeyDown(e, handleOpenApproveLicenseModal, data)}
                            >
                              <i className="fa-regular fa-file" />
                              <span>{Locales.agency.button.authorize}</span>
                            </div>
                          )}
                          {data.license.approved && (
                            <div
                              className="action-item"
                              role="button"
                              tabIndex="0"
                              onClick={() => handleOpenRevokeLicenseModal(data)}
                              onKeyDown={(e) => handleKeyDown(e, handleOpenRevokeLicenseModal, data)}
                            >
                              <i className="fa-regular fa-ban" />
                              <span>{Locales.agency.button.remove}</span>
                            </div>
                          )}
                        </ActionList.Item>

                        <ActionList.Item>
                          <div
                            role="button"
                            tabIndex="0"
                            onClick={() => openEditModal(data)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                openEditModal(data);
                              }
                            }}
                            className="action-item"
                          >
                            <i className="fa-regular fa-pen-to-square" />
                            <span>{Locales.agency.button.modify}</span>
                          </div>
                        </ActionList.Item>
                      </ActionList.Group>
                    </ActionList.Dropdown>
                  </ActionList>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
      {Object.keys(selectedAgencyData).length > 0 && openedEditModal && (
        <EditAgencyModal
          agency={selectedAgencyData}
          opened={openedEditModal}
          closeModal={() => setOpenedEditModal(false)}
        />
      )}
    </ModalProvider>
  );
};

AgenciesTable.defaultProps = {
  tableData: null,
  setCursor: null,
  limit: null,
};

AgenciesTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  setCursor: PropTypes.func,
  limit: PropTypes.number,
};

export default AgenciesTable;
