import React from 'react';
import { Table, Tag, ActionList, Button, Icon, Tooltip } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { formatDate, formatNumber } from 'helpers';
import { useGetUser, useGetPilgrim } from 'hooks';
import Locales from 'locales';
import { LockableActionWrapper } from 'components';
import style from './Trips.module.css';

const CONST_PASSPORT = 'PASSPORT'; // The pilgrim should provide their passport
const CONST_HEALTH = 'HEALTH'; // The pilgrim should go through the health check
const CONST_HEALTH_FAIL = 'HEALTH_FAIL'; // The pilgrim failed health check
const CONST_VISA_SUBMISSION = 'VISA_SUBMISSION'; // The pilgrim should go through the visa delivery service
const CONST_VISA_IN_PROGRESS = 'VISA_IN_PROGRESS'; // The visa requested has been submitted to MAEC and pending response
const CONST_VISA_GRANT = 'VISA_GRANT'; // The pilgrim should go through the visa delivery service
const CONST_TRAVEL_TICKET = 'TRAVEL_TICKET'; // The pilgrim should get their travel ticket
const CONST_COMPLETED = 'COMPLETED'; // The pilgrim has gone through all process and it ready to travel.
const CONST_ABORTED = 'ABORTED'; // The trip has been aborted
const CONST_DEPOSIT_REGISTER = 'FEE_REGISTER'; // The pilgrim should pay the registration fee
const CONST_DEPOSIT_CART = 'FEE_CART'; // The pilgrim should now pay the total of his cart with selected options
const CONST_INVOICE_REGISTER = 'INVOICE_REGISTER'; // The pilgrim is waiting for the agency to pay registration invoice to bank
const CONST_INVOICE_CART = 'INVOICE_CART'; // The pilgrim is waiting for the agency to pay the balance invoice to the bank

const PILGRIMS_STEPS = [
  {
    tooltip: Locales.trip.next_steps.passport_required.tooltip,
    label: Locales.trip.next_steps.passport_required.label,
    value: CONST_PASSPORT,
    iconClassName: 'fa-regular fa-address-card',
  },
  {
    tooltip: Locales.trip.next_steps.passport_submitted.tooltip,
    label: Locales.trip.next_steps.passport_submitted.label,
    value: CONST_VISA_IN_PROGRESS,
    color: 'success',
    iconClassName: 'fa-regular fa-star-and-crescent',
  },
  {
    tooltip: Locales.trip.next_steps.passport_to_submit.tooltip,
    label: Locales.trip.next_steps.passport_to_submit.label,
    value: CONST_VISA_SUBMISSION,
    color: 'info',
    iconClassName: 'fa-regular fa-kaaba',
  },
  {
    tooltip: Locales.trip.next_steps.medical_check_required.tooltip,
    label: Locales.trip.next_steps.medical_check_required.label,
    value: CONST_HEALTH,
    color: 'success',
    iconClassName: 'fa-regular fa-stethoscope',
  },
  {
    tooltip: Locales.trip.next_steps.medical_check_failed.tooltip,
    label: Locales.trip.next_steps.medical_check_failed.label,
    value: CONST_HEALTH_FAIL,
    color: 'danger',
    iconClassName: 'fa-regular fa-stethoscope',
  },
  {
    tooltip: Locales.trip.next_steps.registration_fee.tooltip,
    label: Locales.trip.next_steps.registration_fee.label,
    value: CONST_DEPOSIT_REGISTER,
    color: '',
    iconClassName: 'fa-regular fa-sack-dollar',
  },
  {
    tooltip: Locales.trip.next_steps.visa_received.tooltip,
    label: Locales.trip.next_steps.visa_received.label,
    value: CONST_VISA_GRANT,
    color: 'success',
    iconClassName: 'fa-regular fa-star-and-crescent',
  },
  {
    tooltip: Locales.trip.next_steps.travel_ticket.tooltip,
    label: Locales.trip.next_steps.travel_ticket.label,
    value: CONST_TRAVEL_TICKET,
    iconClassName: 'fa-regular fa-address-card',
  },
  {
    tooltip: Locales.trip.next_steps.process_completed.tooltip,
    label: Locales.trip.next_steps.process_completed.label,
    value: CONST_COMPLETED,
    color: 'success',
    iconClassName: 'fa-regular fa-circle-check',
  },
  {
    tooltip: Locales.trip.next_steps.process_aborted.tooltip,
    label: Locales.trip.next_steps.process_aborted.label,
    value: CONST_ABORTED,
    color: 'danger',
    iconClassName: 'fa-regular fa-ban',
  },
  {
    tooltip: Locales.trip.next_steps.balance_deposit.tooltip,
    label: Locales.trip.next_steps.balance_deposit.label,
    value: CONST_DEPOSIT_CART,
    color: '',
    iconClassName: 'fa-regular fa-sack-dollar',
  },
  {
    tooltip: Locales.trip.next_steps.registration_transfer.tooltip,
    label: Locales.trip.next_steps.registration_transfer.label,
    color: 'danger',
    value: CONST_INVOICE_REGISTER,
    iconClassName: 'fa-regular fa-file-invoice-dollar',
  },
  {
    tooltip: Locales.trip.next_steps.balance_transfer.tooltip,
    label: Locales.trip.next_steps.balance_transfer.label,
    color: 'danger',
    value: CONST_INVOICE_CART,
    iconClassName: 'fa-regular fa-file-invoice-dollar',
  },
];

const TripsTableRow = ({
  pilgrimData,
  isPlatformLocked,
  isSelected,
  openEditModal,
  openCustomizationModal,
  openMedicalCheckupModal,
  openDepositModal,
  handleOpenRejectionModal,
  handleOpenValidationPassportModal,
  handleDeleteTripModal,
  handleOpenDeclineVisaModal,
}) => {
  const { isDEI, isMS, isMSSecondary, isAGLO, isMAEC, isMAECPrimary, isAgency, user } = useGetUser();
  const hasActionsEnabled = isAgency || isMAEC || isAGLO;

  const {
    id,
    depositTotal,
    cartTotal,
    depositTransferred,
    nextSteps,
    agencyName,
    note,
    firstname,
    lastname,
    npi,
    isNpiValid,
    passport,
    passportExpirationDate,
    isPassportValid,
    birthdate,
    isInternational,
    isHealthy,
    doctorName,
    doctorEmail,
    customizationName,
    customizationApproved,
    categoryName,
    isInvoicePaid,
    isVisaSubmission,
  } = useGetPilgrim(pilgrimData);

  const isCustomizationPending = customizationName.trim().length > 0 && customizationApproved === null;
  const disableTripSelection = isVisaSubmission;
  const disableMedicalCheckup = isMSSecondary && isHealthy !== null && user.email !== doctorEmail;

  const iconCheck = <i className="fa-regular fa-check" />;
  const iconXmark = <i className="fa-regular fa-xmark" />;
  const iconEdit = <i className="fa-regular fa-edit" />;
  const iconCartShopping = <i className="fa-regular fa-cart-shopping" />;
  const iconSackDollar = <i className="fa-regular fa-sack-dollar" />;

  const successIcon = (
    <Icon color="success" small>
      {iconCheck}
    </Icon>
  );

  const dangerIcon = (
    <Icon color="danger" small>
      {iconXmark}
    </Icon>
  );

  const grayIcon = (
    <Icon small color="gray">
      <i className="fa-regular fa-question" />
    </Icon>
  );

  const successIconSackDollar = (
    <Icon color="success" small>
      {iconSackDollar}
    </Icon>
  );

  const internationalTripTooltip = (
    <Tooltip label={Locales.trip.table.tooltips.international_residence}>
      <Icon color="info" small>
        <i className="fa-regular fa-globe" />
      </Icon>
    </Tooltip>
  );

  const passportIconStatus = () => {
    let iconElement;

    if (isPassportValid) {
      iconElement = <Tooltip label={Locales.trip.table.tooltips.passport_verified}>{successIcon}</Tooltip>;
    } else if (isPassportValid === null) {
      iconElement = <Tooltip label={Locales.trip.table.tooltips.pending}>{grayIcon}</Tooltip>;
    } else {
      iconElement = <Tooltip label={Locales.trip.table.tooltips.passport_invalid}>{dangerIcon}</Tooltip>;
    }

    return iconElement;
  };

  const npiIconStatus = () => {
    let iconElement;

    if (isNpiValid) {
      iconElement = <Tooltip label={Locales.trip.table.tooltips.npi_verified}>{successIcon}</Tooltip>;
    } else if (isNpiValid === null) {
      iconElement = <Tooltip label={Locales.trip.table.tooltips.pending}>{grayIcon}</Tooltip>;
    } else {
      iconElement = <Tooltip label={Locales.trip.table.tooltips.npi_invalid}>{dangerIcon}</Tooltip>;
    }

    return iconElement;
  };

  const healthIconStatus = () => {
    let iconElement;

    if (isHealthy) {
      iconElement = (
        <div className="items-group">
          {isMS && (
            <Tooltip label={Locales.trip.table.tooltips.medical_visit_approved}>
              <Icon color="success" small>
                <i className="fa-regular fa-stethoscope" />
              </Icon>
            </Tooltip>
          )}
          {isInternational && internationalTripTooltip}
          {!isInternational && <Tooltip label={Locales.trip.table.tooltips.healthy}>{successIcon}</Tooltip>}
        </div>
      );
    } else if (isHealthy === null) {
      iconElement = (
        <>
          {isInternational && internationalTripTooltip}
          {!isInternational && <Tooltip label={Locales.trip.table.tooltips.health_pending}>{grayIcon}</Tooltip>}
        </>
      );
    } else {
      iconElement = (
        <div className="items-group">
          {isMS && (
            <Tooltip label={Locales.trip.table.tooltips.medical_visit_rejected}>
              <Icon color="danger" small>
                <i className="fa-regular fa-stethoscope" />
              </Icon>
            </Tooltip>
          )}
          {isInternational && internationalTripTooltip}
          {!isInternational && <Tooltip label={Locales.trip.table.tooltips.unhealthy}>{dangerIcon}</Tooltip>}
        </div>
      );
    }

    return iconElement;
  };

  const displayPassport = () => {
    let content = '-';

    if (passport) {
      content = (
        <>
          {passport}
          {passportIconStatus()}
        </>
      );
    }

    return content;
  };

  const displayNpi = () => {
    let content = '-';

    if (npi) {
      content = (
        <>
          {npi}
          {npiIconStatus()}
        </>
      );
    }

    return content;
  };

  const handleKeyDown = (e, callback) => {
    if (e.keyCode === 13) {
      callback(pilgrimData);
    }
  };

  const categoryTagColor = () => {
    let result = 'grey';

    if (categoryName.includes('Premium')) {
      result = '';
    } else if (categoryName.includes('Elite')) {
      result = 'info';
    }

    return result;
  };

  return (
    <Table.Row
      id={id.toString()}
      className={`${style.tablerow} ${disableTripSelection ? style.disabledRow : ''}`}
      selected={isSelected}
      disabled={disableTripSelection}
    >
      <Table.Cell type="thumbnail">&ndash;</Table.Cell>
      <Table.Cell type="text">
        <div className="items-group">{displayPassport()}</div>
      </Table.Cell>

      <Table.Cell type="text">
        <div className="items-group">{displayNpi()}</div>
      </Table.Cell>
      <Table.Cell type="text">{firstname}</Table.Cell>
      <Table.Cell type="text">
        <div className="items-group">
          {lastname.toUpperCase()}
          {isInternational && internationalTripTooltip}
        </div>
      </Table.Cell>

      <Table.Cell type="enum">
        <Tag color={categoryTagColor()}>{categoryName}</Tag>
      </Table.Cell>

      {(isMAEC || isMS || isDEI) && <Table.Cell type="date">{formatDate(birthdate)}</Table.Cell>}

      {(isAGLO || isAgency) && <Table.Cell type="numeric">{formatNumber(depositTotal)}</Table.Cell>}
      {isMAEC && (
        <Table.Cell type="numeric enum">
          <Tooltip label={Locales.trip.table.tooltips.participation_paid}>{successIconSackDollar}</Tooltip>
        </Table.Cell>
      )}

      {(isAgency || isAGLO) && (
        <>
          <Table.Cell type="numeric">{formatNumber(depositTransferred)}</Table.Cell>
          <Table.Cell type="numeric">
            <div className={style['cart-total-group']}>
              {formatNumber(cartTotal)}
              {isInvoicePaid && (
                <Tooltip label={Locales.trip.table.tooltips.participation_paid}>{successIconSackDollar}</Tooltip>
              )}

              {!isInvoicePaid && isCustomizationPending && (
                <Tooltip label={Locales.trip.table.tooltips.customization_pending}>
                  <Icon color="primary" small>
                    {iconCartShopping}
                  </Icon>
                </Tooltip>
              )}
            </div>
          </Table.Cell>
        </>
      )}

      <Table.Cell type="enum">
        <div className="tags-container">
          {nextSteps.map((step) => {
            const matchingStep = PILGRIMS_STEPS.find((steps) => steps.value === step);
            return (
              <div key={`${step}_ID`}>
                <Tooltip label={matchingStep.tooltip}>
                  <div className="items-group">
                    <Tag color={matchingStep.color}>
                      <Icon small>
                        <i className={matchingStep.iconClassName} />
                      </Icon>
                      {matchingStep.label}
                    </Tag>
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </div>
      </Table.Cell>

      {!isAgency && <Table.Cell type="text">{agencyName}</Table.Cell>}

      {isDEI && (
        <>
          <Table.Cell type="text">{passportExpirationDate ? formatDate(passportExpirationDate) : '-'}</Table.Cell>
          <Table.Cell>
            <div className="items-group">
              {!isPassportValid && (
                <>
                  <Button variant="secondary" onClick={() => openEditModal(pilgrimData)} isIconButton>
                    <i className="fa-regular fa-solid fa-pen" />
                  </Button>
                  <Button color="success" onClick={() => handleOpenValidationPassportModal(pilgrimData)} isIconButton>
                    {iconCheck}
                  </Button>
                </>
              )}
              {(isPassportValid || isPassportValid === null) && (
                <Button color="danger" onClick={() => handleOpenRejectionModal(pilgrimData)} isIconButton>
                  {iconXmark}
                </Button>
              )}
            </div>
          </Table.Cell>
        </>
      )}

      {isMS && <Table.Cell type="text">{doctorName}</Table.Cell>}

      {!isDEI && <Table.Cell type="text">{healthIconStatus()}</Table.Cell>}

      {isMS && (
        <Table.Cell type="text">
          <Button
            onClick={() => openMedicalCheckupModal(pilgrimData)}
            variant="secondary"
            isIconButton
            disabled={disableMedicalCheckup}
          >
            <i className="fa-regular fa-stethoscope" />
          </Button>
        </Table.Cell>
      )}

      {(isAgency || isAGLO) && <Table.Cell type="text">{note}</Table.Cell>}

      {hasActionsEnabled && (
        <Table.Cell type="text">
          {isMAECPrimary && disableTripSelection && (
            <Button onClick={() => handleOpenDeclineVisaModal(pilgrimData)} color="danger" isIconButton>
              {iconXmark}
            </Button>
          )}
          <ActionList>
            {!isMAEC && (
              <ActionList.Trigger>
                <Button variant="secondary" isIconButton>
                  <i className="fa-regular fa-ellipsis-vertical" />
                </Button>
              </ActionList.Trigger>
            )}
            <ActionList.Dropdown>
              <ActionList.Group>
                {isAGLO && (
                  <>
                    <ActionList.Item>
                      <div
                        className="action-item"
                        role="button"
                        tabIndex={0}
                        onClick={() => openEditModal(pilgrimData)}
                        onKeyDown={(e) => handleKeyDown(e, openEditModal)}
                      >
                        {iconEdit}
                        <span>{Locales.trip.actionlist.edit}</span>
                      </div>
                    </ActionList.Item>

                    <ActionList.Item>
                      <div
                        className="action-item danger"
                        role="button"
                        tabIndex={0}
                        onClick={() => handleDeleteTripModal(pilgrimData)}
                        onKeyDown={(e) => handleKeyDown(e, handleDeleteTripModal)}
                      >
                        <i className="fa-regular fa-trash" />
                        <span>{Locales.trip.actionlist.delete}</span>
                      </div>
                    </ActionList.Item>
                  </>
                )}

                {isAgency && (
                  <>
                    <LockableActionWrapper
                      isPlatformLocked={isPlatformLocked}
                      callback={() => openDepositModal(pilgrimData)}
                      className={`action-item ${isCustomizationPending ? 'disabled' : ''}`}
                    >
                      <ActionList.Item>
                        <div className="action-item">
                          {iconSackDollar}
                          <span>{Locales.trip.table.actionlist.deposit}</span>
                        </div>
                      </ActionList.Item>
                    </LockableActionWrapper>

                    <LockableActionWrapper
                      isPlatformLocked={isPlatformLocked}
                      callback={() => openCustomizationModal(pilgrimData)}
                    >
                      <ActionList.Item>
                        <div className="action-item">
                          {iconCartShopping}
                          <span>{Locales.trip.table.actionlist.customize}</span>
                        </div>
                      </ActionList.Item>
                    </LockableActionWrapper>

                    <LockableActionWrapper
                      isPlatformLocked={isPlatformLocked}
                      callback={() => openEditModal(pilgrimData)}
                    >
                      <ActionList.Item>
                        <div className="action-item">
                          {iconEdit}
                          <span>{Locales.trip.table.actionlist.update_info}</span>
                        </div>
                      </ActionList.Item>
                    </LockableActionWrapper>
                  </>
                )}
              </ActionList.Group>
            </ActionList.Dropdown>
          </ActionList>
        </Table.Cell>
      )}
    </Table.Row>
  );
};

TripsTableRow.defaultProps = {
  pilgrimData: null,
  isSelected: false,
  isPlatformLocked: false,
  openEditModal: null,
  openCustomizationModal: null,
  openMedicalCheckupModal: null,
  openDepositModal: null,
  handleOpenRejectionModal: null,
  handleOpenValidationPassportModal: null,
  handleDeleteTripModal: null,
  handleOpenDeclineVisaModal: null,
};

TripsTableRow.propTypes = {
  pilgrimData: PropTypes.shape({}),
  isSelected: PropTypes.bool,
  isPlatformLocked: PropTypes.bool,
  openEditModal: PropTypes.func,
  openCustomizationModal: PropTypes.func,
  openMedicalCheckupModal: PropTypes.func,
  openDepositModal: PropTypes.func,
  handleOpenRejectionModal: PropTypes.func,
  handleOpenValidationPassportModal: PropTypes.func,
  handleDeleteTripModal: PropTypes.func,
  handleOpenDeclineVisaModal: PropTypes.func,
};

export default TripsTableRow;
