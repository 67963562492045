import React, { useState } from 'react';
import { Table, Button, ModalProvider, openModal, Icon, Tooltip, showToast } from '@trellixio/roaster-coffee';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI, useGetUser } from 'hooks';
import PropTypes from 'prop-types';
import Locales from 'locales';
import {
  formatDate,
  getUserName,
  hasNextPage,
  hasPreviousPage,
  updateQueryCursor,
  showPagination,
  formatNumber,
} from 'helpers';
import customizationIcon from 'assets/img/sidebar/customization.svg';
import { LockableActionWrapper } from 'components';
import EditCustomizationModal from './EditCustomizationModal';
import style from './CustomizationsTable.module.css';

const CustomizationsTable = ({ tableData, setCursor, limit, isPlatformLocked }) => {
  const { isAgency, isAGLO } = useGetUser();
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const [isEditModalOpened, setEditModalOpened] = useState(false);
  const [selectedCustomizationData, setCustomizationData] = useState({});

  const openEditModal = (customizations) => {
    setCustomizationData(customizations);
    setEditModalOpened((val) => !val);
  };

  const { mutate: deleteCustomization } = useMutation({
    mutationFn: (pilgrimId) => api.post(`trip/${pilgrimId}/customization_reset/`),
    onSuccess: ({ data }) => {
      const { pilgrim } = data;
      const { first_name: firstName, last_name: lastName } = pilgrim;
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      showToast({
        message: Locales.formatString(Locales.customization.toast.customization_deleted, {
          fullname: getUserName(firstName, lastName),
        }),
        level: 'success',
      });
    },
  });

  const { mutate: approveCustomization } = useMutation({
    mutationFn: (pilgrimId) => api.post(`trip/${pilgrimId}/customization_approve/`),
    onSuccess: ({ data }) => {
      const { pilgrim } = data;
      const { first_name: firstName, last_name: lastName } = pilgrim;
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      showToast({
        message: Locales.formatString(Locales.customization.toast.customization_approved, {
          fullname: getUserName(firstName, lastName),
        }),
        level: 'success',
      });
    },
  });

  const handleOpenActivateModal = (pilgrimData) => {
    const { customization, pilgrim } = pilgrimData || {};
    const { name, price } = customization;
    const { first_name: firstname, last_name: lastname } = pilgrim || {};
    openModal({
      title: Locales.customization.customization.activate,
      children: (
        <p>
          {Locales.formatString(Locales.customization.alerts.activate, {
            service: <strong>{name}</strong>,
            price: <strong>{price}</strong>,
            fullname: <strong>{getUserName(firstname, lastname)}</strong>,
          })}
        </p>
      ),
      confirmProps: { children: Locales.base.button.activate, variant: 'primary', color: 'success' },
      cancelProps: { children: Locales.base.button.discard, variant: 'secondary', color: '' },
      onCancel: () => {},
      onConfirm: () => approveCustomization(pilgrimData.id),
    });
  };

  const handleOpenDeleteModal = (pilgrimData) => {
    const { customization, pilgrim } = pilgrimData || {};
    const { name, price } = customization;
    const { first_name: firstname, last_name: lastname } = pilgrim || {};
    openModal({
      title: Locales.customization.customization.delete,
      children: (
        <p>
          {Locales.formatString(Locales.customization.alerts.delete, {
            service: <strong>{name}</strong>,
            price: <strong>{price}</strong>,
            fullname: <strong>{getUserName(firstname, lastname)}</strong>,
          })}
        </p>
      ),
      confirmProps: { children: Locales.base.button.delete, variant: 'primary', color: 'danger' },
      cancelProps: { children: Locales.base.button.discard, variant: 'secondary', color: '' },
      onCancel: () => {},
      onConfirm: () => deleteCustomization(pilgrimData.id),
    });
  };

  return (
    <>
      <ModalProvider>
        <div className={style.table}>
          <Table
            showPagination={showPagination(tableData, limit)}
            hasNextPage={hasNextPage(tableData)}
            hasPreviousPage={hasPreviousPage(tableData)}
            onNextPage={() => updateQueryCursor(tableData.next, setCursor)}
            onPreviousPage={() => updateQueryCursor(tableData.previous, setCursor)}
          >
            <Table.Head>
              <Table.HeadCell type="thumbnail" />
              {isAGLO && (
                <Table.HeadCell type="text">
                  {Locales.customization.field.agency}
                  <Tooltip inline label={Locales.customization.tooltips.agency}>
                    <Icon hasTooltip>
                      <i className="fa-regular fa-circle-info" />
                    </Icon>
                  </Tooltip>
                </Table.HeadCell>
              )}

              <Table.HeadCell type="text">{Locales.customization.field.pilgrim}</Table.HeadCell>
              <Table.HeadCell type="date">{Locales.customization.field.birthDate}</Table.HeadCell>
              <Table.HeadCell type="text">{Locales.customization.field.service}</Table.HeadCell>
              <Table.HeadCell type="paragraph">{Locales.customization.field.description}</Table.HeadCell>
              <Table.HeadCell type="numeric">{Locales.customization.field.price}</Table.HeadCell>
              <Table.HeadCell type="enum">
                {Locales.customization.field.status}
                <Tooltip inline label={Locales.customization.tooltips.status}>
                  <Icon hasTooltip>
                    <i className="fa-regular fa-circle-info" />
                  </Icon>
                </Tooltip>
              </Table.HeadCell>
              <Table.HeadCell type="object">{Locales.customization.field.actions}</Table.HeadCell>
            </Table.Head>

            {tableData && tableData.length > 0 && (
              <Table.Body>
                {tableData.map((data, key) => (
                  <Table.Row key={data.id} id={key}>
                    <Table.Cell type="thumbnail">
                      <Icon>
                        <img src={customizationIcon} alt="" />
                      </Icon>
                    </Table.Cell>

                    {isAGLO && <Table.Cell type="text">{data.agency_name}</Table.Cell>}
                    <Table.Cell type="text">
                      {getUserName(data.pilgrim.first_name, data.pilgrim.last_name.toUpperCase())}
                    </Table.Cell>
                    <Table.Cell type="text">{formatDate(data.pilgrim.birthdate)}</Table.Cell>
                    <Table.Cell type="text">{data.customization.name}</Table.Cell>
                    <Table.Cell type="paragraph">{data.customization.description}</Table.Cell>
                    <Table.Cell type="numeric">{formatNumber(data.customization.price)}</Table.Cell>
                    <Table.Cell type="text">
                      {data.customization.approved ? (
                        <Icon small color="success">
                          <i className="fa-solid fa-check" />
                        </Icon>
                      ) : (
                        <Icon small className="gray">
                          <i className="fa-solid fa-question" />
                        </Icon>
                      )}
                    </Table.Cell>
                    <Table.Cell type="text">
                      {isAGLO && !data.customization.approved && (
                        <div className="items-group">
                          <Button color="success" isIconButton onClick={() => handleOpenActivateModal(data)}>
                            <i className="fa-regular fa-solid fa-check" />
                          </Button>

                          <Button variant="secondary" isIconButton onClick={() => openEditModal(data)}>
                            <i className="fa-regular fa-solid fa-pen" />
                          </Button>

                          <Button
                            variant="secondary"
                            color="danger"
                            isIconButton
                            onClick={() => handleOpenDeleteModal(data)}
                          >
                            <i className="fa-regular fa-solid fa-trash" />
                          </Button>
                        </div>
                      )}

                      {isAGLO && data.customization.approved && (
                        <div className="items-group">
                          <Button
                            variant="secondary"
                            color="danger"
                            isIconButton
                            onClick={() => handleOpenDeleteModal(data)}
                          >
                            <i className="fa-regular fa-solid fa-trash" />
                          </Button>
                        </div>
                      )}

                      {isAgency && !data.customization.approved && (
                        <div className="items-group">
                          <LockableActionWrapper
                            isPlatformLocked={isPlatformLocked}
                            callback={() => openEditModal(data)}
                          >
                            <Button variant="secondary" isIconButton>
                              <i className="fa-regular fa-solid fa-pen" />
                            </Button>
                          </LockableActionWrapper>

                          <LockableActionWrapper
                            isPlatformLocked={isPlatformLocked}
                            callback={() => handleOpenDeleteModal(data)}
                          >
                            <Button variant="secondary" color="danger" isIconButton>
                              <i className="fa-regular fa-solid fa-trash" />
                            </Button>
                          </LockableActionWrapper>
                        </div>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>
        </div>
      </ModalProvider>
      {Object.keys(selectedCustomizationData).length > 0 && (
        <div>
          {isEditModalOpened && (
            <EditCustomizationModal
              isOpened={isEditModalOpened}
              pilgrimData={selectedCustomizationData}
              closeModal={() => setEditModalOpened(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

CustomizationsTable.defaultProps = {
  tableData: null,
  setCursor: null,
  limit: null,
  isPlatformLocked: false,
};

CustomizationsTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  setCursor: PropTypes.func,
  limit: PropTypes.number,
  isPlatformLocked: PropTypes.bool,
};

export default CustomizationsTable;
