import React from 'react';
import { Button, Alert } from '@trellixio/roaster-coffee';
import { useIsMutating } from '@tanstack/react-query';
import Locales from 'locales';
import PropTypes from 'prop-types';

const PlatformUnlocked = ({ handleOpenConfirmationModal }) => {
  const isMutating = useIsMutating() > 0;

  return (
    <>
      <Alert message={Locales.campaign.settings.alert.unlock_platform} level="info" />

      <p>
        {Locales.formatString(Locales.campaign.settings.first_description_lock, {
          readOnly: <strong>{Locales.campaign.settings.read_only}</strong>,
        })}
      </p>

      <p>{Locales.campaign.settings.second_description_lock}</p>

      <Button variant="primary" color="danger" disabled={isMutating} onClick={handleOpenConfirmationModal}>
        {Locales.campaign.settings.button.lock_platform}
      </Button>
    </>
  );
};

PlatformUnlocked.defaultProps = {
  handleOpenConfirmationModal: null,
};

PlatformUnlocked.propTypes = {
  handleOpenConfirmationModal: PropTypes.func,
};

export default PlatformUnlocked;
