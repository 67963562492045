import LocalizedStrings from 'react-localization';
import trip from './pages/trip.json';
import authorization from './pages/authorization.json';
import dashboard from './pages/dashboard.json';
import user from './pages/user.json';
import deposit from './pages/deposit.json';
import customization from './pages/customization.json';
import invoice from './pages/invoice.json';
import agency from './pages/agency.json';
import apikey from './pages/apikey.json';
import auth from './pages/auth.json';
import legal from './pages/legal.json';
import cgu from './pages/cgu.json';
import cookie from './pages/cookie.json';
import campaign from './pages/campaign.json';
import base from './base.json';

const fr = {
  trip,
  authorization,
  dashboard,
  user,
  deposit,
  base,
  customization,
  invoice,
  agency,
  apikey,
  auth,
  legal,
  cgu,
  cookie,
  campaign,
};

const Locales = new LocalizedStrings({ fr });

export default Locales;
