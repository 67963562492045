import React, { useMemo, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { PermissionsContext } from 'context';
import { useAPI, useGetUser } from 'hooks';
import { EmptyTable, LockedPlatformAlert } from 'components';
import Locales from 'locales';
import { Page, Select, DropdownCard, Button, BlockCard, Tag } from '@trellixio/roaster-coffee';
import CustomizationsTable from './components/CustomizationsTable';

const CONST_NONE = 'none';
const CONST_FILTER_LABEL_AGENCY = 'agency';
const CONST_TRIP_WITH_CUSTOMIZATION = 'customization_yes';

const Customization = () => {
  const { isPlatformLocked } = useContext(PermissionsContext);
  const { isAGLO, isAgency } = useGetUser();
  const [queryFilters, setQueryFilter] = useState(null);
  const [cursor, setCursor] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const preSetAgency = isAGLO ? searchParams.get(CONST_FILTER_LABEL_AGENCY) : null;

  const [selectedAgency, setAgency] = useState(preSetAgency || CONST_NONE);

  const { api } = useAPI();
  const limit = 30;

  const fetchParams = { ...queryFilters, limit, cursor, customization: CONST_TRIP_WITH_CUSTOMIZATION };

  const { data: customizations } = useQuery({
    queryFn: api.list('trip/', fetchParams),
    queryKey: ['trip/', queryFilters, limit, cursor],
  });

  const { data: agencies } = useQuery({
    queryFn: api.list('agency/', { limit: 100 }),
    queryKey: ['agency/'],
    enabled: isAGLO,
  });

  const resultString =
    customizations?.count > 1
      ? `${customizations?.count} ${Locales.customization.title.toLowerCase()}`
      : `${customizations?.count || 0} ${Locales.customization.title_singular}`;

  const selectedAgencyName = () => {
    let name = null;
    if (selectedAgency !== CONST_NONE) {
      agencies.forEach((data) => {
        if (data.id === selectedAgency) name = data.name;
      });
    }

    return name;
  };

  const applyFilters = () => {
    let filters = null;

    if (selectedAgency !== CONST_NONE) {
      filters = { [CONST_FILTER_LABEL_AGENCY]: selectedAgency };
    }

    setQueryFilter(filters);
  };

  const resetFilters = () => {
    setAgency(CONST_NONE);
    setQueryFilter(null);
  };

  useMemo(() => {
    // set value if in URL and allow the filter to be applied only if the user have the right to apply it
    if (preSetAgency !== CONST_NONE) {
      applyFilters();
    }
  }, []);

  useMemo(() => {
    if (preSetAgency === null) {
      resetFilters();
    }
  }, [preSetAgency]);

  useMemo(() => {
    let params = {};

    // update URL each time the filter is selected
    if (selectedAgency !== CONST_NONE) {
      params = { [CONST_FILTER_LABEL_AGENCY]: selectedAgency };
    }

    if (params) {
      setSearchParams(params);
    }
  }, [selectedAgency]);

  return (
    <Page title={Locales.customization.title} size="full">
      {isPlatformLocked && isAgency && <LockedPlatformAlert />}
      <section className="table-form">
        <form>
          <div className="table-actions items-group">
            <div />
            {isAGLO && (
              <DropdownCard>
                <DropdownCard.Trigger>
                  <Button variant="secondary">
                    <i className="fa-solid fa-regular fa-bars-filter" /> {Locales.base.dropdown.filter}
                  </Button>
                </DropdownCard.Trigger>

                <DropdownCard.Dropdown width="large">
                  <DropdownCard.Content>
                    <form>
                      <div className="content-row">
                        <p className="group-label">{Locales.base.dropdown.agreed_agency}</p>
                        <Select
                          defaultValue={selectedAgency}
                          helpText={Locales.base.dropdown.agreed_agency_helptext}
                          data={[{ label: Locales.base.button.select, value: CONST_NONE, disabled: true }].concat(
                            agencies
                          )}
                          onChange={setAgency}
                        />
                      </div>
                    </form>
                  </DropdownCard.Content>
                  <DropdownCard.Actions>
                    <Button variant="inline" onClick={resetFilters}>
                      {Locales.base.button.reset}
                    </Button>
                    <Button color="success" onClick={applyFilters}>
                      {Locales.base.button.validate}
                    </Button>
                  </DropdownCard.Actions>
                </DropdownCard.Dropdown>
              </DropdownCard>
            )}
          </div>
        </form>
      </section>
      <BlockCard>
        <div className="table-meta items-group">
          <div>
            <span>{Locales.base.result} : </span>
            <strong>{resultString}</strong>
          </div>

          <div className="tags-container">
            {queryFilters && Object.prototype.hasOwnProperty.call(queryFilters, CONST_FILTER_LABEL_AGENCY) && (
              <>
                <span>|</span>
                <Tag onRemove={() => resetFilters()}>{`${
                  Locales.invoice.dropdown.agreed_agency
                }: ${selectedAgencyName()}`}</Tag>
              </>
            )}
          </div>
        </div>
      </BlockCard>

      {customizations?.count > 0 && (
        <CustomizationsTable
          limit={limit}
          setCursor={setCursor}
          tableData={customizations}
          isPlatformLocked={isPlatformLocked}
        />
      )}

      {customizations?.count === 0 && <EmptyTable isSearchResult={queryFilters} />}
    </Page>
  );
};

export default Customization;
