import React, { useState } from 'react';
import {
  Table,
  Button,
  useSelectableResourceState,
  Tooltip,
  Icon,
  ModalProvider,
  showToast,
  openModal,
} from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { hasNextPage, hasPreviousPage, updateQueryCursor, showPagination, getUserName } from 'helpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI, useGetUser } from 'hooks';
import Locales from 'locales';
import CustomizationModal from 'pages/Trip/components/CustomizationModal';
import TripsTableRow from './TripsTableRow';
import EditTripModal from './EditTripModal';
import MedicalCheckupModal from './MedicalCheckupModal';
import DepositModal from './DepositModal';
import style from './Trips.module.css';

const TripsTable = ({
  tableData,
  setCursor,
  limit,
  registrationDepot,
  preferencesId,
  tableRef,
  isPlatformLocked,
  categories,
  openCategoriesModal,
}) => {
  const { isAGLO, isAgency, isMAEC, isMS, isDEI } = useGetUser();
  const hasActionsEnabled = isAGLO || isAgency || isMAEC;
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const { selectedResources, handleSelectionChange, allResourcesSelected, clearSelection } =
    useSelectableResourceState(tableData);

  const isMutating = queryClient.isMutating() > 0;

  const [isEditModalOpened, setEditModalOpened] = useState(false);
  const [isCustomizationModalOpened, setCustomizationModalOpened] = useState(false);
  const [isMedicalCheckupModalOpened, setMedicalCheckupModalOpened] = useState(false);
  const [isDepositModalOpened, setDepositModalOpened] = useState(false);
  const [selectedPilgrimData, setPilgrimData] = useState({});

  const openEditModal = (pilgrimData) => {
    setPilgrimData(pilgrimData);
    setEditModalOpened((val) => !val);
  };

  const openCustomizationModal = (pilgrimData) => {
    setPilgrimData(pilgrimData);
    setCustomizationModalOpened((val) => !val);
  };

  const openMedicalCheckupModal = (pilgrimData) => {
    setPilgrimData(pilgrimData);
    setMedicalCheckupModalOpened((val) => !val);
  };

  const openDepositModal = (pilgrimData) => {
    setPilgrimData(pilgrimData);
    setDepositModalOpened((val) => !val);
  };

  const { mutate: approveVisaSubmission } = useMutation({
    mutationFn: () => api.post('trip/visa_submission_approve/', selectedResources),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/default/'] });
      showToast({
        message: Locales.trip.toast.visa_request_approved,
        level: 'success',
      });
      clearSelection();
    },
  });

  const { mutate: declineVisaSubmission } = useMutation({
    mutationFn: (id) => api.post(`trip/${id}/visa_submission_cancel/`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/default/'] });
      showToast({
        message: Locales.trip.toast.visa_request_declined,
        level: 'success',
      });
    },
  });

  const { mutate: approvePassport } = useMutation({
    mutationFn: (id) => api.post(`trip/${id}/passport_approve/`),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/default/'] });
      showToast({
        message: Locales.formatString(Locales.trip.toast.passport_approved, {
          fullname: getUserName(data.pilgrim.first_name, data.pilgrim.last_name),
        }),
        level: 'success',
      });
    },
  });

  const { mutate: declinePassport } = useMutation({
    mutationFn: (id) => api.post(`trip/${id}/passport_decline/`),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/default/'] });
      showToast({
        message: Locales.formatString(Locales.trip.toast.passport_rejected, {
          fullname: getUserName(data.pilgrim.first_name, data.pilgrim.last_name),
        }),
        level: 'success',
      });
    },
  });

  const { mutate: deleteTrip } = useMutation({
    mutationFn: (id) => api.remove('trip', id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/default/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/category/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/trip_category_paid/'] });
      showToast({
        message: Locales.formatString(Locales.trip.toast.delete),
        level: 'success',
      });
    },
  });

  const handleOpenValidationPassportModal = (pilgrimData) => {
    openModal({
      title: Locales.trip.modal.title.validation_passport,
      children: (
        <p>
          {Locales.formatString(Locales.trip.modal.text.validate_passport, {
            passport: <strong>{pilgrimData.pilgrim.passport}</strong>,
            fullname: <strong>{getUserName(pilgrimData.pilgrim.first_name, pilgrimData.pilgrim.last_name)}</strong>,
          })}
        </p>
      ),
      confirmProps: { children: Locales.base.button.validate },
      cancelProps: { children: Locales.base.button.cancel, variant: 'secondary', color: '' },
      onCancel: () => {},
      onConfirm: () => approvePassport(pilgrimData.id),
    });
  };

  const handleOpenRejectionModal = (pilgrimData) => {
    openModal({
      title: Locales.trip.modal.title.passport_rejection,
      children: (
        <p>
          {Locales.formatString(Locales.trip.modal.text.reject_passport, {
            passport: <strong>{pilgrimData.pilgrim.passport}</strong>,
            fullname: <strong>{getUserName(pilgrimData.pilgrim.first_name, pilgrimData.pilgrim.last_name)}</strong>,
          })}
        </p>
      ),
      confirmProps: { children: Locales.trip.button.reject, variant: 'primary', color: 'danger' },
      cancelProps: { children: Locales.base.button.cancel, variant: 'secondary', color: '' },
      onCancel: () => {},
      onConfirm: () => declinePassport(pilgrimData.id),
    });
  };

  const handleDeleteTripModal = (pilgrimData) => {
    openModal({
      title: Locales.trip.modal.title.delete_trip,
      children: (
        <p>
          {Locales.formatString(Locales.trip.modal.text.delete_trip, {
            fullname: <strong>{getUserName(pilgrimData.pilgrim.first_name, pilgrimData.pilgrim.last_name)}</strong>,
          })}
        </p>
      ),
      confirmProps: { children: Locales.base.button.delete, variant: 'primary', color: 'danger' },
      cancelProps: { children: Locales.base.button.cancel, variant: 'secondary', color: '' },
      onCancel: () => {},
      onConfirm: () => deleteTrip(pilgrimData.id),
    });
  };

  const handleOpenDeclineVisaModal = (pilgrimData) => {
    openModal({
      title: Locales.trip.decline_visa_modal_title,
      children: (
        <p>
          {Locales.formatString(Locales.trip.decline_visa_modal_text, {
            passport: <strong>{pilgrimData.pilgrim.passport}</strong>,
            fullname: <strong>{getUserName(pilgrimData.pilgrim.first_name, pilgrimData.pilgrim.last_name)}</strong>,
          })}
        </p>
      ),
      confirmProps: { children: Locales.trip.button.cancel_reception, variant: 'primary', color: 'danger' },
      cancelProps: { children: Locales.base.button.discard, variant: 'secondary', color: '' },
      onCancel: () => {},
      onConfirm: () => declineVisaSubmission(pilgrimData.id),
    });
  };

  return (
    <ModalProvider modalProps={{ size: 'small' }}>
      <div className={style.table} ref={tableRef}>
        <Table
          showPagination={showPagination(tableData, limit)}
          hasNextPage={hasNextPage(tableData)}
          hasPreviousPage={hasPreviousPage(tableData)}
          onNextPage={() => updateQueryCursor(tableData.next, setCursor)}
          onPreviousPage={() => updateQueryCursor(tableData.previous, setCursor)}
          itemCount={tableData ? tableData.count : 0}
          selectable={isMAEC}
          selectedItemsCount={allResourcesSelected ? Locales.base.all : selectedResources.length}
          onSelectionChange={handleSelectionChange}
          selectActions={
            <Button variant="secondary" onClick={approveVisaSubmission} disabled={isMutating}>
              {Locales.base.button.receive}
            </Button>
          }
        >
          <Table.Head>
            <Table.HeadCell type="thumbnail" />
            <Table.HeadCell type="text">{Locales.trip.table.passport}</Table.HeadCell>
            <Table.HeadCell type="text">{Locales.trip.table.npi}</Table.HeadCell>
            <Table.HeadCell type="text">{Locales.trip.table.firstname}</Table.HeadCell>
            <Table.HeadCell type="text">
              {Locales.trip.table.lastname}
              <Tooltip inline label={Locales.trip.table.tooltips.lastname}>
                <Icon hasTooltip>
                  <i className="fa-regular fa-circle-info" />
                </Icon>
              </Tooltip>
            </Table.HeadCell>

            <Table.HeadCell type="enum">
              {Locales.trip.table.categorie}
              <Tooltip inline label={Locales.trip.table.tooltips.categorie}>
                <Icon hasTooltip>
                  <i className="fa-regular fa-circle-info" />
                </Icon>
              </Tooltip>
            </Table.HeadCell>

            {(isMAEC || isMS || isDEI) && (
              <Table.HeadCell type="date">
                {Locales.trip.table.birthdate}
                <Tooltip inline label={Locales.trip.table.tooltips.birthdate}>
                  <Icon hasTooltip>
                    <i className="fa-regular fa-circle-info" />
                  </Icon>
                </Tooltip>
              </Table.HeadCell>
            )}

            {(isAGLO || isAgency) && (
              <Table.HeadCell type="numeric">
                {Locales.trip.table.deposits}
                <Tooltip inline label={Locales.trip.table.tooltips.deposits}>
                  <Icon hasTooltip>
                    <i className="fa-regular fa-circle-info" />
                  </Icon>
                </Tooltip>
              </Table.HeadCell>
            )}

            {(isAGLO || isAgency || isMAEC) && (
              <Table.HeadCell type="numeric">
                {Locales.trip.table.bank}
                <Tooltip inline label={Locales.trip.table.tooltips.bank}>
                  <Icon hasTooltip>
                    <i className="fa-regular fa-circle-info" />
                  </Icon>
                </Tooltip>
              </Table.HeadCell>
            )}

            {(isAGLO || isAgency) && (
              <Table.HeadCell type="numeric">
                {Locales.trip.table.total_fees}
                <Tooltip inline label={Locales.trip.table.tooltips.total_fees}>
                  <Icon hasTooltip>
                    <i className="fa-regular fa-circle-info" />
                  </Icon>
                </Tooltip>
              </Table.HeadCell>
            )}

            <Table.HeadCell type="enum">
              {Locales.trip.table.next_step}
              <Tooltip inline label={Locales.trip.table.tooltips.next_step}>
                <Icon hasTooltip>
                  <i className="fa-regular fa-circle-info" />
                </Icon>
              </Tooltip>
            </Table.HeadCell>

            {!isAgency && (
              <Table.HeadCell type="text">
                {Locales.trip.table.company}
                <Tooltip inline label={Locales.trip.table.tooltips.company}>
                  <Icon hasTooltip>
                    <i className="fa-regular fa-circle-info" />
                  </Icon>
                </Tooltip>
              </Table.HeadCell>
            )}

            {isDEI && (
              <>
                <Table.HeadCell type="text">
                  {Locales.trip.table.passport_expiration}
                  <Tooltip inline label={Locales.trip.table.tooltips.passport_expiration}>
                    <Icon hasTooltip>
                      <i className="fa-regular fa-circle-info" />
                    </Icon>
                  </Tooltip>
                </Table.HeadCell>
                <Table.HeadCell type="text">
                  {Locales.trip.table.passport_validation}
                  <Tooltip inline label={Locales.trip.table.tooltips.passport_validation}>
                    <Icon hasTooltip>
                      <i className="fa-regular fa-circle-info" />
                    </Icon>
                  </Tooltip>
                </Table.HeadCell>
              </>
            )}

            {isMS && (
              <Table.HeadCell type="text">
                {Locales.trip.table.doctor}
                <Tooltip inline label={Locales.trip.table.tooltips.doctor}>
                  <Icon hasTooltip>
                    <i className="fa-regular fa-circle-info" />
                  </Icon>
                </Tooltip>
              </Table.HeadCell>
            )}

            {!isDEI && (
              <Table.HeadCell type="text">
                {Locales.trip.table.health}
                <Tooltip inline label={Locales.trip.table.tooltips.health}>
                  <Icon hasTooltip>
                    <i className="fa-regular fa-circle-info" />
                  </Icon>
                </Tooltip>
              </Table.HeadCell>
            )}

            {isMS && (
              <Table.HeadCell type="text">
                {Locales.trip.table.medical_visit}
                <Tooltip inline label={Locales.trip.table.tooltips.medical_visit}>
                  <Icon hasTooltip>
                    <i className="fa-regular fa-circle-info" />
                  </Icon>
                </Tooltip>
              </Table.HeadCell>
            )}

            {(isAGLO || isAgency) && <Table.HeadCell type="text">{Locales.trip.table.note}</Table.HeadCell>}
            {hasActionsEnabled && <Table.HeadCell type="object">{Locales.trip.table.actions}</Table.HeadCell>}
          </Table.Head>

          {tableData && tableData.length > 0 && (
            <Table.Body>
              {tableData.map((data) => (
                <TripsTableRow
                  key={data.id}
                  pilgrimData={data}
                  isPlatformLocked={isPlatformLocked}
                  isSelected={selectedResources.includes(data.id.toString())}
                  registrationDeposit={registrationDepot}
                  openEditModal={openEditModal}
                  openCustomizationModal={openCustomizationModal}
                  openMedicalCheckupModal={openMedicalCheckupModal}
                  openDepositModal={openDepositModal}
                  handleOpenRejectionModal={handleOpenRejectionModal}
                  handleOpenValidationPassportModal={handleOpenValidationPassportModal}
                  handleDeleteTripModal={handleDeleteTripModal}
                  handleOpenDeclineVisaModal={handleOpenDeclineVisaModal}
                />
              ))}
            </Table.Body>
          )}
        </Table>
      </div>

      {Object.keys(selectedPilgrimData).length > 0 && (
        <div>
          {isEditModalOpened && (
            <EditTripModal
              isOpened={isEditModalOpened}
              pilgrimData={selectedPilgrimData}
              categories={categories}
              registrationDeposit={registrationDepot}
              openCategoriesModal={openCategoriesModal}
              closeModal={() => setEditModalOpened(false)}
            />
          )}

          {isCustomizationModalOpened && (
            <CustomizationModal
              isOpened={isCustomizationModalOpened}
              pilgrimData={selectedPilgrimData}
              closeModal={() => setCustomizationModalOpened(false)}
            />
          )}

          {isMedicalCheckupModalOpened && (
            <MedicalCheckupModal
              isOpened={isMedicalCheckupModalOpened}
              pilgrimData={selectedPilgrimData}
              closeModal={() => setMedicalCheckupModalOpened(false)}
            />
          )}

          {isDepositModalOpened && (
            <DepositModal
              isOpened={isDepositModalOpened}
              pilgrimData={selectedPilgrimData}
              advanceDeposit={registrationDepot}
              preferencesId={preferencesId}
              closeModal={() => setDepositModalOpened(false)}
            />
          )}
        </div>
      )}
    </ModalProvider>
  );
};

TripsTable.defaultProps = {
  tableData: null,
  setCursor: null,
  limit: null,
  registrationDepot: null,
  preferencesId: null,
  tableRef: null,
  isPlatformLocked: false,
  categories: null,
  openCategoriesModal: false,
};

TripsTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  setCursor: PropTypes.func,
  limit: PropTypes.number,
  registrationDepot: PropTypes.number,
  preferencesId: PropTypes.string,
  tableRef: PropTypes.shape({}),
  isPlatformLocked: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  openCategoriesModal: PropTypes.func,
};

export default TripsTable;
