import React from 'react';
import { Navigate } from 'react-router-dom';
import { useGetUser } from 'hooks';
import { Urls } from 'data';

const LandingPage = () => {
  const { isConnected, isSuperAdmin, isBank } = useGetUser();

  let result = <Navigate to={Urls.front.login} replace />;

  if (isConnected) {
    if (isSuperAdmin || isBank) {
      result = <Navigate to={Urls.user} replace />;
    } else {
      result = <Navigate to={Urls.trip} replace />;
    }
  }

  return result;
};

export default LandingPage;
