import React, { useState, useMemo, Fragment } from 'react';
import { Button, DropdownCard, TextField, BlockCard, Tag } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { FilterSelect } from 'components';
import { preventSubmitWithENTER } from 'helpers';
import { useGetUser } from 'hooks';
import Locales from 'locales';

// Constants for filter labels and values used in trip filtering
const CONST_NONE = 'none';
const CONST_FILTER_LABEL_AGENCY = 'agency';
const CONST_FILTER_LABEL_DEPOSIT_TRANSFERRED = 'deposit_transferred';
const CONST_FILTER_VALUE_DEPOSIT_EMPTY = 'deposit_empty';
const CONST_FILTER_VALUE_DEPOSIT_PARTIAL = 'deposit_partial';
const CONST_FILTER_VALUE_DEPOSIT_FULL = 'deposit_full';
const CONST_FILTER_LABEL_VISA = 'visa';
const CONST_FILTER_VALUE_VISA_PENDING = 'visa_pending';
const CONST_FILTER_VALUE_VISA_SUBMITTED = 'visa_submitted';
const CONST_FILTER_VALUE_VISA_GRANTED = 'visa_granted';
const CONST_FILTER_VALUE_VISA_REJECTED = 'visa_rejected';
const CONST_FILTER_LABEL_NPI = 'npi';
const CONST_FILTER_VALUE_NPI_PENDING = 'npi_pending';
const CONST_FILTER_VALUE_NPI_GRANTED = 'npi_granted';
const CONST_FILTER_VALUE_NPI_REJECTED = 'npi_rejected';
const CONST_FILTER_LABEL_PASSPORT = 'passport';
const CONST_FILTER_VALUE_PASSPORT_PENDING = 'passport_pending';
const CONST_FILTER_VALUE_PASSPORT_GRANTED = 'passport_granted';
const CONST_FILTER_VALUE_PASSPORT_REJECTED = 'passport_rejected';
const CONST_FILTER_LABEL_INTERNATIONAL = 'international';
const CONST_FILTER_VALUE_INTERNATIONAL_YES = 'international_yes';
const CONST_FILTER_VALUE_INTERNATIONAL_NO = 'international_no';
const CONST_FILTER_LABEL_HEALTH = 'health';
const CONST_FILTER_VALUE_HEALTH_PENDING = 'health_pending';
const CONST_FILTER_VALUE_HEALTH_GRANTED = 'health_granted';
const CONST_FILTER_VALUE_HEALTH_REJECTED = 'health_rejected';
const CONST_FILTER_LABEL_CATEGORY = 'category';

const TripFilters = ({
  agencies,
  trips,
  categories,
  queryFilters,
  setQueryFilter,
  searchParams,
  setSearchParams,
  searchQuery,
  setSearchQuery,
  preSetSearch,
}) => {
  const { isAgency, isMAEC, isDEI, isMS, isAGLO } = useGetUser();

  const preSetAgency = !isAgency && !isDEI ? searchParams.get(CONST_FILTER_LABEL_AGENCY) : null;
  const preSetPaymentStatus = isAgency || isAGLO ? searchParams.get(CONST_FILTER_LABEL_DEPOSIT_TRANSFERRED) : null;
  const preSetVisaStatus = !isDEI && !isMS ? searchParams.get(CONST_FILTER_LABEL_VISA) : null;
  const preSetNpiStatus = isAgency || isAGLO ? searchParams.get(CONST_FILTER_LABEL_NPI) : null;
  const preSetPassportStatus = !isMS && !isMAEC ? searchParams.get(CONST_FILTER_LABEL_PASSPORT) : null;
  const preSetIsInternational = !isDEI ? searchParams.get(CONST_FILTER_LABEL_INTERNATIONAL) : null;
  const preSetMedicalVisitStatus = !isDEI ? searchParams.get(CONST_FILTER_LABEL_HEALTH) : null;
  const preSetCategory = !isDEI ? searchParams.get(CONST_FILTER_LABEL_CATEGORY) : null;

  const [selectedAgency, setAgency] = useState(preSetAgency || CONST_NONE);
  const [selectedPaymentStatus, setPaymentStatus] = useState(preSetPaymentStatus || CONST_NONE);
  const [selectedVisaStatus, setVisaStatus] = useState(preSetVisaStatus || CONST_NONE);
  const [selectedNpiStatus, setNpiStatus] = useState(preSetNpiStatus || CONST_NONE);
  const [selectedPassportStatus, setPassportStatus] = useState(preSetPassportStatus || CONST_NONE);
  const [selectedIsInternational, setIsInternational] = useState(preSetIsInternational || CONST_NONE);
  const [selectedMedicalVisitStatus, setMedicalVisitStatus] = useState(preSetMedicalVisitStatus || CONST_NONE);
  const [selectedCategory, setCategory] = useState(preSetCategory || CONST_NONE);

  const resultString =
    trips?.count > 1
      ? `${trips?.count} ${Locales.trip.title.toLowerCase()}`
      : `${trips?.count || 0} ${Locales.trip.title.slice(0, -1).toLowerCase()}`;

  const selectableListDefaultOption = [{ label: Locales.base.button.select, value: CONST_NONE, disabled: true }];

  const paymentStatus = [
    { label: Locales.trip.filter.payment_statut.deposit_empty, value: CONST_FILTER_VALUE_DEPOSIT_EMPTY },
    { label: Locales.trip.filter.payment_statut.deposit_partial, value: CONST_FILTER_VALUE_DEPOSIT_PARTIAL },
    { label: Locales.trip.filter.payment_statut.deposit_full, value: CONST_FILTER_VALUE_DEPOSIT_FULL },
  ];

  const visaStatus = [
    { label: Locales.trip.filter.visa_statut.visa_pending, value: CONST_FILTER_VALUE_VISA_PENDING },
    { label: Locales.trip.filter.visa_statut.visa_submitted, value: CONST_FILTER_VALUE_VISA_SUBMITTED },
    { label: Locales.trip.filter.visa_statut.visa_granted, value: CONST_FILTER_VALUE_VISA_GRANTED },
    { label: Locales.trip.filter.visa_statut.visa_rejected, value: CONST_FILTER_VALUE_VISA_REJECTED },
  ];

  const npiStatus = [
    { label: Locales.trip.filter.npi_statut.npi_pending, value: CONST_FILTER_VALUE_NPI_PENDING },
    { label: Locales.trip.filter.npi_statut.npi_granted, value: CONST_FILTER_VALUE_NPI_GRANTED },
    { label: Locales.trip.filter.npi_statut.npi_rejected, value: CONST_FILTER_VALUE_NPI_REJECTED },
  ];

  const passportStatus = [
    { label: Locales.trip.filter.passport_statut.passport_pending, value: CONST_FILTER_VALUE_PASSPORT_PENDING },
    { label: Locales.trip.filter.passport_statut.passport_granted, value: CONST_FILTER_VALUE_PASSPORT_GRANTED },
    { label: Locales.trip.filter.passport_statut.passport_rejected, value: CONST_FILTER_VALUE_PASSPORT_REJECTED },
  ];

  const internationalTrip = [
    { label: Locales.trip.filter.international_trip.international_yes, value: CONST_FILTER_VALUE_INTERNATIONAL_YES },
    { label: Locales.trip.filter.international_trip.international_no, value: CONST_FILTER_VALUE_INTERNATIONAL_NO },
  ];

  const medicalVisitStatus = [
    { label: Locales.trip.filter.medical_visit_status.health_pending, value: CONST_FILTER_VALUE_HEALTH_PENDING },
    { label: Locales.trip.filter.medical_visit_status.health_granted, value: CONST_FILTER_VALUE_HEALTH_GRANTED },
    { label: Locales.trip.filter.medical_visit_status.health_rejected, value: CONST_FILTER_VALUE_HEALTH_REJECTED },
  ];

  const selectedAgencyName = () => {
    let name = null;
    if (selectedAgency !== CONST_NONE) {
      agencies?.forEach((data) => {
        if (data.id === selectedAgency) name = data.name;
      });
    }

    return name;
  };

  const selectedPaymentStatusName = () => {
    let label = null;
    if (selectedPaymentStatus !== CONST_NONE) {
      paymentStatus.forEach((data) => {
        if (data.value === selectedPaymentStatus) label = data.label;
      });
    }

    return label;
  };

  const selectedVisaStatusName = () => {
    let label = null;
    if (selectedVisaStatus !== CONST_NONE) {
      visaStatus.forEach((data) => {
        if (data.value === selectedVisaStatus) label = data.label;
      });
    }

    return label;
  };

  const selectedNpiStatusName = () => {
    let label = null;
    if (selectedNpiStatus !== CONST_NONE) {
      npiStatus.forEach((data) => {
        if (data.value === selectedNpiStatus) label = data.label;
      });
    }

    return label;
  };

  const selectedPassportStatusName = () => {
    let label = null;
    if (selectedPassportStatus !== CONST_NONE) {
      passportStatus.forEach((data) => {
        if (data.value === selectedPassportStatus) label = data.label;
      });
    }

    return label;
  };

  const selectedIsInternationalTrip = () => {
    let label = null;
    if (selectedIsInternational !== CONST_NONE) {
      internationalTrip.forEach((data) => {
        if (data.value === selectedIsInternational) label = data.label;
      });
    }

    return label;
  };

  const selectedMedicalVisitStatusName = () => {
    let label = null;
    if (selectedMedicalVisitStatus !== CONST_NONE) {
      medicalVisitStatus.forEach((data) => {
        if (data.value === selectedMedicalVisitStatus) label = data.label;
      });
    }

    return label;
  };

  const selectedCategoryName = () => {
    let label = null;
    if (selectedCategory !== CONST_NONE) {
      categories?.forEach((data) => {
        if (data.id === selectedCategory) label = data.name;
      });
    }

    return label;
  };

  const filterOptions = [
    {
      id: CONST_FILTER_LABEL_AGENCY,
      label: Locales.trip.filter.agreed_agency,
      helpText: Locales.trip.filter.helptext.agreed_agency,
      value: selectedAgency,
      data: selectableListDefaultOption.concat(agencies),
      onChange: setAgency,
      condition: !isAgency && !isDEI,
      tagName: selectedAgencyName(),
    },
    {
      id: CONST_FILTER_LABEL_DEPOSIT_TRANSFERRED,
      label: Locales.trip.filter.payment_status,
      helpText: Locales.trip.filter.helptext.payment_status,
      value: selectedPaymentStatus,
      data: selectableListDefaultOption.concat(paymentStatus),
      onChange: setPaymentStatus,
      condition: isAgency || isAGLO,
      tagName: selectedPaymentStatusName(),
    },
    {
      id: CONST_FILTER_LABEL_VISA,
      label: Locales.trip.filter.visa_status,
      helpText: Locales.trip.filter.helptext.visa_status,
      value: selectedVisaStatus,
      data: selectableListDefaultOption.concat(visaStatus),
      onChange: setVisaStatus,
      condition: !isDEI && !isMS,
      tagName: selectedVisaStatusName(),
    },
    {
      id: CONST_FILTER_LABEL_HEALTH,
      label: Locales.trip.filter.medical_exam_status,
      helpText: Locales.trip.filter.helptext.medical_exam_status,
      value: selectedMedicalVisitStatus,
      data: selectableListDefaultOption.concat(medicalVisitStatus),
      onChange: setMedicalVisitStatus,
      condition: !isDEI,
      tagName: selectedMedicalVisitStatusName(),
    },
    {
      id: CONST_FILTER_LABEL_PASSPORT,
      label: Locales.trip.filter.passport_status,
      helpText: Locales.trip.filter.helptext.passport_status,
      value: selectedPassportStatus,
      data: selectableListDefaultOption.concat(passportStatus),
      onChange: setPassportStatus,
      condition: !isMS && !isMAEC,
      tagName: selectedPassportStatusName(),
    },
    {
      id: CONST_FILTER_LABEL_NPI,
      label: Locales.trip.filter.npi_status,
      helpText: Locales.trip.filter.helptext.npi_status,
      value: selectedNpiStatus,
      data: selectableListDefaultOption.concat(npiStatus),
      onChange: setNpiStatus,
      condition: isAgency || isAGLO,
      tagName: selectedNpiStatusName(),
    },
    {
      id: CONST_FILTER_LABEL_INTERNATIONAL,
      label: Locales.trip.filter.diaspora,
      helpText: Locales.trip.filter.helptext.diaspora,
      value: selectedIsInternational,
      data: selectableListDefaultOption.concat(internationalTrip),
      onChange: setIsInternational,
      condition: !isDEI,
      tagName: selectedIsInternationalTrip(),
    },
    {
      id: CONST_FILTER_LABEL_CATEGORY,
      label: Locales.trip.filter.category,
      helpText: Locales.trip.filter.helptext.category,
      value: selectedCategory,
      data: selectableListDefaultOption.concat(categories),
      onChange: setCategory,
      condition: !isDEI,
      tagName: selectedCategoryName(),
    },
  ];

  // Function to apply selected filters
  const applyFilters = () => {
    let filters = null;

    if (selectedAgency !== CONST_NONE) {
      filters = { [CONST_FILTER_LABEL_AGENCY]: selectedAgency };
    }

    if (selectedPaymentStatus !== CONST_NONE) {
      filters = { ...filters, [CONST_FILTER_LABEL_DEPOSIT_TRANSFERRED]: selectedPaymentStatus };
    }

    if (selectedVisaStatus !== CONST_NONE) {
      filters = { ...filters, [CONST_FILTER_LABEL_VISA]: selectedVisaStatus };
    }

    if (selectedNpiStatus !== CONST_NONE) {
      filters = { ...filters, [CONST_FILTER_LABEL_NPI]: selectedNpiStatus };
    }

    if (selectedPassportStatus !== CONST_NONE) {
      filters = { ...filters, [CONST_FILTER_LABEL_PASSPORT]: selectedPassportStatus };
    }

    if (selectedIsInternational !== CONST_NONE) {
      filters = { ...filters, [CONST_FILTER_LABEL_INTERNATIONAL]: selectedIsInternational };
    }

    if (selectedMedicalVisitStatus !== CONST_NONE) {
      filters = { ...filters, [CONST_FILTER_LABEL_HEALTH]: selectedMedicalVisitStatus };
    }

    if (selectedCategory !== CONST_NONE) {
      filters = { ...filters, [CONST_FILTER_LABEL_CATEGORY]: selectedCategory };
    }

    setQueryFilter(filters);
  };

  // Function to reset all filters to their default state (CONST_NONE)
  const resetFilters = () => {
    setAgency(CONST_NONE);
    setPaymentStatus(CONST_NONE);
    setVisaStatus(CONST_NONE);
    setNpiStatus(CONST_NONE);
    setPassportStatus(CONST_NONE);
    setIsInternational(CONST_NONE);
    setMedicalVisitStatus(CONST_NONE);
    setCategory(CONST_NONE);
    setQueryFilter(null);
  };

  // Function to remove a specific filter from the query filters
  const deleteFilter = (filter) => {
    const queryFiltersLocal = { ...queryFilters };
    delete queryFiltersLocal[filter];
    setQueryFilter(queryFiltersLocal);
  };

  // Function to remove a specific filter and reset its corresponding state
  const removeFilter = (filter) => {
    switch (filter) {
      case CONST_FILTER_LABEL_AGENCY:
        setAgency(CONST_NONE);
        break;
      case CONST_FILTER_LABEL_DEPOSIT_TRANSFERRED:
        setPaymentStatus(CONST_NONE);
        break;
      case CONST_FILTER_LABEL_VISA:
        setVisaStatus(CONST_NONE);
        break;
      case CONST_FILTER_LABEL_NPI:
        setNpiStatus(CONST_NONE);
        break;
      case CONST_FILTER_LABEL_PASSPORT:
        setPassportStatus(CONST_NONE);
        break;
      case CONST_FILTER_LABEL_INTERNATIONAL:
        setIsInternational(CONST_NONE);
        break;
      case CONST_FILTER_LABEL_HEALTH:
        setMedicalVisitStatus(CONST_NONE);
        break;
      case CONST_FILTER_LABEL_CATEGORY:
        setCategory(CONST_NONE);
        break;
      default:
        break;
    }
    deleteFilter(filter);
  };

  useMemo(() => {
    // set value if in URL and allow the filter to be applied only if the user have the right to apply it
    if (
      preSetAgency !== CONST_NONE ||
      preSetPaymentStatus !== CONST_NONE ||
      preSetVisaStatus !== CONST_NONE ||
      preSetNpiStatus !== CONST_NONE ||
      preSetPassportStatus !== CONST_NONE ||
      preSetIsInternational !== CONST_NONE ||
      preSetMedicalVisitStatus !== CONST_NONE ||
      preSetCategory !== CONST_NONE
    ) {
      applyFilters();
    }
  }, []);

  useMemo(() => {
    if (
      preSetAgency === null &&
      preSetPaymentStatus === null &&
      preSetVisaStatus === null &&
      preSetNpiStatus === null &&
      preSetPassportStatus === null &&
      preSetIsInternational === null &&
      preSetMedicalVisitStatus === null &&
      preSetCategory === null &&
      preSetSearch === null
    ) {
      resetFilters();
      setSearchQuery('');
    }
  }, [
    preSetAgency,
    preSetPaymentStatus,
    preSetVisaStatus,
    preSetNpiStatus,
    preSetPassportStatus,
    preSetIsInternational,
    preSetMedicalVisitStatus,
    preSetCategory,
    preSetSearch,
  ]);

  useMemo(() => {
    let params = {};

    // update URL each time a filter is selected
    if (selectedAgency !== CONST_NONE) {
      params = { [CONST_FILTER_LABEL_AGENCY]: selectedAgency };
    }

    if (selectedPaymentStatus !== CONST_NONE) {
      params = { ...params, [CONST_FILTER_LABEL_DEPOSIT_TRANSFERRED]: selectedPaymentStatus };
    }

    if (selectedVisaStatus !== CONST_NONE) {
      params = { ...params, [CONST_FILTER_LABEL_VISA]: selectedVisaStatus };
    }

    if (selectedNpiStatus !== CONST_NONE) {
      params = { ...params, [CONST_FILTER_LABEL_NPI]: selectedNpiStatus };
    }

    if (selectedPassportStatus !== CONST_NONE) {
      params = { ...params, [CONST_FILTER_LABEL_PASSPORT]: selectedPassportStatus };
    }

    if (selectedIsInternational !== CONST_NONE) {
      params = { ...params, [CONST_FILTER_LABEL_INTERNATIONAL]: selectedIsInternational };
    }

    if (selectedMedicalVisitStatus !== CONST_NONE) {
      params = { ...params, [CONST_FILTER_LABEL_HEALTH]: selectedMedicalVisitStatus };
    }

    if (selectedCategory !== CONST_NONE) {
      params = { ...params, [CONST_FILTER_LABEL_CATEGORY]: selectedCategory };
    }

    // update URL with search query
    if (searchQuery) {
      params = { q: searchQuery };
    }

    if (params) {
      setSearchParams(params);
    }
  }, [
    selectedAgency,
    selectedPaymentStatus,
    selectedVisaStatus,
    selectedNpiStatus,
    selectedPassportStatus,
    selectedIsInternational,
    selectedMedicalVisitStatus,
    selectedCategory,
    searchQuery,
  ]);

  return (
    <>
      <section className="table-form">
        <form>
          <div className="table-actions items-group">
            <TextField
              placeholder={Locales.trip.search}
              prefix={<i className="fa-solid fa-magnifying-glass" />}
              value={searchQuery}
              onChange={(value) => setSearchQuery(value.trim())}
              onKeyDown={preventSubmitWithENTER}
            />

            <DropdownCard>
              <DropdownCard.Trigger>
                <Button variant="secondary">
                  <i className="fa-regular fa-bars-filter" /> {Locales.trip.filter.filter}
                </Button>
              </DropdownCard.Trigger>
              <DropdownCard.Dropdown width="large">
                <DropdownCard.Content>
                  <form>
                    {filterOptions.map((option) =>
                      option.condition ? (
                        <FilterSelect
                          key={option.id}
                          label={option.label}
                          helpText={option.helpText}
                          value={option.value}
                          data={option.data}
                          onChange={option.onChange}
                        />
                      ) : null
                    )}
                  </form>
                </DropdownCard.Content>
                <DropdownCard.Actions>
                  <Button variant="inline" onClick={resetFilters}>
                    {Locales.base.button.reset}
                  </Button>
                  <Button color="success" onClick={applyFilters}>
                    {Locales.base.button.validate}
                  </Button>
                </DropdownCard.Actions>
              </DropdownCard.Dropdown>
            </DropdownCard>
          </div>
        </form>
      </section>

      <BlockCard>
        <div className="table-meta items-group">
          <div>
            <span>{Locales.base.result} : </span>
            <strong>{resultString}</strong>
          </div>

          <div className="tags-container">
            {queryFilters &&
              filterOptions.map(
                (filter) =>
                  queryFilters[filter.id] && (
                    <Fragment key={filter.id}>
                      <span>|</span>
                      <Tag onRemove={() => removeFilter(filter.id)}>{`${filter.label}: ${filter.tagName}`}</Tag>
                    </Fragment>
                  )
              )}
          </div>
        </div>
      </BlockCard>
    </>
  );
};

TripFilters.defaultProps = {
  agencies: null,
  trips: null,
  categories: null,
  queryFilters: null,
  setQueryFilter: null,
  searchParams: null,
  setSearchParams: null,
  searchQuery: null,
  setSearchQuery: null,
  preSetSearch: null,
};

TripFilters.propTypes = {
  agencies: PropTypes.arrayOf(PropTypes.shape({})),
  trips: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  queryFilters: PropTypes.shape({}),
  setQueryFilter: PropTypes.func,
  searchParams: PropTypes.instanceOf(URLSearchParams),
  setSearchParams: PropTypes.func,
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func,
  preSetSearch: PropTypes.string,
};

export default TripFilters;
