import React from 'react';
import { Alert } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';

const AuthorizationAlert = ({ condition, level, message }) => {
  return condition && <Alert level={level} message={<span className="alert-children">{message}</span>} />;
};

AuthorizationAlert.defaultProps = {
  condition: false,
  level: null,
  message: null,
};

AuthorizationAlert.propTypes = {
  condition: PropTypes.bool,
  level: PropTypes.string,
  message: PropTypes.string,
};

export default AuthorizationAlert;
