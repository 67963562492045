import React, { useState } from 'react';
import { Page } from '@trellixio/roaster-coffee';
import Locales from 'locales';
import { useFormatTextWithLinks } from 'hooks';
import style from './Legal.module.css';

const LegalMention = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleBlock = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Page title={Locales.legal.title} className={style.container}>
      <nav className="settings">
        <ol>
          {Locales.legal.articles.map((article) => (
            <li key={article.id}>
              <a
                href={article.id}
                onClick={(e) => {
                  e.preventDefault();
                  toggleBlock(article.id);
                }}
              >
                {article.title}
              </a>
              {openIndex === article.id && (
                <section className={style.content}>
                  {article?.preamble && <p>{useFormatTextWithLinks(article?.preamble)}</p>}
                  {article.content.map((paragraph) => {
                    if (typeof paragraph === 'string') {
                      return <p key={`${article.id}-${paragraph.id}`}>{useFormatTextWithLinks(paragraph)}</p>;
                    }
                    if (paragraph.type === 'list') {
                      return (
                        <ul key={`list-${paragraph.id}`} className={style.list}>
                          {paragraph.items.map((item) => (
                            <li key={`item-${item.id}`}>{useFormatTextWithLinks(item)}</li>
                          ))}
                        </ul>
                      );
                    }
                    return null;
                  })}
                </section>
              )}
            </li>
          ))}

          <li>
            <a
              href={Locales.legal.tos.id}
              onClick={(e) => {
                e.preventDefault();
                toggleBlock(Locales.legal.tos.id);
              }}
            >
              {Locales.legal.tos.title}
            </a>
            {openIndex === Locales.legal.tos.id && (
              <section className={style.content}>
                {Object.keys(Locales.legal.tos.sections).map((key) => {
                  const section = Locales.legal.tos.sections[key];
                  return (
                    <div key={`condition-${key}`}>
                      <h3>
                        <strong>{section.title}</strong>
                      </h3>
                      {section.content.map((contentItem) => {
                        if (contentItem.type === 'list') {
                          return (
                            <ul key={`list-${key}-${contentItem.id}`} className={style.list}>
                              {contentItem.items.map((item) => (
                                <li key={`item-${key}-${item.id}`}>{useFormatTextWithLinks(item)}</li>
                              ))}
                            </ul>
                          );
                        }
                        return <p key={`content-${key}-${contentItem.id}`}>{useFormatTextWithLinks(contentItem)}</p>;
                      })}
                    </div>
                  );
                })}
              </section>
            )}
          </li>

          <li>
            <a
              href={Locales.legal.confidentiality.id}
              onClick={(e) => {
                e.preventDefault();
                toggleBlock(Locales.legal.confidentiality.id);
              }}
            >
              {Locales.legal.confidentiality.title}
            </a>
            {openIndex === Locales.legal.confidentiality.id && (
              <section className={style.content}>
                {Object.keys(Locales.legal.confidentiality.sections).map((key) => {
                  const section = Locales.legal.confidentiality.sections[key];
                  return (
                    <div key={`condition-${key}`}>
                      <h3>
                        <strong>{section.title}</strong>
                      </h3>
                      {section.content.map((contentItem) => {
                        if (contentItem.type === 'list') {
                          return (
                            <ul key={`list-${key}-${contentItem.id}`} className={style.list}>
                              {contentItem.items.map((item) => (
                                <li key={`item-${key}-${item.id}`}>{useFormatTextWithLinks(item)}</li>
                              ))}
                            </ul>
                          );
                        }
                        return <p key={`content-${key}-${contentItem.id}`}>{useFormatTextWithLinks(contentItem)}</p>;
                      })}
                    </div>
                  );
                })}
              </section>
            )}
          </li>
        </ol>
      </nav>
    </Page>
  );
};

export default LegalMention;
