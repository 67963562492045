import React from 'react';
import { useGetUser } from 'hooks';
import AGLODashboard from './components/AGLODashboard';
import SuperAdminDashboard from './components/SuperAdminDashboard';

const Dashboard = () => {
  const { isAGLO, isSuperAdmin } = useGetUser();

  return (
    <>
      {isAGLO && <AGLODashboard />}
      {isSuperAdmin && <SuperAdminDashboard />}
    </>
  );
};

export default Dashboard;
