import React, { useState, useContext } from 'react';
import { Page, Button, TextField, BlockCard } from '@trellixio/roaster-coffee';
import { useQuery } from '@tanstack/react-query';
import { PermissionsContext } from 'context';
import { useAPI, useDebounce, useGetUser } from 'hooks';
import { EmptyTable, LockedPlatformAlert, LockableActionWrapper } from 'components';
import { preventSubmitWithENTER } from 'helpers';
import Locales from 'locales';
import AddUserModal from './components/AddUserModal';
import UsersTable from './components/UsersTable';

const User = () => {
  const { isPlatformLocked } = useContext(PermissionsContext);
  const { isSuperAdmin, isAgency } = useGetUser();

  const [isAddModalOpened, setAddModalOpened] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [cursor, setCursor] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const limit = 30;
  const platformLocked = isAgency ? isPlatformLocked : false;
  const { api } = useAPI();

  const fetchParams = searchQuery.length > 0 ? { q: debouncedSearchQuery } : { limit, cursor };

  const { data: usersList } = useQuery({
    queryFn: api.list('user/', fetchParams),
    queryKey: ['user/', debouncedSearchQuery, limit, cursor],
    keepPreviousData: true,
  });

  const { data: organizations } = useQuery({
    queryFn: api.list('organization/'),
    queryKey: ['organization/'],
    enabled: isSuperAdmin,
  });

  const toggleAddModalOpen = () => setAddModalOpened((val) => !val);

  const resultString = usersList
    ? `${usersList.count} ${
        usersList.count <= 1 ? Locales.user.admin.slice(0, -1).toLowerCase() : Locales.user.admin.toLowerCase()
      }`
    : '-';

  return (
    usersList && (
      <Page
        title={Locales.user.admin}
        size="full"
        primaryAction={
          <LockableActionWrapper isPlatformLocked={platformLocked} callback={toggleAddModalOpen}>
            <Button variant="primary" color="success">
              <i className="fa-regular fa-solid fa-plus" />
              {Locales.base.button.add}
            </Button>
          </LockableActionWrapper>
        }
      >
        {isPlatformLocked && isAgency && <LockedPlatformAlert />}
        <div>
          <form>
            <TextField
              placeholder={Locales.user.search_admin}
              prefix={<i className="fa-solid fa-magnifying-glass" />}
              onChange={(value) => setSearchQuery(value.trim())}
              onKeyDown={preventSubmitWithENTER}
            />
          </form>
        </div>

        <section>
          <BlockCard>
            <div>
              <span>{Locales.user.result}</span>
              <strong>{resultString}</strong>
            </div>
          </BlockCard>
        </section>

        {usersList.count > 0 && (
          <UsersTable tableData={usersList} limit={limit} setCursor={setCursor} isPlatformLocked={platformLocked} />
        )}

        {usersList.count === 0 && (
          <EmptyTable label={searchQuery.length === 0 ? Locales.base.empty_table : Locales.base.empty_search_result} />
        )}

        {isAddModalOpened && (
          <AddUserModal opened={isAddModalOpened} organizations={organizations} closeModal={toggleAddModalOpen} />
        )}
      </Page>
    )
  );
};

export default User;
