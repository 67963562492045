import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@trellixio/roaster-coffee';
import Locales from 'locales';
import style from './Lockable.module.css';

const LockedPlatformModal = ({ opened, onClose }) => {
  return (
    <Modal title={Locales.base.access_denied.title} opened={opened} onClose={onClose} size="small">
      <p className={style.description}>{Locales.base.access_denied.description}</p>
    </Modal>
  );
};

LockedPlatformModal.defaultProps = {
  opened: false,
  onClose: null,
};

LockedPlatformModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
};

export default LockedPlatformModal;
