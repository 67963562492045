import React, { useMemo, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { PermissionsContext } from 'context';
import { useAPI, useDebounce, useGetUser } from 'hooks';
import { Page, TextField, BlockCard, DropdownCard, Button, Select, Tag } from '@trellixio/roaster-coffee';
import { EmptyTable, LockedPlatformAlert } from 'components';
import { preventSubmitWithENTER } from 'helpers';
import Locales from 'locales';
import InvoicesTable from './components/InvoicesTable';

const CONST_NONE = 'none';
const CONST_FILTER_LABEL_AGENCY = 'agency';

const Invoice = () => {
  const { isPlatformLocked } = useContext(PermissionsContext);
  const { isAGLO, isAgency } = useGetUser();
  const [cursor, setCursor] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const preSetSearch = searchParams.get('q');
  const preSetAgency = isAGLO ? searchParams.get(CONST_FILTER_LABEL_AGENCY) : null;

  const [searchQuery, setSearchQuery] = useState(preSetSearch || '');
  const [selectedAgency, setAgency] = useState(preSetAgency || CONST_NONE);

  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [queryFilters, setQueryFilter] = useState(null);
  const limit = 30;
  const { api } = useAPI();

  const fetchParams = searchQuery.length > 0 ? { q: debouncedSearchQuery } : { ...queryFilters, limit, cursor };

  const { data: invoices } = useQuery({
    queryFn: api.list('invoice/', fetchParams),
    queryKey: ['invoice/', debouncedSearchQuery, limit, cursor, queryFilters],
    keepPreviousData: true,
  });

  const { data: agencies } = useQuery({
    queryFn: api.list('agency/', { limit: 100 }),
    queryKey: ['agency/'],
    enabled: !!isAGLO,
  });

  const resultString = invoices
    ? `${invoices.count} ${
        invoices.count <= 1 ? Locales.invoice.title.toLowerCase().slice(0, -1) : Locales.invoice.title.toLowerCase()
      }`
    : '-'; // add count later if working

  const selectedAgencyName = () => {
    let name = null;
    if (selectedAgency !== CONST_NONE) {
      agencies.forEach((data) => {
        if (data.id === selectedAgency) name = data.name;
      });
    }

    return name;
  };

  const applyFilters = () => {
    let filters = null;

    if (selectedAgency !== CONST_NONE) {
      filters = { agency: selectedAgency };
    }

    setQueryFilter(filters);
  };

  const resetFilters = () => {
    setAgency(CONST_NONE);
    setQueryFilter(null);
  };

  useMemo(() => {
    // set value if in URL and allow the filter to be applied only if the user have the right to apply it
    if (preSetAgency !== CONST_NONE) {
      applyFilters();
    }
  }, []);

  useMemo(() => {
    if (preSetAgency === null && preSetSearch === null) {
      resetFilters();
      setSearchQuery('');
    }
  }, [preSetAgency, preSetSearch]);

  useMemo(() => {
    let params = {};

    // update URL each time the filter is selected
    if (selectedAgency !== CONST_NONE) {
      params = { [CONST_FILTER_LABEL_AGENCY]: selectedAgency };
    }

    // update URL with search query
    if (searchQuery) {
      params = { q: searchQuery };
    }

    if (params) {
      setSearchParams(params);
    }
  }, [selectedAgency, searchQuery]);

  return (
    invoices && (
      <Page title={Locales.invoice.title} size="full">
        {isPlatformLocked && isAgency && <LockedPlatformAlert />}
        <section className="table-form">
          <form>
            <div className="table-actions items-group">
              <TextField
                placeholder={Locales.invoice.search}
                prefix={<i className="fa-solid fa-magnifying-glass" />}
                value={searchQuery}
                onChange={(value) => setSearchQuery(value.trim())}
                onKeyDown={preventSubmitWithENTER}
              />

              {isAGLO && agencies && (
                <DropdownCard>
                  <DropdownCard.Trigger>
                    <Button variant="secondary">
                      <i className="fa-solid fa-regular fa-bars-filter" /> {Locales.invoice.dropdown.filter}
                    </Button>
                  </DropdownCard.Trigger>
                  <DropdownCard.Dropdown width="large">
                    <DropdownCard.Content>
                      <form>
                        <div className="content-row">
                          <p className="group-label">{Locales.invoice.dropdown.agreed_agency}</p>
                          <Select
                            helpText={Locales.invoice.dropdown.agreed_agency_helptext}
                            defaultValue={selectedAgency}
                            data={[{ label: Locales.base.button.select, value: CONST_NONE, disabled: true }].concat(
                              agencies
                            )}
                            onChange={setAgency}
                          />
                        </div>
                      </form>
                    </DropdownCard.Content>
                    <DropdownCard.Actions>
                      <Button variant="inline" onClick={resetFilters}>
                        {Locales.base.button.reset}
                      </Button>
                      <Button color="success" onClick={applyFilters}>
                        {Locales.base.button.validate}
                      </Button>
                    </DropdownCard.Actions>
                  </DropdownCard.Dropdown>
                </DropdownCard>
              )}
            </div>
          </form>
        </section>

        <BlockCard>
          <div className="table-meta items-group">
            <div>
              <span>{Locales.base.result} : </span>
              <strong>{resultString}</strong>
            </div>

            <div className="tags-container">
              {queryFilters && Object.prototype.hasOwnProperty.call(queryFilters, 'agency') && (
                <>
                  <span>|</span>
                  <Tag onRemove={() => resetFilters()}>{`${
                    Locales.invoice.dropdown.agreed_agency
                  }: ${selectedAgencyName()}`}</Tag>
                </>
              )}
            </div>
          </div>
        </BlockCard>

        {invoices.count > 0 && (
          <InvoicesTable tableData={invoices} limit={limit} setCursor={setCursor} isPlatformLocked={isPlatformLocked} />
        )}

        {invoices.count === 0 && <EmptyTable isSearchResult={queryFilters || searchQuery.length > 0} />}
      </Page>
    )
  );
};

export default Invoice;
