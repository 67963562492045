import React from 'react';
import { Modal, Table, Button, Icon, Tooltip, showToast } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { getUserName, formatDate, formatNumber } from 'helpers';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useAPI, useGetUser, useGetPilgrim } from 'hooks';
import Locales from 'locales';

const DepositModal = ({ isOpened, closeModal, pilgrimData, advanceDeposit, preferencesId }) => {
  const { user } = useGetUser();
  const { id, deposits, depositTotal, cartTotal, firstname, lastname } = useGetPilgrim(pilgrimData);

  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;

  const { mutate: makeDeposit } = useMutation({
    mutationFn: (amount) =>
      api.create('deposit', {
        trip: id,
        amount,
        campaign: preferencesId,
        agency: user.id,
      }),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['deposit/'] });
      queryClient.invalidateQueries({ queryKey: ['deposit/stats/default/'] });
      showToast({
        message: Locales.formatString(Locales.trip.toast.update_deposits, {
          fullname: data.pilgrim_name,
        }),
        level: 'success',
      });
      closeModal();
    },
  });

  // Format an amount and add "FCFA" currency
  const formatAmount = (amout) => `${formatNumber(amout)} FCFA`;

  return (
    <Modal
      title={Locales.trip.table.deposit_modal_title}
      opened={isOpened}
      onClose={closeModal}
      size="small"
      footer={
        <Button variant="secondary" onClick={closeModal}>
          {Locales.base.button.discard}
        </Button>
      }
    >
      <p className="content-row">
        {Locales.trip.table.update_deposit}
        <strong> {getUserName(firstname, lastname)}.</strong>
      </p>
      <Table>
        <Table.Head>
          <Table.HeadCell type="date">
            {Locales.trip.table.deposit_date}
            <Tooltip inline label={Locales.trip.table.tooltips.deposit_date}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="numeric">
            {Locales.trip.table.amount}
            <Tooltip inline label={Locales.trip.table.tooltips.amount}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
          <Table.HeadCell type="text">
            {Locales.trip.table.deposit_status}
            <Tooltip inline label={Locales.trip.table.tooltips.deposit_status}>
              <Icon hasTooltip>
                <i className="fa-regular fa-circle-info" />
              </Icon>
            </Tooltip>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {deposits.length > 0 && depositTotal < cartTotal && (
            <Table.Row>
              <Table.Cell type="date">{formatDate(new Date())}</Table.Cell>
              <Table.Cell type="numeric">
                <strong>
                  {Locales.formatString(Locales.trip.remain, {
                    remaining_amount: formatAmount(cartTotal - depositTotal),
                  })}
                </strong>
              </Table.Cell>
              <Table.Cell type="text">
                <Button
                  variant="primary"
                  color="success"
                  disabled={isMutating}
                  onClick={() => {
                    // Pay the remaining balance for an existing deposit
                    // This completes the payment if the total hasn't been reached yet
                    makeDeposit(cartTotal - depositTotal);
                  }}
                >
                  <i className="fa-regular fa-solid fa-check" />
                </Button>
              </Table.Cell>
            </Table.Row>
          )}

          {deposits.length > 0 &&
            deposits.map((deposit) => (
              <Table.Row key={deposit.id}>
                <Table.Cell type="date">{formatDate(deposit.created)}</Table.Cell>
                <Table.Cell type="numeric">{formatAmount(deposit.amount)}</Table.Cell>
                <Table.Cell type="text">
                  {deposit.is_invoice_paid && (
                    <Icon small color="success">
                      <i className="fa-solid fa-check" />
                    </Icon>
                  )}
                  {!deposit.is_invoice_paid && (
                    <Icon small className="gray">
                      <i className="fa-solid fa-question" />
                    </Icon>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}

          {deposits.length <= 0 && (
            <>
              <Table.Row>
                <Table.Cell type="date">{formatDate(new Date())}</Table.Cell>
                <Table.Cell type="numeric">
                  <strong>
                    {Locales.formatString(Locales.trip.total, {
                      total_amount: formatAmount(cartTotal),
                    })}
                  </strong>
                </Table.Cell>
                <Table.Cell type="text">
                  <Button
                    variant="primary"
                    color="success"
                    disabled={isMutating}
                    onClick={() => {
                      // Make a full payment in one go
                      // This is available when there are no existing deposits
                      makeDeposit(cartTotal);
                    }}
                  >
                    <i className="fa-regular fa-solid fa-check" />
                  </Button>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell type="date">{formatDate(new Date())}</Table.Cell>
                <Table.Cell type="numeric">
                  <strong>
                    {Locales.formatString(Locales.trip.advance, {
                      partial_amount: formatAmount(advanceDeposit),
                    })}
                  </strong>
                </Table.Cell>
                <Table.Cell type="text">
                  <Button
                    variant="primary"
                    color="success"
                    disabled={isMutating}
                    onClick={() => {
                      // Make a partial deposit (advance payment)
                      // This option is available when there are no existing deposits
                      makeDeposit(advanceDeposit);
                    }}
                  >
                    <i className="fa-regular fa-solid fa-plus" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            </>
          )}
        </Table.Body>
      </Table>
    </Modal>
  );
};

DepositModal.defaultProps = {
  isOpened: false,
  pilgrimData: null,
  advanceDeposit: null,
  preferencesId: null,
  closeModal: null,
};

DepositModal.propTypes = {
  isOpened: PropTypes.bool,
  pilgrimData: PropTypes.shape({}),
  advanceDeposit: PropTypes.number,
  preferencesId: PropTypes.string,
  closeModal: PropTypes.func,
};

export default DepositModal;
