import React, { useState } from 'react';
import { Modal, Icon, Switch } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import Locales from 'locales';
import { formatNumber } from 'helpers';
import styles from './Trips.module.css';

const CONST_STANDARD = 'Standard';
const CONST_PREMIUM = 'Premium';
const CONST_ELITE = 'Elite';
const CONST_DIASPORA_STANDARD = 'Diaspora Standard';
const CONST_DIASPORA_PREMIUM = 'Diaspora Premium';

const CategoryCard = ({ name, bgColor, advantages, adultAmount, childAmount, availableSeats }) => {
  return (
    <article className={styles.categoryCard}>
      <div className={`${styles.categoryCardHeader} ${bgColor}`}>
        <p>{name}</p>

        <ul>
          {advantages.map((advantage) => (
            <li>{advantage}</li>
          ))}
        </ul>

        <div>
          {Locales.trip.categories.available_seats}
          <strong>{formatNumber(availableSeats)}</strong>
        </div>
      </div>

      <div className={styles.categoryCardContent}>
        <div>
          <p>{Locales.trip.categories.labels.adult}</p>
          <strong>
            {`${formatNumber(adultAmount)} `}
            <span className={styles.noBold}>FCFA</span>
          </strong>
        </div>

        <div>
          <p>{Locales.trip.categories.labels.child}</p>
          <strong>
            {`${formatNumber(childAmount)} `}
            <span className={styles.noBold}>FCFA</span>
          </strong>
        </div>
      </div>
    </article>
  );
};

CategoryCard.defaultProps = {
  name: null,
  bgColor: null,
  advantages: null,
  adultAmount: null,
  childAmount: null,
  availableSeats: null,
};

CategoryCard.propTypes = {
  name: PropTypes.string,
  bgColor: PropTypes.string,
  advantages: PropTypes.arrayOf(PropTypes.string),
  adultAmount: PropTypes.number,
  childAmount: PropTypes.number,
  availableSeats: PropTypes.number,
};

const TripCategoriesModal = ({ isOpened, closeModal, categoriesData }) => {
  const [diasporaSelected, setDiasporaSelected] = useState(false);

  const getCategoryDetails = (name) => {
    const category = categoriesData.find((cat) => cat.name === name);
    return category
      ? {
          adultAmount: category.price.adult,
          childAmount: category.price.child,
          availableSeats: category.available_seats,
        }
      : {};
  };

  const standardDetails = getCategoryDetails(CONST_STANDARD);
  const premiumDetails = getCategoryDetails(CONST_PREMIUM);
  const eliteDetails = getCategoryDetails(CONST_ELITE);
  const diasporaStandardDetails = getCategoryDetails(CONST_DIASPORA_STANDARD);
  const diasporaPremiumDetails = getCategoryDetails(CONST_DIASPORA_PREMIUM);

  const categories = [
    {
      name: Locales.trip.categories.standard,
      bgColor: styles.headerStandard,
      adultAmount: diasporaSelected ? diasporaStandardDetails.adultAmount : standardDetails.adultAmount,
      childAmount: diasporaSelected ? diasporaStandardDetails.childAmount : standardDetails.childAmount,
      availableSeats: standardDetails.availableSeats,
      advantages: [
        Locales.trip.categories.advantages.transport_standard,
        Locales.trip.categories.advantages.lodging_standard,
        Locales.trip.categories.advantages.services_standard,
      ],
    },
    {
      name: Locales.trip.categories.premium,
      bgColor: styles.headerPremium,
      adultAmount: diasporaSelected ? diasporaPremiumDetails.adultAmount : premiumDetails.adultAmount,
      childAmount: diasporaSelected ? diasporaPremiumDetails.childAmount : premiumDetails.childAmount,
      availableSeats: premiumDetails.availableSeats,
      advantages: [
        Locales.trip.categories.advantages.transport_premium,
        Locales.trip.categories.advantages.lodging_premium,
        Locales.trip.categories.advantages.services_premium,
      ],
    },
    {
      name: Locales.trip.categories.elite,
      bgColor: styles.headerElite,
      adultAmount: eliteDetails.adultAmount,
      childAmount: eliteDetails.childAmount,
      availableSeats: eliteDetails.availableSeats,
      advantages: [
        Locales.trip.categories.advantages.transport_premium,
        Locales.trip.categories.advantages.lodging_elite,
        Locales.trip.categories.advantages.services_elite,
      ],
    },
  ];

  return (
    <Modal title={Locales.trip.categories.title} opened={isOpened} onClose={closeModal} size="large">
      <div className={styles.categoriesHeader}>
        <div className={styles.categoriesHeader}>
          <span>{Locales.trip.categories.diaspora}</span>

          <form>
            <Switch onChange={setDiasporaSelected} />
          </form>
        </div>

        <div className={styles.categoriesHeader}>
          <Icon small>
            <i className="fa-regular fa-circle-info" />
          </Icon>
          <span>
            {Locales.formatString(Locales.trip.categories.stock_limit, {
              premium: <strong>{Locales.trip.categories.premium}</strong>,
              elite: <strong>{Locales.trip.categories.elite}</strong>,
            })}
          </span>
        </div>
      </div>

      <div className={styles.categoryCardContainer}>
        {categories.map((category) => (
          <CategoryCard
            name={category.name}
            bgColor={category.bgColor}
            advantages={category.advantages}
            adultAmount={category.adultAmount}
            childAmount={category.childAmount}
            availableSeats={category.availableSeats}
          />
        ))}
      </div>
    </Modal>
  );
};

TripCategoriesModal.defaultProps = {
  isOpened: false,
  closeModal: null,
  categoriesData: null,
};

TripCategoriesModal.propTypes = {
  isOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  categoriesData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TripCategoriesModal;
