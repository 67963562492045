import React, { useState } from 'react';
import { Modal, TextField, Textarea, showToast, Button, BlockCard } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { validateEmail } from 'helpers';
import { useAPI } from 'hooks';
import Locales from 'locales';

const AddAgencyModal = ({ opened, closeModal }) => {
  const [name, setName] = useState('');
  const [ifuNumber, setIFU] = useState('');
  const [phoneNumber, setPhone] = useState('');
  const [addr, setAddr] = useState('');
  const [ownerFirstname, setOwnerFirstname] = useState('');
  const [ownerLastname, setOwnerLastname] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [ifuError, setIfuError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addrLengthError, setLengthAddrError] = useState(false);
  const [nameLengthError, setNameLengthError] = useState(false);
  const [firstnameError, setFirstnameError] = useState(false);
  const [lastnameError, setLastnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;

  const newAgency = {
    name,
    ifu: ifuNumber,
    address: addr,
    phone: phoneNumber,
    admin_first_name: ownerFirstname,
    admin_last_name: ownerLastname,
    admin_email: ownerEmail,
  };

  const { mutate: createAgency } = useMutation({
    mutationFn: () => api.create('agency/', newAgency),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agency/'] });
      queryClient.invalidateQueries({ queryKey: ['agency/stats/default/'] });
      showToast({
        message: Locales.formatString(Locales.agency.toast.agency_added, { name }),
        level: 'success',
      });
      closeModal();
    },
  });

  const validateForm = (event) => {
    event.preventDefault();
    const ifuCorrectFormat = /^[0-9]{13}$/.test(ifuNumber);
    const ifuErrorLocal = !ifuCorrectFormat;
    const phoneErrorLocal = phoneNumber.length >= 0 && phoneNumber.length < 8;
    const addrLengthErrorLocal = addr.length >= 0 && addr.length < 5;
    const nameLengthErrorLocal = name.length >= 0 && name.length < 5;
    const firstnameErrorLocal = ownerFirstname.length === 0;
    const lastnameErrorLocal = ownerLastname.length === 0;
    const emailErrorLocal = !validateEmail(ownerEmail);

    setIfuError(ifuErrorLocal);
    setPhoneError(phoneErrorLocal);
    setFirstnameError(firstnameErrorLocal);
    setLastnameError(lastnameErrorLocal);
    setEmailError(emailErrorLocal);
    setLengthAddrError(addrLengthErrorLocal);
    setNameLengthError(nameLengthErrorLocal);

    if (
      !ifuErrorLocal &&
      !phoneErrorLocal &&
      !firstnameErrorLocal &&
      !lastnameErrorLocal &&
      !emailErrorLocal &&
      !addrLengthErrorLocal &&
      !nameLengthErrorLocal
    ) {
      createAgency();
    }
  };

  return (
    <Modal
      title={Locales.agency.add}
      size="small"
      opened={opened}
      onClose={closeModal}
      footer={
        <>
          <Button variant="secondary" onClick={closeModal}>
            {Locales.base.button.discard}
          </Button>
          <Button variant="primary" color="success" onClick={validateForm} disabled={isMutating}>
            {Locales.base.button.save}
          </Button>
        </>
      }
    >
      <form>
        <BlockCard>
          <p>{Locales.agency.agency_detail} :</p>

          <TextField
            label={Locales.agency.field.agency_name}
            type="text"
            placeholder={Locales.base.field.placeholder.name}
            required
            error={nameLengthError ? Locales.agency.errors.agency_name : ''}
            onChange={setName}
          />

          <TextField
            label={Locales.agency.field.num_ifu}
            type="text"
            placeholder="3202300000000"
            pattern="^[0-9]{13}$"
            minLength="13"
            maxLength="13"
            required
            error={ifuError ? Locales.agency.errors.ifu : ''}
            onChange={setIFU}
          />

          <TextField
            label={Locales.base.field.phone_number}
            type="number"
            placeholder="99010203"
            className="prefixed-input"
            prefix="+229"
            required
            error={phoneError ? Locales.agency.errors.phone : ''}
            onChange={setPhone}
          />

          <Textarea
            label={Locales.base.field.address}
            placeholder={Locales.base.field.placeholder.address}
            required
            error={addrLengthError ? Locales.agency.errors.addr : ''}
            onChange={setAddr}
          />

          <p>{Locales.agency.enter_user_info}</p>

          <div className="items-group">
            <TextField
              label={Locales.base.field.first_name}
              type="text"
              placeholder={Locales.base.field.placeholder.first_name}
              required
              error={firstnameError ? Locales.agency.errors.empty_field : ''}
              onChange={setOwnerFirstname}
            />

            <TextField
              label={Locales.base.field.last_name}
              type="text"
              placeholder={Locales.base.field.placeholder.lastname}
              required
              error={lastnameError ? Locales.agency.errors.empty_field : ''}
              onChange={setOwnerLastname}
            />
          </div>

          <TextField
            label={Locales.base.field.email}
            type="email"
            placeholder={Locales.base.field.placeholder.email}
            required
            error={emailError ? Locales.agency.errors.email : ''}
            onChange={setOwnerEmail}
          />
        </BlockCard>
      </form>
    </Modal>
  );
};

AddAgencyModal.defaultProps = {
  opened: false,
  closeModal: null,
};

AddAgencyModal.propTypes = {
  opened: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default AddAgencyModal;
