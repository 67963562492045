import React from 'react';
import { Button } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import Locales from 'locales';
import { Urls } from 'data';
import styles from './LegalNotice.module.css';

const LegalNotice = ({ cta }) => {
  return (
    <div className={styles.container}>
      <p>
        {Locales.formatString(Locales.cgu.modal.first_part, {
          legal: <strong>{Locales.cgu.modal.legal}</strong>,
          cgu: <strong>{Locales.cgu.modal.cgu}</strong>,
          confidentiality: <strong>{Locales.cgu.modal.confidentiality}</strong>,
        })}
      </p>

      <p>{Locales.cgu.modal.second_part}</p>

      <div>
        {Locales.cgu.modal.third_part.title}

        <ul>
          <li>
            {Locales.formatString(Locales.cgu.modal.third_part.legal, {
              legal: (
                <a href={Urls.legal_mention} target="_blank" rel="noreferrer">
                  {Locales.cgu.modal.legal}
                </a>
              ),
            })}
          </li>

          <li>
            {Locales.formatString(Locales.cgu.modal.third_part.cgu, {
              cgu: (
                <a href={Urls.cgu} target="_blank" rel="noreferrer">
                  {Locales.cgu.modal.cgu}
                </a>
              ),
            })}
          </li>

          <li>
            {Locales.formatString(Locales.cgu.modal.third_part.confidentiality, {
              confidentiality: (
                <a href={Urls.legal_mention} target="_blank" rel="noreferrer">
                  {Locales.cgu.modal.confidentiality}
                </a>
              ),
            })}
          </li>

          <li>
            {Locales.formatString(Locales.cgu.modal.third_part.cookie, {
              cookie: (
                <a href={Urls.cookie_policy} target="_blank" rel="noreferrer">
                  {Locales.cgu.modal.cookie}
                </a>
              ),
            })}
          </li>
        </ul>
      </div>

      <p>
        {Locales.formatString(Locales.cgu.modal.fourth_part, {
          btn: <strong>{Locales.cgu.modal.btn}</strong>,
        })}
      </p>

      <Button variant="primary" color="success" onClick={cta}>
        {Locales.cgu.modal.btn}
      </Button>
    </div>
  );
};

LegalNotice.defaultProps = {
  cta: null,
};

LegalNotice.propTypes = {
  cta: PropTypes.func,
};

export default LegalNotice;
