import React, { useState } from 'react';
import { Modal, ActionCard, Switch, Button, BlockCard, Textarea, showToast } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { getUserName, formatDate } from 'helpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI, useGetPilgrim, useGetUser } from 'hooks';
import Locales from 'locales';
import style from './Trips.module.css';

const MedicalCheckupModal = ({ isOpened, closeModal, pilgrimData }) => {
  const {
    id,
    firstname,
    lastname,
    npi,
    note,
    passport,
    birthdate,
    health,
    categoryId,
    isPassportValid,
    passportExpirationDate,
    isInternational,
    isHealthy,
  } = useGetPilgrim(pilgrimData);

  const { isMSSecondary } = useGetUser();

  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;

  // Define local conditions for each health issue
  const [q1IsVaxCovid, setQ1IsVaxCovid] = useState(health.q1_is_vax_covid || false);
  const [q2IsVaxMeningitis, setQ2IsVaxMeningitis] = useState(health.q2_is_vax_meningitis || false);
  const [q3IsVaxYellow, setQ3IsVaxYellow] = useState(health.q3_is_vax_yellow || false);
  const [q4IsVaxPoliomyelitis, setQ4IsVaxPoliomyelitis] = useState(health.q4_is_vax_poliomyelitis || false);
  const [q6IsVaxFlu, setQ6IsVaxFlu] = useState(health.q6_is_vax_flu || false);
  const [q7aIsIssuePsychomotor, setQ7aIsIssuePsychomotor] = useState(health.q7a_is_issue_psychomotor || false);
  const [q7bIsIssueLocomotor, setQ7bIsIssueLocomotor] = useState(health.q7b_is_issue_locomotor || false);
  const [q7cIsIssueSeeing, setQ7cIsIssueSeeing] = useState(health.q7c_is_issue_seeing || false);
  const [q7dIsIssueChronic, setQ7dIsIssueChronic] = useState(health.q7d_is_issue_chronic || false);
  const [q7eIsChronicTreatment, setQ7eIsChronicTreatment] = useState(health.q7e_is_chronic_treatment || false);
  const [healthNote, setPilgrimNote] = useState(health.notes || '');

  const updatedPilgrim = {
    pilgrim: {
      first_name: firstname,
      last_name: lastname,
      npi,
      passport,
      birthdate: formatDate(birthdate, 'YYYY-MM-DD'),
      is_passport_valid: isPassportValid,
      passport_expiration: passportExpirationDate ? formatDate(passportExpirationDate, 'YYYY-MM-DD') : null,
      is_international: isInternational,
    },
    health: {
      q1_is_vax_covid: q1IsVaxCovid,
      q2_is_vax_meningitis: q2IsVaxMeningitis,
      q3_is_vax_yellow: q3IsVaxYellow,
      q4_is_vax_poliomyelitis: q4IsVaxPoliomyelitis,
      q6_is_vax_flu: q6IsVaxFlu,
      q7a_is_issue_psychomotor: q7aIsIssuePsychomotor,
      q7b_is_issue_locomotor: q7bIsIssueLocomotor,
      q7c_is_issue_seeing: q7cIsIssueSeeing,
      q7d_is_issue_chronic: q7dIsIssueChronic,
      q7e_is_chronic_treatment: q7eIsChronicTreatment,
      notes: healthNote,
    },
    note,
    category_id: categoryId,
  };

  const { mutate: medicalCheckup } = useMutation({
    mutationFn: () => api.update('trip', updatedPilgrim, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/default/'] });
      showToast({
        message: Locales.formatString(Locales.trip.toast.pilgrim_updated, {
          fullname: getUserName(firstname, lastname),
        }),
        level: 'success',
      });
      closeModal();
    },
  });

  return (
    <div className={style.medicalform}>
      <Modal
        title={Locales.trip.modal.title.certificate}
        opened={isOpened}
        onClose={closeModal}
        size="large"
        footer={
          <>
            <Button variant="secondary" onClick={closeModal}>
              {Locales.base.button.cancel}
            </Button>
            <Button
              variant="primary"
              color="success"
              onClick={medicalCheckup}
              disabled={isMutating || (isMSSecondary && isHealthy !== null)}
            >
              {Locales.base.button.validate}
            </Button>
          </>
        }
      >
        <form>
          <BlockCard>
            <p>
              {Locales.trip.medical_certificate.establishment}
              <strong>{getUserName(firstname, lastname)} :</strong>
            </p>
            <h3>{Locales.trip.medical_certificate.vaccination}</h3>
            <ActionCard actions={<Switch defaultChecked={q1IsVaxCovid} onChange={setQ1IsVaxCovid} />}>
              <h4>{Locales.trip.medical_certificate.coronavirus}</h4>
              <p>{Locales.trip.medical_certificate.covid19_vaccine}</p>
            </ActionCard>
            <ActionCard actions={<Switch defaultChecked={q2IsVaxMeningitis} onChange={setQ2IsVaxMeningitis} />}>
              <h4>{Locales.trip.medical_certificate.meningitis}</h4>
              <p>{Locales.trip.medical_certificate.meningitis_vaccine}</p>
            </ActionCard>
            <ActionCard actions={<Switch defaultChecked={q3IsVaxYellow} onChange={setQ3IsVaxYellow} />}>
              <h4>{Locales.trip.medical_certificate.yellow_fever}</h4>
              <p>{Locales.trip.medical_certificate.yellow_fever_vaccine}</p>
            </ActionCard>
            <ActionCard actions={<Switch defaultChecked={q4IsVaxPoliomyelitis} onChange={setQ4IsVaxPoliomyelitis} />}>
              <h4>{Locales.trip.medical_certificate.polio}</h4>
              <p>{Locales.trip.medical_certificate.polio_vaccine}</p>
            </ActionCard>
            <ActionCard actions={<Switch defaultChecked={q6IsVaxFlu} onChange={setQ6IsVaxFlu} />}>
              <h4>{Locales.trip.medical_certificate.flu}</h4>
              <p>{Locales.trip.medical_certificate.flu_vaccine}</p>
            </ActionCard>
            <hr /> <h3>{Locales.trip.medical_certificate.physical_exam}</h3>
            <ActionCard actions={<Switch defaultChecked={q7aIsIssuePsychomotor} onChange={setQ7aIsIssuePsychomotor} />}>
              <h4>{Locales.trip.medical_certificate.psychomotor_disorders}</h4>
              <p>{Locales.trip.medical_certificate.psychomotor_disorders_description}</p>
            </ActionCard>
            <ActionCard actions={<Switch defaultChecked={q7bIsIssueLocomotor} onChange={setQ7bIsIssueLocomotor} />}>
              <h4>{Locales.trip.medical_certificate.locomotor_disorders}</h4>
              <p>{Locales.trip.medical_certificate.locomotor_disorders_description}</p>
            </ActionCard>
            <ActionCard actions={<Switch defaultChecked={q7cIsIssueSeeing} onChange={setQ7cIsIssueSeeing} />}>
              <h4>{Locales.trip.medical_certificate.vision_disorders}</h4>
              <p>{Locales.trip.medical_certificate.vision_disorders_description}</p>
            </ActionCard>
            <ActionCard actions={<Switch defaultChecked={q7dIsIssueChronic} onChange={setQ7dIsIssueChronic} />}>
              <h4>{Locales.trip.medical_certificate.chronic_disease}</h4>
              <p>{Locales.trip.medical_certificate.chronic_disease_description}</p>
            </ActionCard>
            <ActionCard actions={<Switch defaultChecked={q7eIsChronicTreatment} onChange={setQ7eIsChronicTreatment} />}>
              <h4>{Locales.trip.medical_certificate.chronic_disease_treatment}</h4>
              <p>{Locales.trip.medical_certificate.chronic_disease_treatment_description}</p>
            </ActionCard>
            <Textarea
              label={Locales.trip.medical_certificate.notes}
              placeholder={Locales.trip.medical_certificate.notes_description}
              defaultValue={healthNote}
              onChange={setPilgrimNote}
            />
          </BlockCard>
        </form>
      </Modal>
    </div>
  );
};

MedicalCheckupModal.defaultProps = {
  isOpened: false,
  pilgrimData: null,
  closeModal: null,
};

MedicalCheckupModal.propTypes = {
  isOpened: PropTypes.bool,
  pilgrimData: PropTypes.shape({}),
  closeModal: PropTypes.func,
};

export default MedicalCheckupModal;
