import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import { Urls } from 'data';

const ProtectedRoute = ({ isAllowed, children }) => {
  if (!isAllowed) {
    // Redirect to the landing page if the user is not allowed to see the page he is trying to access
    return <Navigate to={Urls.base} replace />;
  }

  return children || <Outlet />; // Display the page if the user is allowed
};

ProtectedRoute.defaultProps = {
  isAllowed: false,
  children: null,
};

ProtectedRoute.propTypes = {
  isAllowed: PropTypes.bool,
  children: PropTypes.node,
};

export default ProtectedRoute;
