import React, { useState } from 'react';
import { TextField, Button, Alert, showToast, Page, BlockCard } from '@trellixio/roaster-coffee';
import { useNavigate, Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Urls } from 'data';
import { validateEmail } from 'helpers';
import { useAPI } from 'hooks';
import Locales from 'locales';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [displayError, setDisplayError] = useState(false);
  const navigate = useNavigate();
  const { api } = useAPI();

  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;

  const { mutate: checkEmail } = useMutation({
    mutationFn: () => api.post('auth/user_token/forgot_password/', { email }),
    onSuccess: () => {
      setDisplayError(false);
      showToast({
        message: Locales.formatString(Locales.auth.forgot_pwd.alert, { email }),
        level: 'success',
      });
      navigate(Urls.front.login);
    },
  });

  const handleForgotPassword = (event) => {
    event.preventDefault();
    const emptyEmailField = email.trim() === '';
    const validEmail = validateEmail(email);
    if (!emptyEmailField && validEmail) {
      setDisplayError(false);
      checkEmail();
    } else {
      setDisplayError(true);
    }
  };

  return (
    <Page size="medium" className="vcenter">
      <div className="auth-form-container">
        <form>
          <BlockCard title={<h2>{Locales.auth.forgot_pwd.title}</h2>}>
            {displayError ? <Alert level="error" message={Locales.auth.email_error_message} /> : null}

            <Alert level="info" message={Locales.auth.forgot_pwd.description} />

            <TextField
              label={Locales.base.field.email}
              placeholder={Locales.base.field.placeholder.email}
              type="email"
              required
              onChange={setEmail}
            />

            <div>
              <div className="content-row">
                <Button
                  variant="primary"
                  color="success"
                  className="full-width"
                  submit
                  onClick={handleForgotPassword}
                  disabled={isMutating}
                >
                  {Locales.auth.forgot_pwd.action}
                </Button>
              </div>

              <Link to={Urls.front.login}>{Locales.auth.connect}</Link>
            </div>
          </BlockCard>
        </form>
      </div>
    </Page>
  );
};

export default ForgotPassword;
