import React, { useContext } from 'react';
import { Sidebar, Icon } from '@trellixio/roaster-coffee';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Urls } from 'data';
import { getUserName } from 'helpers';
import { useAPI, useGetUser } from 'hooks';
import { PermissionsContext } from 'context';
import Locales from 'locales';
import logo from 'assets/img/sidebar/ehadj-logo.svg';
import logoutIcon from 'assets/img/sidebar/logout.svg';
import invoiceIcon from 'assets/img/sidebar/invoice.svg';
import apiKeyIcon from 'assets/img/sidebar/api_key.svg';
import pilgrimIcon from 'assets/img/sidebar/pilgrim.svg';
import agencyIcon from 'assets/img/sidebar/agency.svg';
import govIcon from 'assets/img/sidebar/gov.svg';
import bankIcon from 'assets/img/sidebar/bank.svg';
import licenseIcon from 'assets/img/sidebar/license.svg';
import dashboardIcon from 'assets/img/sidebar/dashboard.svg';
import depositIcon from 'assets/img/sidebar/deposit.svg';
import customizationIcon from 'assets/img/sidebar/customization.svg';
import campaignIcon from 'assets/img/sidebar/campaign.svg';
import adminIcon from 'assets/img/sidebar/admin.svg';
import styles from './Sidebar.module.css';

const SidebarComponent = () => {
  const { user, isAgency, isAgencyPrimary, isBank, isAGLO, isSuperAdmin, isPrimaryUser, isMS, isDEI, isMAEC } =
    useGetUser();
  const { isPlatformLocked } = useContext(PermissionsContext);
  const { first_name: firstname, last_name: lastname, organization_name: organisationName } = user || {};
  const { api } = useAPI();

  const navigate = useNavigate();
  const { pathname: currentItemPath } = useLocation();

  const { data: preferences } = useQuery({
    queryFn: api.retrieve('preference', 'current/'),
    queryKey: ['preference/current/'],
  });

  const { data: organizations } = useQuery({
    queryFn: api.list('organization/'),
    queryKey: ['organization/'],
    enabled: !isAgency,
  });

  const organizationAcronym = organizations?.find((org) => org.name === organisationName)?.acronym;

  const { campaign_year: campaignYear } = preferences || {};

  const isActive = (itemName) => currentItemPath === `/${itemName}`;

  const userIcon = () => {
    if (isAgency) return agencyIcon;

    if (isBank) return bankIcon;

    return govIcon;
  };

  const menuItems = () => {
    const result = [];

    if (isAgency || isAGLO || isMS || isDEI || isMAEC) {
      result.push({
        icon: (
          <Icon small>
            <img src={pilgrimIcon} alt={Locales.base.sidebar.trips} />
          </Icon>
        ),
        label: Locales.base.sidebar.trips,
        active: isActive('trip'),
        onClick: () => navigate(Urls.trip),
      });
    }

    if (isAgency || isAGLO) {
      result.push({
        icon: (
          <Icon small>
            <img src={customizationIcon} alt={Locales.base.sidebar.customizations} />
          </Icon>
        ),
        label: Locales.base.sidebar.customizations,
        active: isActive('customization'),
        onClick: () => navigate(Urls.customization),
      });
    }

    if (isAgency || isAGLO) {
      result.push({
        icon: (
          <Icon small>
            <img src={depositIcon} alt={Locales.base.sidebar.deposits} />
          </Icon>
        ),
        label: Locales.base.sidebar.deposits,
        active: isActive('deposit'),
        onClick: () => navigate(Urls.deposit),
      });
    }

    if (isAgency || isAGLO || isBank) {
      result.push({
        icon: (
          <Icon small>
            <img src={invoiceIcon} alt={Locales.base.sidebar.invoices} />
          </Icon>
        ),
        label: Locales.base.sidebar.invoices,
        active: isActive('invoice'),
        onClick: () => navigate(Urls.invoice),
      });
    }

    if (isBank) {
      result.push({
        icon: (
          <Icon small>
            <img src={apiKeyIcon} alt={Locales.base.sidebar.api_key} />
          </Icon>
        ),
        label: Locales.base.sidebar.api_key,
        active: isActive('api-key'),
        onClick: () => navigate(Urls.apikey),
      });
    }

    if (isAgencyPrimary) {
      result.push({
        icon: (
          <Icon small>
            <img src={licenseIcon} alt={Locales.base.sidebar.authorization} />
          </Icon>
        ),
        label: Locales.base.sidebar.authorization,
        active: isActive('authorization'),
        onClick: () => navigate(Urls.authorization),
      });
    }

    if (isAGLO) {
      result.push({
        icon: (
          <Icon small>
            <img src={agencyIcon} alt={Locales.base.sidebar.agencies} />
          </Icon>
        ),
        label: Locales.base.sidebar.agencies,
        active: isActive('agency'),
        onClick: () => navigate(Urls.agency),
      });
    }

    if (isAGLO || isSuperAdmin) {
      result.push({
        icon: (
          <Icon small>
            <img src={dashboardIcon} alt={Locales.base.sidebar.dashboard} />
          </Icon>
        ),
        label: Locales.base.sidebar.dashboard,
        active: isActive('dashboard'),
        onClick: () => navigate(Urls.dashboard),
      });
    }

    if (isSuperAdmin || isAGLO) {
      result.push({
        icon: (
          <Icon small>
            <img src={campaignIcon} alt={Locales.base.sidebar.campaign} />
          </Icon>
        ),
        label: Locales.base.sidebar.campaign,
        active: isActive('campaign'),
        onClick: () => navigate(Urls.campaign),
      });
    }

    if (isPrimaryUser) {
      result.push({
        icon: (
          <Icon small>
            <img src={adminIcon} alt={Locales.base.sidebar.users} />
          </Icon>
        ),
        label: Locales.base.sidebar.users,
        active: isActive('user'),
        onClick: () => navigate(Urls.user),
      });
    }

    return [result];
  };

  return (
    <Sidebar
      className={styles.sidebar}
      header={
        <div className="app-logo">
          <img src={logo} alt="ehadj-logo" title="ehadj logo" />
          {campaignYear && <span>{` ${campaignYear}`}</span>}
          {/* Display the lock icon only for SA and AGLO users */}
          {(isAgency || isAGLO) && isPlatformLocked && <i className="fa regular fa-lock" />}
        </div>
      }
      mobileHeader={
        <div className="app-logo">
          <img src={logo} alt="ehadj-logo" title="ehadj logo" />
        </div>
      }
      footer={
        <Sidebar.Menu>
          <Sidebar.Menu.Item
            icon={
              <Icon small>
                <img src={logoutIcon} alt="logout" />
              </Icon>
            }
            onClick={() => navigate(Urls.front.logout)}
          >
            <span>{Locales.base.sidebar.logout}</span>
          </Sidebar.Menu.Item>

          <div className={`menu-item ${styles.identity}`}>
            <div className="actions-list-container">
              <div className="store-profile button secondary" tabIndex="0" role="button">
                <div className="user">
                  <img src={userIcon()} alt="user-logo" />

                  <div className={styles.name}>
                    <p>{isAgency ? organisationName : organizationAcronym}</p>
                    <span>{getUserName(firstname, lastname)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Sidebar.Menu>
      }
      items={menuItems()}
    />
  );
};

export default SidebarComponent;
