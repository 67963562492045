import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from 'helpers';
import style from './TripDistributionBar.module.css';

const CONST_MAX_VALUE = 500;

const TripDistributionChartItem = ({ value, color, className }) => {
  const minWidth = 5; // Minimum width in percentage (e.g., 5%)

  const calculateWidth = () => {
    // Calculate the proportional width
    const proportionalWidth = (value / CONST_MAX_VALUE) * (100 - minWidth); // Reserve space for the minimum width
    return Math.min(proportionalWidth + minWidth, 100); // Ensure the total width doesn't exceed 100%
  };

  return (
    value > 0 && (
      <div
        className={`${style['trip-bar-cell']} ${className}`} // Add the custom className here
        style={{
          width: `${calculateWidth()}%`,
          backgroundColor: color,
        }}
      >
        <strong className={style['trip-bar-label']}>{formatNumber(value)}</strong>
      </div>
    )
  );
};

TripDistributionChartItem.defaultProps = {
  value: null,
  color: null,
  className: null,
};

TripDistributionChartItem.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default TripDistributionChartItem;
