/* eslint-disable */

import * as Sentry from '@sentry/react';

const vendors = {
  _user: null,
  _appName: null,
  _isAdmin: false,
  _isLoaded: false,

  init(updatedUser = null, isadmin) {
    this._appName = 'eHadj';
    this._user = updatedUser || {};
    this._isAdmin = isadmin;

    this.Sentry.init();

    this._isLoaded = true;

    window.vendors = this;
  },

  /* Sentry Bug Reporting */
  Sentry: {
    init: () => {
      if (!Sentry.getClient()) {
        Sentry.init({
          dsn: process.env.REACT_APP_SENTRY_DSN,
          environment: process.env.REACT_APP_ENV, // Auto-detect environment
          mail: {
            replyTo: vendors._user.email,
          },
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
            ...(vendors._isAdmin
              ? [
                  Sentry.feedbackIntegration({
                    colorScheme: 'dark',
                    showBranding: false,
                    showName: false,
                    showEmail: false,
                    messagePlaceholder: 'Quel bug avez vous rencontré ?',
                    successMessageText: 'Merci ! Veuillez patientez, nous prenons en compte votre demande',
                    triggerLabel: 'Contacter le support',
                    submitButtonLabel: 'Envoyer',
                    cancelButtonLabel: 'Annuler',
                    formTitle: 'Support',
                    addScreenshotButtonLabel: `Ajouter une capture d'écran`,
                    removeScreenshotButtonLabel: `Retirer une capture d'écran`,
                  }),
                ]
              : []),
          ],
          tracesSampleRate: 0.1, // Capture 10% of transactions
          replaysSessionSampleRate: 0.1, // Records 10% of all user sessions
          replaysOnErrorSampleRate: 0.5, // Records 50% of sessions where an error occurs
        });
      }

      Sentry.setUser({
        id: vendors._user.id,
        name: `${vendors._user.first_name} ${vendors._user.last_name}`,
        email: vendors._user.email,
        role: vendors._user.organization_name,
      });
    },
  },
};

export default vendors;
