import React, { useState } from 'react';
import { Modal, TextField, Textarea, showToast, Button, BlockCard } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI, useGetUser, useGetPilgrim } from 'hooks';
import Locales from 'locales';
import { getUserName, formatDate } from 'helpers';

const EditCustomizationModal = ({ isOpened, pilgrimData, closeModal }) => {
  const { isAgency, isAGLO } = useGetUser();

  const {
    id,
    categoryId,
    firstname,
    lastname,
    birthdate,
    passport,
    npi,
    customizationName,
    customizationDescription,
    customizationPrice,
  } = useGetPilgrim(pilgrimData);

  const [name, setCustomizationName] = useState(customizationName);
  const [description, setCustomizationDescription] = useState(customizationDescription);
  const [price, setCustomizationPrice] = useState(customizationPrice);
  const [customizationNameError, setCustomizationNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [priceTooHighError, setPriceTooHighError] = useState(false);

  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;

  const editedCustomization = {
    pilgrim: {
      first_name: firstname,
      last_name: lastname,
      birthdate: formatDate(birthdate, 'YYYY-MM-DD'),
      passport,
      npi,
    },
    customization: { name, price, description },
    category_id: categoryId,
  };

  const { mutate: editCustomization } = useMutation({
    mutationFn: () => api.update('trip', editedCustomization, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      showToast({
        message: Locales.customization.toast.pilgrim_updated,
        level: 'success',
      });
      closeModal();
    },
  });

  const validateForm = (event) => {
    event.preventDefault();
    const descriptionErrorLocal = description.length >= 0 && description.length < 5;
    const customizationNameErrorLocal = name.length >= 0 && name.length < 5;
    // Checks if the price is invalid: empty string, less than zero, or not an integer.
    const priceErrorLocal = price === '' || parseFloat(price, 10) < 0 || !(parseFloat(price, 10) % 1 === 0);
    // Checks if the price is too high
    const priceTooHighErrorLocal = parseFloat(price, 10) > 10000000;

    setDescriptionError(descriptionErrorLocal);
    setCustomizationNameError(customizationNameErrorLocal);
    setPriceError(priceErrorLocal);
    setPriceTooHighError(priceTooHighErrorLocal);

    if (!descriptionErrorLocal && !customizationNameErrorLocal && !priceErrorLocal && !priceTooHighErrorLocal) {
      editCustomization();
    }
  };

  return (
    <Modal
      title={Locales.customization.personnalisation}
      size="small"
      opened={isOpened}
      onClose={closeModal}
      footer={
        <>
          <Button variant="secondary" onClick={closeModal}>
            {Locales.base.button.discard}
          </Button>
          <Button variant="primary" color="success" disabled={isMutating} onClick={validateForm}>
            {Locales.base.button.save}
          </Button>
        </>
      }
    >
      <form>
        <BlockCard>
          <p>
            {Locales.formatString(Locales.customization.edit_customization, {
              fullname: <strong>{getUserName(firstname, lastname)}</strong>,
            })}
          </p>

          <TextField
            label={Locales.customization.field.service_name}
            type="text"
            placeholder={Locales.customization.field.placeholder.service_name}
            defaultValue={customizationName}
            required
            onChange={setCustomizationName}
            error={customizationNameError ? Locales.customization.error : ''}
          />

          <Textarea
            label={Locales.customization.field.customization_description}
            placeholder={Locales.customization.field.placeholder.customization_description}
            defaultValue={customizationDescription}
            required
            onChange={setCustomizationDescription}
            error={descriptionError ? Locales.customization.error : ''}
          />

          {isAGLO && (
            <TextField
              label={Locales.customization.field.price}
              type="number"
              placeholder="0"
              defaultValue={customizationPrice}
              required
              onChange={setCustomizationPrice}
              helpText={Locales.customization.field.helptext.price}
              error={
                (priceError ? Locales.customization.invalid_field : '') ||
                (priceTooHighError ? Locales.customization.price_too_high : '')
              }
            />
          )}

          {isAgency && (
            <TextField
              label={Locales.customization.field.price}
              type="number"
              placeholder="0"
              value={customizationPrice}
              required
              helpText={Locales.customization.field.helptext.price}
              error={priceError ? Locales.customization.invalid_field : ''}
              readOnly
              disabled
            />
          )}
        </BlockCard>
      </form>
    </Modal>
  );
};

EditCustomizationModal.defaultProps = {
  isOpened: false,
  pilgrimData: null,
  closeModal: null,
};

EditCustomizationModal.propTypes = {
  isOpened: PropTypes.bool,
  pilgrimData: PropTypes.shape({}),
  closeModal: PropTypes.func,
};

export default EditCustomizationModal;
