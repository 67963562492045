import React, { useMemo } from 'react';
import { RestClient, Storage, onLogout } from 'helpers';
import { Params } from 'data';
import PropTypes from 'prop-types';

const CONST_USER_TOKEN = 'user_token';

/**
 * Create an instance of the RestClient class
 * Give access to all Rest API methods: get, post, retrieve, update, list, ..
 * This method can be use with useQuery/useMutation (@tanstack/react-query) to handle the return promise
 *
 * @returns {RestClient} instance of RestClient
 */
const getRestClient = () => {
  const storage = new Storage(localStorage, 'eHadj-');
  const token = storage.get(CONST_USER_TOKEN);

  return new RestClient(Params.API_BASE_URL, token, onLogout);
};

export const RestClientContext = React.createContext({});

const RestClientProvider = ({ children }) => {
  const api = getRestClient();

  const memoValues = useMemo(() => ({ api }), [api]);

  return <RestClientContext.Provider value={memoValues}>{children}</RestClientContext.Provider>;
};

RestClientProvider.defaultProps = {
  children: null,
};

RestClientProvider.propTypes = {
  children: PropTypes.node,
};

export default RestClientProvider;
