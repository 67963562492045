import React from 'react';
import { Page, BlockCard, TextField, Button, Alert, Icon, showToast } from '@trellixio/roaster-coffee';
import { useQuery } from '@tanstack/react-query';
import { useAPI } from 'hooks';
import Locales from 'locales';

const APIKey = () => {
  const { api } = useAPI();

  const { data: APIData } = useQuery({
    queryFn: api.retrieve('user', 'current/api_key/'),
    queryKey: ['user/current/api_key/'],
  });

  const apiKey = APIData?.api_key;

  const copyAPIKey = () => {
    navigator.clipboard.writeText(apiKey);
    showToast({
      message: Locales.apikey.toast.success,
      level: 'success',
    });
  };

  return (
    APIData && (
      <Page title={Locales.apikey.title}>
        <BlockCard title={Locales.apikey.access_key}>
          <p>
            {Locales.apikey.about}
            <a href={`${process.env.REACT_APP_API_BASE_URL}doc`} rel="noreferrer" target="_blank">
              {Locales.apikey.doc}
            </a>
          </p>

          <Alert message={Locales.apikey.alert} level="warning" />

          <form>
            <TextField
              label={Locales.base.field.access_key}
              value={apiKey}
              rightSection={
                <Button variant="secondary" onClick={copyAPIKey}>
                  <Icon small>
                    <i className="far fa-copy" />
                  </Icon>
                </Button>
              }
              helpText={Locales.apikey.helptext}
              readOnly
              disabled
            />
          </form>
        </BlockCard>
      </Page>
    )
  );
};

export default APIKey;
