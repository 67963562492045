import React, { createContext, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { showToast } from '@trellixio/roaster-coffee';
import { useAPI } from 'hooks';
import Locales from 'locales';
import PropTypes from 'prop-types';

export const PermissionsContext = createContext({});

export const PermissionsProvider = ({ children }) => {
  const { api } = useAPI();
  const queryClient = useQueryClient();

  const { data: permissions } = useQuery({
    queryFn: api.retrieve('permission', 'current/'),
    queryKey: ['permission/current/'],
  });

  const isPlatformLocked = permissions?.data[0].platform_locked;

  const { mutate: lockPlatform } = useMutation({
    mutationFn: () => api.post('permission/lock/'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['permission/current/'] });
      showToast({
        message: Locales.campaign.settings.toast.success_lock,
        level: 'success',
      });
    },
  });

  const { mutate: unlockPlatform } = useMutation({
    mutationFn: () => api.post('permission/unlock'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['permission/current/'] });
      showToast({
        message: Locales.campaign.settings.toast.success_unlock,
        level: 'success',
      });
    },
  });

  const value = useMemo(
    () => ({
      permissions,
      isPlatformLocked,
      lockPlatform,
      unlockPlatform,
    }),
    [permissions]
  );

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};

PermissionsProvider.propTypes = {
  children: PropTypes.node,
};

PermissionsProvider.defaultProps = {
  children: null,
};

export default PermissionsProvider;
