import React, { useState } from 'react';
import { Modal, TextField, Textarea, showToast, Button, BlockCard } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI, useGetPilgrim } from 'hooks';
import Locales from 'locales';
import { getUserName, formatDate } from 'helpers';
import style from './Trips.module.css';

const CustomizationModal = ({ isOpened, pilgrimData, closeModal }) => {
  const {
    id,
    firstname,
    lastname,
    npi,
    note,
    passport,
    passportExpirationDate,
    birthdate,
    isInternational,
    customizationName,
    customizationApproved,
    categoryId,
    customizationDescription,
    customizationPrice,
  } = useGetPilgrim(pilgrimData);

  const [name, setCustomizationName] = useState(customizationName);
  const [description, setCustomizationDescription] = useState(customizationDescription);
  const [customizationNameError, setCustomizationNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;

  const editedCustomization = {
    pilgrim: {
      npi,
      first_name: firstname,
      last_name: lastname,
      birthdate: formatDate(birthdate, 'YYYY-MM-DD'),
      passport,
      passport_expiration: passportExpirationDate ? formatDate(passportExpirationDate, 'YYYY-MM-DD') : null,
      is_international: isInternational,
    },
    note,
    customization: { name, description },
    category_id: categoryId,
  };

  const disableTextField = customizationApproved !== null;

  const { mutate: editCustomization } = useMutation({
    mutationFn: () => api.update('trip', editedCustomization, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['trip/stats/default/'] });
      showToast({
        message: Locales.trip.toast.customization_updated,
        level: 'success',
      });
      closeModal();
    },
  });

  const validateForm = (event) => {
    event.preventDefault();
    const descriptionErrorLocal = description.length >= 0 && description.length < 5;
    const customizationNameErrorLocal = name.length >= 0 && name.length < 5;

    setDescriptionError(descriptionErrorLocal);
    setCustomizationNameError(customizationNameErrorLocal);

    if (!descriptionErrorLocal && !customizationNameErrorLocal) {
      editCustomization();
    }
  };

  return (
    <Modal
      title={Locales.customization.title}
      size="small"
      opened={isOpened}
      onClose={closeModal}
      footer={
        <>
          <Button variant="secondary" onClick={closeModal}>
            {Locales.base.button.discard}
          </Button>
          <Button variant="primary" color="success" disabled={isMutating} onClick={validateForm}>
            {Locales.base.button.save}
          </Button>
        </>
      }
    >
      <form>
        <BlockCard>
          <p>
            {Locales.formatString(Locales.customization.edit_customization, {
              fullname: <strong>{getUserName(firstname, lastname)}</strong>,
            })}
          </p>

          <TextField
            label={Locales.customization.field.service_name}
            type="text"
            placeholder={Locales.customization.field.placeholder.service_name}
            defaultValue={customizationName}
            required
            error={customizationNameError ? Locales.customization.error : ''}
            onChange={setCustomizationName}
            disabled={disableTextField}
          />

          <Textarea
            label={Locales.customization.field.customization_description}
            placeholder={Locales.customization.field.placeholder.customization_description}
            defaultValue={customizationDescription}
            required
            error={descriptionError ? Locales.customization.error : ''}
            onChange={setCustomizationDescription}
            disabled={disableTextField}
          />

          <TextField
            label={Locales.customization.field.price}
            type="number"
            placeholder="0"
            value={customizationPrice}
            readOnly
            helpText={Locales.customization.field.helptext.price}
            className={style['disabled-field']}
            disabled
          />
        </BlockCard>
      </form>
    </Modal>
  );
};

CustomizationModal.defaultProps = {
  isOpened: false,
  pilgrimData: null,
  closeModal: null,
};

CustomizationModal.propTypes = {
  isOpened: PropTypes.bool,
  pilgrimData: PropTypes.shape({}),
  closeModal: PropTypes.func,
};

export default CustomizationModal;
