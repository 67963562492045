import React from 'react';

/**
 *
 * @param {string} text a text that may contain URL or mail
 * @returns {string} same text with URL and mail correctly format
 */
const useFormatTextWithLinks = (text) => {
  const regex = /(https?:\/\/[^\s]+|mailto:[^\s]+|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
  const parts = text.split(regex);

  return parts.map((part) => {
    if (regex.test(part)) {
      const isMailto = part.startsWith('mailto:') || part.includes('@');
      const href = isMailto ? `mailto:${part.replace('mailto:', '')}` : part;
      return (
        <a key={part} className="link" href={href} target={isMailto ? '_self' : '_blank'} rel="noopener noreferrer">
          {part.replace('mailto:', '')}
        </a>
      );
    }
    return part;
  });
};

export default useFormatTextWithLinks;
