import React, { useState } from 'react';
import { Modal, TextField, Textarea, showToast, Button, BlockCard } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI } from 'hooks';
import Locales from 'locales';

const EditAgencyModal = ({ opened, agency, closeModal }) => {
  const { id, name: agencyName, ifu: agencyIFU, phone: agencyPhone, address: agencyAddr, admin } = agency;
  const { first_name: firstname, last_name: lastname, email } = admin;

  const [name, setAgencyName] = useState(agencyName);
  const [ifu, setAgencyIFU] = useState(agencyIFU);
  const [phone, setAgencyPhone] = useState(agencyPhone);
  const [address, setAgencyAddr] = useState(agencyAddr);
  const [ifuError, setIfuError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addrLengthError, setLengthAddrError] = useState(false);
  const [agencyNameLengthError, setAgencyNameLengthError] = useState(false);

  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;

  const editedAgency = {
    name,
    ifu,
    address,
    phone,
    admin_first_name: firstname,
    admin_last_name: lastname,
    admin_email: email,
  };

  const { mutate: editAgency } = useMutation({
    mutationFn: () => api.update('agency', editedAgency, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['trip/'] });
      queryClient.invalidateQueries({ queryKey: ['deposit/'] });
      queryClient.invalidateQueries({ queryKey: ['invoice/'] });
      queryClient.invalidateQueries({ queryKey: ['agency/'] });
      showToast({
        message: Locales.formatString(Locales.agency.toast.updated_agency, { name }),
        level: 'success',
      });
      closeModal();
    },
  });

  const validateForm = (event) => {
    event.preventDefault();
    const ifuCorrectFormat = /^[0-9]{13}$/.test(ifu);
    const ifuErrorLocal = !ifuCorrectFormat;
    const phoneErrorLocal = phone.length >= 0 && phone.length < 8;
    const addrLengthErrorLocal = address.length >= 0 && address.length < 5;
    const agencyNameLengthErrorLocal = name.length >= 0 && name.length < 5;

    setIfuError(ifuErrorLocal);
    setPhoneError(phoneErrorLocal);
    setAgencyNameLengthError(agencyNameLengthErrorLocal);
    setLengthAddrError(addrLengthErrorLocal);

    if (!ifuErrorLocal && !phoneErrorLocal && !agencyNameLengthErrorLocal && !addrLengthErrorLocal) {
      editAgency();
    }
  };

  return (
    <Modal
      title={Locales.agency.edit}
      size="small"
      opened={opened}
      onClose={closeModal}
      footer={
        <>
          <Button variant="secondary" onClick={closeModal}>
            {Locales.base.button.discard}
          </Button>
          <Button variant="primary" color="success" onClick={validateForm} disabled={isMutating}>
            {Locales.base.button.save}
          </Button>
        </>
      }
    >
      <form>
        <BlockCard>
          <p>
            {Locales.agency.agency_detail}
            <strong>{agencyName} :</strong>
          </p>

          <TextField
            label={Locales.agency.field.agency_name}
            type="text"
            placeholder={Locales.base.field.placeholder.name}
            defaultValue={agencyName}
            required
            error={agencyNameLengthError ? Locales.agency.errors.agency_name : ''}
            onChange={setAgencyName}
          />

          <TextField
            label={Locales.agency.field.num_ifu}
            type="text"
            defaultValue={agencyIFU}
            pattern="^[0-9]{13}$"
            minLength="13"
            maxLength="13"
            required
            error={ifuError ? Locales.agency.errors.ifu : ''}
            onChange={setAgencyIFU}
          />

          <TextField
            label={Locales.base.field.phone_number}
            type="number"
            defaultValue={agencyPhone}
            className="prefixed-input"
            prefix="+229"
            required
            error={phoneError ? Locales.agency.errors.phone : ''}
            onChange={setAgencyPhone}
          />

          <Textarea
            label={Locales.base.field.address}
            placeholder={Locales.base.field.placeholder.address}
            defaultValue={agencyAddr}
            required
            error={addrLengthError ? Locales.agency.errors.addr : ''}
            onChange={setAgencyAddr}
          />
        </BlockCard>
      </form>
    </Modal>
  );
};

EditAgencyModal.defaultProps = {
  opened: false,
  agency: null,
  closeModal: null,
};

EditAgencyModal.propTypes = {
  opened: PropTypes.bool,
  agency: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    ifu: PropTypes.number,
    phone: PropTypes.number,
    address: PropTypes.string,
    admin: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  closeModal: PropTypes.func,
};

export default EditAgencyModal;
