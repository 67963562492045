import React, { useContext } from 'react';
import { Page, BlockCard, ModalProvider, openModal } from '@trellixio/roaster-coffee';
import { PermissionsContext } from 'context';
import PropTypes from 'prop-types';
import Locales from 'locales';
import PlatformLocked from './PlatformLocked';
import PlatformUnlocked from './PlatformUnlocked';

const AGLOCampaign = ({ campaignYear }) => {
  const { isPlatformLocked, lockPlatform, unlockPlatform } = useContext(PermissionsContext);

  const handleOpenConfirmationModal = () => {
    openModal({
      title: (
        <>
          {isPlatformLocked && Locales.campaign.settings.modal.title.unlock_platform}
          {!isPlatformLocked && Locales.campaign.settings.modal.title.lock_platform}
        </>
      ),
      children: (
        <span>
          {isPlatformLocked && Locales.campaign.settings.modal.confirmation_message.unlock_platform}
          {!isPlatformLocked && Locales.campaign.settings.modal.confirmation_message.lock_platform}
        </span>
      ),
      closeOnConfirm: true,
      closeOnCancel: true,
      confirmProps: {
        children: (
          <>
            {isPlatformLocked && Locales.base.button.unlock}
            {!isPlatformLocked && Locales.base.button.lock}
          </>
        ),
        variant: 'primary',
        color: isPlatformLocked ? 'success' : 'danger',
      },
      cancelProps: {
        children: <span>{Locales.base.button.cancel}</span>,
        variant: 'secondary',
        color: 'secondary',
      },
      onCancel: () => {},
      onConfirm: () => (isPlatformLocked ? unlockPlatform() : lockPlatform()),
    });
  };

  return (
    <ModalProvider>
      <Page title={`${Locales.campaign.title} ${campaignYear}`}>
        <BlockCard title={Locales.campaign.settings.title}>
          {isPlatformLocked && <PlatformLocked handleOpenConfirmationModal={handleOpenConfirmationModal} />}

          {!isPlatformLocked && <PlatformUnlocked handleOpenConfirmationModal={handleOpenConfirmationModal} />}
        </BlockCard>
      </Page>
    </ModalProvider>
  );
};

AGLOCampaign.defaultProps = {
  campaignYear: null,
};

AGLOCampaign.propTypes = {
  campaignYear: PropTypes.number,
};

export default AGLOCampaign;
