import React from 'react';
import { StatCard } from '@trellixio/roaster-coffee';
import { formatNumber } from 'helpers';
import PropTypes from 'prop-types';

const StatCardsList = ({ statsData }) => {
  return (
    <>
      {statsData.map((stat) => (
        <>
          {stat.percent === null && (
            <StatCard
              key={stat.name}
              title={stat.name}
              value={formatNumber(stat.value)}
              description={stat.description}
            />
          )}
          {stat.percent !== null && (
            <StatCard
              key={stat.name}
              title={stat.name}
              value={formatNumber(stat.value)}
              description={stat.description}
              progressValue={stat.percent}
              progressLabel={`${stat.percent}%`}
            />
          )}
        </>
      ))}
    </>
  );
};

StatCardsList.defaultProps = {
  statsData: null,
};

StatCardsList.propTypes = {
  statsData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default StatCardsList;
