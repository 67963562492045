import React, { useState } from 'react';
import { Page, BlockCard, TextField, Button, showToast } from '@trellixio/roaster-coffee';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useAPI } from 'hooks';
import Locales from 'locales';

// Define the minimum valid campaign year as a constant
// This value is chosen based on the business requirement that the campaign year should not be earlier than 2023
// MIN_CAMPAIGN_YEAR is set to 2023 because it's the first year the platform was used for the Hajj
const CONST_MIN_CAMPAIGN_YEAR = 2023;
// Default pre-registration fee is 200000 (this can be adjusted based on the business requirement)
const CONST_DEFAULT_PRE_REGISTRATION_FEE = 200000;
// Default minimum number of pilgrims required for a certificate is 200 (this can be adjusted based on the business requirement)
const CONST_DEFAULT_MINIMUM_PILGRIMS = 200;

const CONST_ELIGIBILITY_AGE = 2;

const SuperAdminCampaign = ({ preferences }) => {
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const isMutating = queryClient.isMutating() > 0;

  const {
    registration_deposit: registrationDeposit,
    certificate_min_active_pilgrim: certificateMinActivePilgrim,
    campaign_year: editableCampaignYear,
    pilgrim_eligibility_min_age: editableEligibilityAge,
  } = preferences || {};

  // Default campaign year is the current year
  const [campaignYear, setCampaignYear] = useState(editableCampaignYear || new Date().getFullYear());
  const [eligibilityAge, setEligibilityAge] = useState(editableEligibilityAge || CONST_ELIGIBILITY_AGE);
  const [preRegistrationFee, setPreRegistrationFee] = useState(
    registrationDeposit || CONST_DEFAULT_PRE_REGISTRATION_FEE
  );
  const [minimumPilgrims, setMinimumPilgrims] = useState(certificateMinActivePilgrim || CONST_DEFAULT_MINIMUM_PILGRIMS);
  const [isCampaignYearNotIntegerError, setIsCampaignYearNotIntegerError] = useState(false);
  const [isCampaignYearOutOfRangeError, setIsCampaignYearOutOfRangeError] = useState(false);
  const [isEligibilityAgeFieldError, setIsEligibilityAgeFieldError] = useState(false);
  const [isInvalidPreRegistrationFeeFieldError, setIsInvalidPreRegistrationFeeFieldError] = useState(false);
  const [isInvalidMinimumPilgrimsFieldError, setIsInvalidMinimumPilgrimsFieldError] = useState(false);

  const editedPreference = {
    registration_deposit: preRegistrationFee,
    certificate_min_active_pilgrim: minimumPilgrims,
    campaign_year: campaignYear,
    pilgrim_eligibility_min_age: eligibilityAge,
  };

  const { mutate: editPreference } = useMutation({
    mutationFn: () => api.update('preference', editedPreference),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['preference/current/'] });
      showToast({
        message: Locales.campaign.toast.success,
        level: 'success',
      });
    },
  });

  const handleUpdatePreference = () => {
    const isInt = (value) =>
      !Number.isNaN(value) &&
      parseInt(Number(value), 10) === Number(value) &&
      Number(value).toString() === value.toString();

    const isCampaignYearNotInteger = !isInt(campaignYear);
    const isCampaignYearOutOfRange = campaignYear < CONST_MIN_CAMPAIGN_YEAR;
    const isInvalidEligibilityAge = !isInt(eligibilityAge) || eligibilityAge < 0;
    const isInvalidPreRegistrationFeeField = !isInt(preRegistrationFee) || preRegistrationFee < 0;
    const isInvalidMinimumPilgrimsField = !isInt(minimumPilgrims) || minimumPilgrims < 0;

    setIsCampaignYearNotIntegerError(isCampaignYearNotInteger);
    setIsCampaignYearOutOfRangeError(isCampaignYearOutOfRange);
    setIsEligibilityAgeFieldError(isInvalidEligibilityAge);
    setIsInvalidPreRegistrationFeeFieldError(isInvalidPreRegistrationFeeField);
    setIsInvalidMinimumPilgrimsFieldError(isInvalidMinimumPilgrimsField);

    if (
      !isCampaignYearNotInteger &&
      !isCampaignYearOutOfRange &&
      !isEligibilityAgeFieldError &&
      !isInvalidPreRegistrationFeeField &&
      !isInvalidMinimumPilgrimsField
    ) {
      editPreference();
    }
  };

  return (
    <Page loading={isMutating} title={`${Locales.campaign.title} ${campaignYear || editableCampaignYear}`}>
      <form>
        <BlockCard title={Locales.campaign.subtitle}>
          <TextField
            label={Locales.campaign.field.campaign_year}
            defaultValue={editableCampaignYear}
            onChange={setCampaignYear}
            error={
              (isCampaignYearOutOfRangeError ? Locales.campaign.error.out_of_range : '') ||
              (isCampaignYearNotIntegerError ? Locales.campaign.error.invalid : '')
            }
            type="number"
          />

          <TextField
            label={Locales.campaign.field.min_eligibility_age}
            defaultValue={eligibilityAge}
            onChange={setEligibilityAge}
            helpText={Locales.campaign.field.helptext.min_eligibility_age}
            error={isEligibilityAgeFieldError ? Locales.campaign.error.invalid : ''}
            type="number"
          />

          <TextField
            label={Locales.campaign.field.pre_registration_fee}
            prefix="CFA"
            className="prefixed-input"
            defaultValue={registrationDeposit}
            onChange={setPreRegistrationFee}
            helpText={Locales.campaign.field.helptext.pre_registration_fee}
            error={isInvalidPreRegistrationFeeFieldError ? Locales.campaign.error.invalid : ''}
            type="number"
          />

          <TextField
            label={Locales.campaign.field.minimum_pilgrims}
            defaultValue={certificateMinActivePilgrim}
            onChange={setMinimumPilgrims}
            helpText={Locales.campaign.field.helptext.minimum_pilgrims}
            error={isInvalidMinimumPilgrimsFieldError ? Locales.campaign.error.invalid : ''}
            type="number"
          />
        </BlockCard>
      </form>
      <div className="form-actions">
        <span />
        <Button variant="primary" color="success" onClick={handleUpdatePreference} disabled={isMutating}>
          {Locales.base.button.save}
        </Button>
      </div>
    </Page>
  );
};

SuperAdminCampaign.defaultProps = {
  preferences: null,
};

SuperAdminCampaign.propTypes = {
  preferences: PropTypes.shape({}),
};

export default SuperAdminCampaign;
